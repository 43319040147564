import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import { Style } from "src/app/utils/style/style";
import { BrowserFamily } from "../../models/statistics/browserFamily";
import { DeviceBrand } from "../../models/statistics/device-brand";
import { DeviceType } from "../../models/statistics/deviceType";
import { IColumn } from "../../models/table/column.interface";
import { viewsByAgeRangeAndGenderByAd } from "src/app/store/statistics/statistics.actions";
import { JwtService } from "../../services/jwt.service";
import { TechnicalCardSearchMethod } from "../../models/statistics/technical-data";
import {
  setDeviceBrand,
  setDeviceType,
  setOsFamily,
  setBrowserFamily,
  invokeOsFamily,
  invokeBrowserFamily,
  InvokeDeviceType as invokeDeviceType,
  invokeDeviceBrand,
} from "src/app/store/consumers/consumers.actions";
import {
  resultDeviceTypeSelector,
  resultDeviceBrandSelector,
  resultOsFamilySelector,
  resultBrowserFamilySelector,
} from "src/app/store/consumers/consumers.selectors";
import { ChartName } from "../chart/chart-name.enum";
import {
  ExportData,
  exportDataType,
  technicalData,
} from "../../data/exportData";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { OsFamily } from "../../models/statistics/osFamily-statics";

@Component({
  selector: "app-technical-data",
  templateUrl: "./technical-data.component.html",
})
export class TechnicalDataComponent extends BaseComponent implements OnInit {
  @Input() isPremium: boolean = false;
  @Output() resultOsFamily = new EventEmitter<OsFamily | null>();
  @Output() resultbrowserFamily = new EventEmitter<BrowserFamily | null>();
  @Output() resultDeviceType = new EventEmitter<DeviceType | null>();
  @Output() resultDeviceBrand = new EventEmitter<Map<string, number> | null>();
  @Input() search: Observable<TechnicalCardSearchMethod>;
  searchResult : TechnicalCardSearchMethod
  role: string;
  technicalSpecifications: ExportData[] = technicalData;
  userByBrowser: any;
  userByOsFamily: any;

  chartName: ChartName = ChartName.DEVICE_TYPE;
  donutChart = {
    title: "Données thechnique",
    legend: {
      customLegendItems: Style.browserFamilyRepartitionLegend.customLegendItems,
    },
    labels: Style.browserFamily,
  };
  columnChart = {
    title: "Données démographiques",
    yaxisTitle: "Charts.consumers",
    xaxisTitle: "Days",
    xaxis: {
      title: { text: "Years" },
    },
  };
  formDeviceBrand: IColumn[] = [
    {
      key: "infoAppareilMobile",
      label: "inseights.details.technicalData.deviceInfo",
      sorter: true,
    },
    {
      key: "utilisateur",
      label: "inseights.details.technicalData.users",
      sorter: true,
    },
    {
      key: "utilisateur%",
      label: "inseights.details.technicalData.usersPer",
      sorter: true,
    },
  ];
  pieChart = {
    title: "Données thechnique",
    style: null,
    legend: {
      customLegendItems: Style.deviceBrand,
      position: Style.browserDeviceRepartitionLegend.position,
    },
    labels: Style.deviceBrand,
  };
  companyName: string = "";
  deviceBrand: string[] = [];
  valueDeviceBrand: number[] = [];
  percentageDeviceBrand: number[] = [];

  deviceType$: Observable<DeviceType | null>;
  deviceBrand$: Observable<Map<string, number> | null>;
  browserFamily$: Observable<BrowserFamily | null>;
  osFamily$: Observable<OsFamily | null>;
  allData: HTMLElement | null;
  adTitle: string | null;
  title: string | null;
  isLoading : boolean = true
  cachedAt : Date | null
  constructor(
    private store: Store<AppStateInterface>,
    private jwtService: JwtService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    super(store);

    this.adTitle = this.activatedRoute.snapshot.queryParamMap.get("title");
    if (this.adTitle != null) {
      this.adTitle = this.title;
    } else {
      this.title = this.jwtService.getCompanyName();
    }
    this.deviceType$ = this.store
      .pipe(select(resultDeviceTypeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.deviceBrand$ = this.store
      .pipe(select(resultDeviceBrandSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.osFamily$ = this.store
      .pipe(select(resultOsFamilySelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.browserFamily$ = this.store
      .pipe(select(resultBrowserFamilySelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());
    this.store.dispatch(setDeviceBrand({ deviceBrand: null }));
    this.store.dispatch(setDeviceType({ deviceType: null }));
    this.store.dispatch(setOsFamily({ osFamily: null }));
    this.store.dispatch(setBrowserFamily({ browserFamily: null }));
    this.store.dispatch(
      viewsByAgeRangeAndGenderByAd({ genderAgeStatistics: null })
    );

    this.search.subscribe((search: TechnicalCardSearchMethod) => {
      this.searchResult = search
      if (this.isPremium) {
        this.getData(search, false)
      }
    });
    this.companyName = this.jwtService.getCompanyName();
    this.userByBrowser = document.getElementById("userByBrowser");
    this.userByOsFamily = document.getElementById("userByOsFamily");

    this.osFamily$.subscribe((result: OsFamily | null) => {
      if (result != null) {
        this.columnChart = {
          ...this.columnChart,
          title: this.translate.instant(
            "inseights.technicalData.numberOfConsumers"
          ),
        };
        this.resultOsFamily.emit(result);
        const csvRows = [];
        let headers: string[] = [];
        headers = [
          this.translate.instant(
            "inseights.details.technicalData.operatingSystem"
          ),
          this.translate.instant("inseights.details.technicalData.users"),
        ];
        for (let i = 0; i < Object.keys(result).length; i++) {
          const cols = [Object.keys(result)[i], Object.values(result)[i]];
          csvRows.push(cols.join(","));
        }
        for (let i = 0; i < this.technicalSpecifications.length; i++) {
          if (
            this.technicalSpecifications[i].type ==
            exportDataType.TECHNICAL_DATA_OPERATING_SYSTEM
          )
            this.technicalSpecifications[i].data = [headers, ...csvRows].join(
              "\n"
            );
        }
      }
    });
    this.browserFamily$.subscribe((result: BrowserFamily | null) => {
      if (result != null) {
        this.resultbrowserFamily.emit(result);
        const csvRows = [];
        let headers: string[] = [];
        headers = [
          this.translate.instant("inseights.details.technicalData.navigator"),
          this.translate.instant("inseights.details.technicalData.users"),
        ];
        for (let i = 0; i < Object.keys(result).length; i++) {
          const cols = [Object.keys(result)[i], Object.values(result)[i]];
          csvRows.push(cols.join(","));
        }
        for (let i = 0; i < this.technicalSpecifications.length; i++) {
          if (
            this.technicalSpecifications[i].type ==
            exportDataType.TECHNICAL_DATA_NAVIGATOR
          )
            this.technicalSpecifications[i].data = [headers, ...csvRows].join(
              "\n"
            );
        }
      }
    });
    this.deviceType$.subscribe((result) => {
      if (result != null) {
        this.resultDeviceType.emit(result);
        this.cachedAt = result.cachedAt
        const csvRows = [];
        let headers: string[] = [];
        headers = [
          this.translate.instant(
            "inseights.details.technicalData.deviceCategory"
          ),
          this.translate.instant("inseights.details.technicalData.users"),
        ];
        for (let i = 0; i < Object.keys(result).length; i++) {
          const cols = [Object.keys(result)[i], Object.values(result)[i]];
          csvRows.push(cols.join(","));
        }
        for (let i = 0; i < this.technicalSpecifications.length; i++) {
          if (
            this.technicalSpecifications[i].type ==
            exportDataType.TECHNICAL_DATA_DEVICE_CATEGORY
          )
            this.technicalSpecifications[i].data = [headers, ...csvRows].join(
              "\n"
            );
        }
      }
    });
    this.deviceBrand$.subscribe((result: Map<string, number> | null) => {
      if (result) {
        this.isLoading = false
        this.resultDeviceBrand.emit(result);
        const sortedEntries = Object.entries(result).sort((a, b) => b[1] - a[1]);
        const objetTrie = sortedEntries.reduce((acc: { [key: string]: number }, [key, value]: [string, any]) => {
          acc[key] = value;
          return acc;
        }, {});
        Object.values(objetTrie).filter((value, index) => {
          this.deviceBrand.push(Object.keys(objetTrie)[index]);
          this.valueDeviceBrand.push(Object.values(objetTrie)[index]);
        });
        this.deviceBrand = [...new Set(this.deviceBrand)]
        Object.values(this.valueDeviceBrand).filter((value, index) => {
          this.percentageDeviceBrand.push(
            (this.valueDeviceBrand[index] /
              this.valueDeviceBrand.reduce(
                (acc, current) => acc + current,
                0
              )) *
              100
          );
        });
        const csvRows = [];
        let headers: string[] = [];
        headers = [
          this.translate.instant(
            "inseights.details.technicalData.operatingSystem"
          ),
          this.translate.instant("inseights.details.technicalData.users"),
        ];
        for (let i = 0; i < Object.keys(result).length; i++) {
          const cols = [Object.keys(objetTrie)[i], Object.values(objetTrie)[i]];
          csvRows.push(cols.join(","));
        }
        for (let i = 0; i < this.technicalSpecifications.length; i++) {
          if (
            this.technicalSpecifications[i].type ==
            exportDataType.TECHNICAL_DATA_TOTAL_DISTRIBUTION
          )
            this.technicalSpecifications[i].data = [headers, ...csvRows].join(
              "\n"
            );
        }
      }

    });
  }

  getData(search: TechnicalCardSearchMethod, refreshCache : boolean){
    this.isLoading = true
    this.store.dispatch(invokeOsFamily({ search: search, refreshCache : refreshCache }));
    this.store.dispatch(invokeBrowserFamily({ search: search, refreshCache : refreshCache }));
    this.store.dispatch(invokeDeviceType({ search: search, refreshCache : refreshCache }));
    this.store.dispatch(invokeDeviceBrand({ search: search, refreshCache : refreshCache }));
  }
}
