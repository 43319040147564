import { OptionActionInterface } from "../models/option-action.interface";

export interface ActionInterface {
  name: string;
  icon: string | null;
  type: OptionActionEnum;
  disabled: boolean,
}

export interface ActionReturned {
  option : ActionInterface;
  value: any;
}

export enum OptionActionEnum {
  ADMIN_ADVERTISER_DETAILS,
  ADMIN_ADVERTISER_UPDATE,
  ADMIN_ADVERTISER_UPDATE_STATUS,
  ADMIN_ADVERTISER_ACTIVATE,
  ADMIN_ADVERTISER_DESACTIVATE,
  ADMIN_ADVERTISER_DELETE,
  ADMIN_ADVERTISER_ADD_SYNTHESIS_REPORT,

  ADMIN_CAMPAIGN_ADVERTISER_DETAILS,
  ADMIN_CAMPAIGN_ADVERTISER_UPDATE,
  ADMIN_CAMPAIGN_SHOW_VIDEO,
  ADMIN_CAMPAIGN_UPDATE_STATUS,
  ADMIN_CAMPAIGN_LP_UPDATE,
  ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT,
  CAMPAIGNS_STATISTICS,

  CAMPAIGN_ADD_JUSTIFICATION_FILE,
  CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE,
  CAMPAIGN_DELETE,
  ADVERTISER_CAMPAIGN_UPDATE,
  CAMPAIGN_DETAILS,

  ADVERTISER_CAMPAIGN_PUBLISH,

  ADVERTISER_CAMPAIGNS_STATISTICS,
  ADVERTISER_CAMPAIGNS_BILLING,
  ADVERTISER_CAMPAIGNS_PUBLISH,
  ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE,
  ADVERTISER_CAMPAIGNS_DOWNLOAD_INVOICE,

  ADMIN_ROUTER_UPDATE,
  ADMIN_ROUTER_DELETE,
  ADMIN_ROUTER_DETAILS,

  ADMIN_BUG_REPORT,

  ADMIN_REMUNERATION_UPDATE,
  ADMIN_REMUNERATION_DOWNLOAD,
  
  ADMIN_LOCATION_DETAILS,
  ADMIN_LOCATION_UPDATE,
  ADMIN_LOCATION_DELETE,
  ADMIN_LOCATION_VIEWS_DETAILS,
  ADMIN_LOCATION_PREMIUM_ACCESS,

  ADMIN_OBJECTIVE_UPDATE,

  ADMIN_UPDATE_SETTINGS,
  ADMIN_DELETE_SETTINGS,

  ADMIN_FIXED_CHARGES_UPDATE,
  ADMIN_FIXED_CHARGES_DELETE,

  ADMIN_COLLABORATOR_DELETE,
  ADMIN_COLLABORATOR_UPDATE,
  ADMIN_COLLABORATOR_HISTORY,

  ADMIN_SUBROLE_EDIT,
  ADMIN_SUBROLE_DELETE,

  ADMIN_UPDATE_ZONE,
  ADMIN_DELETE_ZONE,

  ADMIN_CATEGORY_UPDATE,
  ADMIN_CATEGORY_DELETE,

  ADMIN_TAG_DELETE,
  ADMIN_TAG_UPDATE,

  ADMIN_BILLING_UPDATE,
  ADMIN_BILLING_SHOW,

  ADMIN_ESTIMATE_UPDATE,
  ADMIN_ESTIMATE_SHOW,

  ADMIN_SUBCATEGORY_DELETE,
  ADMIN_SUBCATEGORY_UPDATE,

  ADMIN_REGION_DELETE,
  ADMIN_REGION_UPDATE,
  
  ADMIN_UPDATE_LOCATION_IMPRESSIONS_MANUALLY,
  ADMIN_UPDATE_LOCATION_IMPRESSIONS,

  ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION = "ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION",
  //ADMIN_UPDATE_LOCATION_COMPANY_INFORMATION = "ADMIN_UPDATE_LOCATION_COMPANY_INFORMATION",
  ADMIN_UPDATE_LOCATION_COMPANY_SPECIFICATION = "ADMIN_UPDATE_LOCATION_COMPANY_SPECIFICATION",
  ADMIN_UPDATE_LOCATION_LOCATION_INFORMATION = "ADMIN_UPDATE_LOCATION_LOCATION_INFORMATION",
  ADMIN_UPDATE_LOCATION_PARTNER_STATUS_INFORMATION = "ADMIN_UPDATE_LOCATION_PARTNER_STATUS_INFORMATION",

  ADMIN_ROUTER_UPDATE_ACCESS_INFORMATION = "ADMIN_ROUTER_UPDATE_ACCESS_INFORMATION",
  ADMIN_ROUTER_UPDATE_ROUTER_SPECIFICATION = "ADMIN_ROUTER_UPDATE_ROUTER_SPECIFICATION",
  ADMIN_ROUTER_UPDATE_LOCATION_INFORMATION = "ADMIN_ROUTER_UPDATE_LOCATION_INFORMATION",

  ADMIN_ADVERTISER_UPDATE_PERSONAL_INFORMATION = "ADMIN_ADVERTISER_UPDATE_PERSONAL_INFORMATION",
  ADMIN_ADVERTISER_UPDATE_COMPANY_INFORMATION = "ADMIN_ADVERTISER_UPDATE_COMPANY_INFORMATION",
  ADMIN_ADVERTISER_UPDATE_BILLING = "ADMIN_ADVERTISER_UPDATE_BILLING",

  ADMIN_CREATE_SIMPLE_LOCATION = "ADMIN_CREATE_SIMPLE_LOCATION",
  ADMIN_CREATE_FRANCHISE = "ADMIN_CREATE_FRANCHISE",
  ADMIN_CREATE_FRANCHISEE = "ADMIN_CREATE_FRANCHISEE",

  ADMIN_UPDATE_LOCATION_PAYMENT_STATUS = "ADMIN_UPDATE_LOCATION_PAYMENT_STATUS",

  ADMIN_SETTINGS_PROMOTION_UPDATE = "ADMIN_SETTINGS_PROMOTION_UPDATE",
  ADMIN_SETTINGS_PROMOTION_DELETE = "ADMIN_SETTINGS_PROMOTION_DELETE"
  
  
}

export const advertiserActions: ActionInterface[] = [
  {
    name: "actions.advertiser.advertiserDetails",
    icon: "icofont icofont-document-search",
    type: OptionActionEnum.ADMIN_ADVERTISER_DETAILS,
    disabled : false
  },
  {
    name: "actions.advertiser.updateAdvertiser",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_ADVERTISER_UPDATE,
    disabled : false
  },
  {
    name: "actions.advertiser.updateStatus",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_ADVERTISER_UPDATE_STATUS,
    disabled : false
  },
  /*{
    name: "actions.advertiser.activateAccount",
    icon: "fa fa-check-square-o",
    type: OptionActionEnum.ADMIN_ADVERTISER_ACTIVATE,
    disabled : false
  },
  {
    name: "actions.advertiser.desableAccount",
    icon: "fa fa-times-circle",
    type: OptionActionEnum.ADMIN_ADVERTISER_DESACTIVATE,
    disabled : false
  },*/
  {
    name: "actions.advertiser.deleteAdvertiser",
    icon: "fa fa-trash-o",
    type: OptionActionEnum.ADMIN_ADVERTISER_DELETE,
    disabled : false
  },
];

export const adminCampaignActions: ActionInterface[] = [
  {
    name: "dashboard.statics",
    icon: "icofont icofont-chart-histogram",
    type: OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS,
    disabled : false
  },
  {
    name: "actions.campaignDetails",
    icon: "icofont icofont-document-search",
    type: OptionActionEnum.CAMPAIGN_DETAILS,
    disabled : false
  },
  {
    name: "actions.seeVideo",
    icon: "fa fa-file-video-o",
    type: OptionActionEnum.ADMIN_CAMPAIGN_SHOW_VIDEO,
    disabled : false
  },
  {
    name: "actions.updateCampaign",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_CAMPAIGN_ADVERTISER_UPDATE,
    disabled : false
  },
  {
    name: "campaignDetails.updateStatusCampaign",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_CAMPAIGN_UPDATE_STATUS,
    disabled : false
  },
  {
    name: "actions.uploadJustificationFile",
    icon: "icofont icofont-ui-file",
    type: OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE,
    disabled : false
  },
  {
    name: "actions.downloadJustificationFile",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE,
    disabled : false
  },
  {
    name: "titleSynthesisReport",
    icon: "icofont icofont-file-alt",
    type: OptionActionEnum.ADMIN_CAMPAIGN_ADD_SYNTHESIS_REPORT,
    disabled : false
  },
  {
    name: "actions.deleteCampaign",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.CAMPAIGN_DELETE,
    disabled : false
  },
];
export const advertiserCampaignDraftActions: ActionInterface[] = [
  {
    name: "dashboard.publish",
    icon: "icofont icofont-share",
    type: OptionActionEnum.ADVERTISER_CAMPAIGN_PUBLISH,
    disabled : false
  },
  {
    name: "actions.updateCampaign",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADVERTISER_CAMPAIGN_UPDATE,
    disabled : false
  },
  {
    name: "actions.deleteCampaign",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.CAMPAIGN_DELETE,
    disabled : false
  },
];

export const advertiserCampaignLastActions: ActionInterface[] = [
  {
    name: "dashboard.statics",
    icon: "icofont icofont-chart-histogram",
    type: OptionActionEnum.CAMPAIGN_DETAILS,
    disabled : false
  },
  {
    name : "actions.estimate.downloadEstimate",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE,
    disabled : false
  },
  {
    name: "actions.uploadJustificationFile",
    icon: "icofont icofont-ui-file",
    type: OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE,
    disabled : false
  },
  {
    name: "actions.downloadJustificationFile",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE,
    disabled : false
  },
  {
    name: "actions.downloadInvoice",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_INVOICE,
    disabled : false
  }
];

export const advertiserCampaignAwaitingPaymentActions: ActionInterface[] = [
  {
    name: "dashboard.statics",
    icon: "icofont icofont-chart-histogram",
    type: OptionActionEnum.CAMPAIGN_DETAILS,
    disabled : false
  },
  {
    name: "dashboard.publish",
    icon: "icofont icofont-share",
    type: OptionActionEnum.ADVERTISER_CAMPAIGN_PUBLISH,
    disabled : false
  },
  {
    name: "actions.updateCampaign",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADVERTISER_CAMPAIGN_UPDATE,
    disabled : false
  },
  {
    name : "actions.estimate.downloadEstimate",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE,
    disabled : false
  },
  {
    name: "actions.deleteCampaign",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.CAMPAIGN_DELETE,
    disabled : false
  },
];

export const advertiserCampaignAwaitingApprovalActions: ActionInterface[] = [
  {
    name: "dashboard.statics",
    icon: "icofont icofont-chart-histogram",
    type: OptionActionEnum.CAMPAIGN_DETAILS,
    disabled : false
  },
  {
    name : "actions.estimate.downloadEstimate",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE,
    disabled : false
  },
  {
    name: "actions.uploadJustificationFile",
    icon: "icofont icofont-ui-file",
    type: OptionActionEnum.CAMPAIGN_ADD_JUSTIFICATION_FILE,
    disabled : false
  },
  {
    name: "actions.downloadJustificationFile",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.CAMPAIGN_DOWNLOAD_JUSTIFICATION_FILE,
    disabled : false
  },
  {
    name: "actions.downloadInvoice",
    icon: "icofont icofont-download-alt",
    type: OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_INVOICE,
    disabled : false
  },
];

export const locationCampaignActions: ActionInterface[] = [
  {
    name: "dashboard.statics",
    icon: "icofont icofont-chart-histogram",
    type: OptionActionEnum.CAMPAIGNS_STATISTICS,
    disabled : false
  },
  {
    name: "actions.campaignDetails",
    icon: "icofont icofont-document-search",
    type: OptionActionEnum.CAMPAIGN_DETAILS,
    disabled : false
  },
  {
    name: "actions.seeVideo",
    icon: "fa fa-file-video-o",
    type: OptionActionEnum.ADMIN_CAMPAIGN_SHOW_VIDEO,
    disabled : false
  },
  {
    name: "campaignDetails.updateAdvertisingCampaign",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_CAMPAIGN_LP_UPDATE,
    disabled : false
  },
  {
    name: "campaignDetails.updateStatusCampaign",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_CAMPAIGN_UPDATE_STATUS,
    disabled : false
  },
  {
    name: "actions.deleteCampaign",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.CAMPAIGN_DELETE,
    disabled : false
  },
];
export const routerActions: ActionInterface[] = [
  {
    name: "actions.detailsRouter",
    icon: "icofont icofont-document-search",
    type: OptionActionEnum.ADMIN_ROUTER_DETAILS,
    disabled : false
  },
  {
    name: "actions.updateRouter",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_ROUTER_UPDATE,
    disabled : false
  },
  {
    name: "actions.deleteRouter",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_ROUTER_DELETE,
    disabled : false
  },
];
export const updateRouterActions : ActionInterface[] = [
  {
    name: "addRouter.step1.title",
    icon: "icofont icofont-ui-unlock",
    type: OptionActionEnum.ADMIN_ROUTER_UPDATE_ACCESS_INFORMATION,
    disabled : false
  },
  {
    name: "addRouter.routerSpecificationStep.title",
    icon: "icofont icofont-wifi-router",
    type: OptionActionEnum.ADMIN_ROUTER_UPDATE_ROUTER_SPECIFICATION,
    disabled : false
  },
  {
    name: "addRouter.locationInformation.title",
    icon: "icofont icofont-location-pin",
    type: OptionActionEnum.ADMIN_ROUTER_UPDATE_LOCATION_INFORMATION,
    disabled : false
  },
]
export const bugRepportActions: ActionInterface[] = [
  {
    name: "actions.updateStatusBugReport",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_BUG_REPORT,
    disabled : false
  },
];
export const remunerationPayedActions: ActionInterface[] = [
  {
    name: "Upload facture",
    icon: null,
    type: OptionActionEnum.ADMIN_REMUNERATION_UPDATE,
    disabled : false
  },
  {
    name: "Télécharger facture",
    icon: null,
    type: OptionActionEnum.ADMIN_REMUNERATION_DOWNLOAD,
    disabled : false
  },
];
export const remunerationWaitingActions: ActionInterface[] = [
  {
    name: "Télécharger facture",
    icon: null,
    type: OptionActionEnum.ADMIN_REMUNERATION_DOWNLOAD,
    disabled : false
  },
];
export const locationActions: ActionInterface[] = [
  {
    name: "LPOptions.details",
    icon: "icofont icofont-document-search",
    type: OptionActionEnum.ADMIN_LOCATION_DETAILS,
    disabled : false
  },
  {
    name: "LPOptions.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_LOCATION_UPDATE,
    disabled : false
  },
  {
    name: "LPOptions.grantPremium",
    icon: "icofont icofont-badge",
    type: OptionActionEnum.ADMIN_LOCATION_PREMIUM_ACCESS,
    disabled : false
  },
  {
    name: "LPOptions.delete",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_LOCATION_DELETE,
    disabled : false
  },
];
export const objectiveActions: ActionInterface[] = [
  {
    name: "actions.objective.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_OBJECTIVE_UPDATE,
    disabled : false
  },
];
export const regionActions : ActionInterface[] = [
  {
    name: "admin.settings.region.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_UPDATE_SETTINGS,
    disabled : false
  },
  {
    name: "admin.settings.region.delete.title",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_DELETE_SETTINGS,
    disabled : false
  },
]
export const fixedChargesActions : ActionInterface[] = [
  {
    name: "admin.settings.fixedCharges.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_FIXED_CHARGES_UPDATE,
    disabled : false
  },
  {
    name: "admin.settings.fixedCharges.delete.title",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_FIXED_CHARGES_DELETE,
    disabled : false
  },
]
export const zoneActions : ActionInterface[] = [
  {
    name: "admin.settings.zone.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_UPDATE_ZONE,
    disabled : false
  },
  {
    name: "admin.settings.zone.delete.title",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_DELETE_ZONE,
    disabled : false
  },
]
export const categoryActions : ActionInterface[] = [
  {
    name: "admin.settings.category.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_UPDATE_SETTINGS,
    disabled : false
  },
  {
    name: "admin.settings.category.delete.title",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_DELETE_SETTINGS,
    disabled : false
  },
]
export const subCategoryActions : ActionInterface[] = [
  {
    name: "admin.settings.subCategory.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_UPDATE_SETTINGS,
    disabled : false
  },
  {
    name: "admin.settings.subCategory.delete.title",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_DELETE_SETTINGS,
    disabled : false
  },
]
export const tagActions : ActionInterface[] = [
  {
    name: "admin.settings.tags.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_TAG_UPDATE,
    disabled : false
  },
  {
    name: "admin.settings.tags.delete.title",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_TAG_DELETE,
    disabled : false
  },
]
export const collaboratorActions: ActionInterface[] = [
  {
    name: "admin.settings.access.collaborator.history",
    icon: "icofont icofont-ssl-security",
    type: OptionActionEnum.ADMIN_COLLABORATOR_HISTORY,
    disabled : false
  },
  {
    name: "admin.settings.access.collaborator.update",
    icon: "icofont icofont-edit-alt",
    type: OptionActionEnum.ADMIN_COLLABORATOR_UPDATE,
    disabled : false
  },
  {
    name: "admin.settings.access.collaborator.delete",
    icon: "icofont icofont-trash",
    type: OptionActionEnum.ADMIN_COLLABORATOR_DELETE,
    disabled : false
  },
];
export const subRoleActions : ActionInterface[] = [
  {
    name : "admin.settings.access.params.update",
    icon: "icofont icofont-edit-alt",
    type : OptionActionEnum.ADMIN_SUBROLE_EDIT,
    disabled : false
  },
  {
    name : "admin.settings.access.params.delete",
    icon: "icofont icofont-trash",
    type : OptionActionEnum.ADMIN_SUBROLE_DELETE,
    disabled : false
  }
]
export const updateLocation: ActionInterface[] = [
  {
    name: "enumMapping.updateLPcards.ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION",
    icon: "icofont icofont-ui-user",
    type: OptionActionEnum.ADMIN_UPDATE_LOCATION_PERSONNELLE_INFORMATION,
    disabled : false
  },
  /*{
    name: "addLP.secondStep.title",
    icon: "icofont icofont-institution",
    type: OptionActionEnum.ADMIN_UPDATE_LOCATION_COMPANY_INFORMATION,
  },*/
  {
    name: "enumMapping.updateLPcards.ADMIN_UPDATE_LOCATION_COMPANY_SPECIFICATION",
    icon: "icofont icofont-gears",
    type: OptionActionEnum.ADMIN_UPDATE_LOCATION_COMPANY_SPECIFICATION,
    disabled : false
  },
  {
    name: "enumMapping.updateLPcards.ADMIN_UPDATE_LOCATION_LOCATION_INFORMATION",
    icon: "icofont icofont-location-pin",
    type: OptionActionEnum.ADMIN_UPDATE_LOCATION_LOCATION_INFORMATION,
    disabled : false
  },
  {
    name: "enumMapping.updateLPcards.ADMIN_UPDATE_LOCATION_PARTNER_STATUS_INFORMATION",
    icon: "icofont icofont-bill",
    type: OptionActionEnum.ADMIN_UPDATE_LOCATION_PARTNER_STATUS_INFORMATION,
    disabled : false
  },
];

export const updateAdvertiserActions : ActionInterface[] = [
  {
    name: "enumMapping.updateAdvertiserCards.ADMIN_ADVERTISER_UPDATE_PERSONAL_INFORMATION",
    icon: "icofont icofont-ui-user",
    type: OptionActionEnum.ADMIN_ADVERTISER_UPDATE_PERSONAL_INFORMATION,
    disabled : false
  },
  {
    name: "enumMapping.updateAdvertiserCards.ADMIN_ADVERTISER_UPDATE_COMPANY_INFORMATION",
    icon: "icofont icofont-institution",
    type: OptionActionEnum.ADMIN_ADVERTISER_UPDATE_COMPANY_INFORMATION,
    disabled : false
  },
  {
    name: "enumMapping.updateAdvertiserCards.ADMIN_ADVERTISER_UPDATE_BILLING",
    icon: "icofont icofont-bill",
    type: OptionActionEnum.ADMIN_ADVERTISER_UPDATE_BILLING,
    disabled : false
  },
]

export const createLocation: ActionInterface[] = [
  {
    name: "addLP.addSimpleLp",
    icon: "icofont icofont-location-pin",
    type: OptionActionEnum.ADMIN_CREATE_SIMPLE_LOCATION,
    disabled : false
  },
  {
    name: "addLP.addFranchise",
    icon: "icofont icofont-company",
    type: OptionActionEnum.ADMIN_CREATE_FRANCHISE,
    disabled : true
  },
  {
    name: "addLP.addFranchisee",
    icon: "icofont icofont-institution",
    type: OptionActionEnum.ADMIN_CREATE_FRANCHISEE,
    disabled : true
  }
];

export const paymentLP : ActionInterface[] = [
  {
    name : "enumMapping.paymentLP.updatePaymentStatus",
    icon: "icofont icofont-bill",
    type : OptionActionEnum.ADMIN_UPDATE_LOCATION_PAYMENT_STATUS,
    disabled : false
  }
]
export const promotionCode : ActionInterface[] = [
  {
    name : "actions.promotionCode.update",
    icon: "icofont icofont-edit-alt",
    type : OptionActionEnum.ADMIN_SETTINGS_PROMOTION_UPDATE,
    disabled : false
  },
  {
    name : "actions.promotionCode.delete",
    icon: "icofont icofont-trash",
    type : OptionActionEnum.ADMIN_SETTINGS_PROMOTION_DELETE,
    disabled : false
  }
]
export const billing_admin : ActionInterface[] = [
  {
    name : "buttons.showInvoice",
    icon: "icofont icofont-file-document",
    type : OptionActionEnum.ADMIN_BILLING_SHOW,
    disabled : false
  },
  {
    name : "buttons.downloadInvoice",
    icon: "icofont icofont-download-alt",
    type : OptionActionEnum.ADMIN_BILLING_UPDATE,
    disabled : false
  }
]
export const estimates_admin : ActionInterface[] = [
  {
    name : "buttons.showEstimate",
    icon: "icofont icofont-file-document",
    type : OptionActionEnum.ADMIN_ESTIMATE_SHOW,
    disabled : false
  },
  {
    name : "buttons.downloadEstimate",
    icon: "icofont icofont-download-alt",
    type : OptionActionEnum.ADMIN_ESTIMATE_UPDATE,
    disabled : false
  }
]
export const advertiser_campaigns : ActionInterface[] = [
  {
    name : "dashboard.statics",
    icon: "icofont icofont-chart-histogram",
    type : OptionActionEnum.ADVERTISER_CAMPAIGNS_STATISTICS,
    disabled : false
  },
  {
    name : "dashboard.billing",
    icon: "icofont icofont-credit-card",
    type : OptionActionEnum.ADVERTISER_CAMPAIGNS_BILLING,
    disabled : false
  },
  {
    name : "dashboard.publish",
    icon: "icofont icofont-share",
    type : OptionActionEnum.ADVERTISER_CAMPAIGNS_PUBLISH,
    disabled : false
  },
  {
    name : "buttons.delete",
    icon: "icofont icofont-trash",
    type : OptionActionEnum.CAMPAIGN_DELETE,
    disabled : false
  },
  {
    name : "actions.estimate.downloadEstimate",
    icon: "icofont icofont-download-alt",
    type : OptionActionEnum.ADVERTISER_CAMPAIGNS_DOWNLOAD_ESTIMATE,
    disabled : false
  }
]
export const locationPartnerImpressions : ActionInterface[] = [
  {
    name : "buttons.updateLocationImpressionsManually",
    icon: "icofont icofont-edit-alt",
    type : OptionActionEnum.ADMIN_UPDATE_LOCATION_IMPRESSIONS_MANUALLY,
    disabled : false
  },
  {
    name : "buttons.updateLocationImpressions",
    icon: "icofont icofont-edit-alt",
    type : OptionActionEnum.ADMIN_UPDATE_LOCATION_IMPRESSIONS,
    disabled : false
  }
]