import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, takeUntil } from "rxjs";
import {
  setAdvertisingCampaign,
  invokeLatestAdvertisingCampaigns,
  invokeAddAdvertisingCampaign,
  SetAddAdvertisingCampaign,
  invokeAdvertisingCompaignFilter,
  advertisingCompaignResult,
  invokeAdvertisingCompaignByHashedId,
  getAdvertisingCompaignByHashedId,
  invokeUpdateAdCampaignDetails,
  updateAdCampaignDetails,
  invokeUpdateAdCampaignStatus,
  upadateAdCampaignStatus,
  invokeDeleteAdCampaign,
  invokeBestAdvertisingCampaign,
  bestAdvertisingCampaign,
  invokeAllAdsByStatusAndPublisher,
  resultAllAdsByStatusAndPublisher,
  invokeAddSynthesisReport,
  resultAddSynthesisReport,
  invokeUpadateAdPaymentDetails,
  invokeChangeAdStatus,
  resultSuccessAd,
  invokeStatusesByListAds,
  resultStatusesByListAds,
  invokeCheckAdvertisingCampaignExistByTitle,
  resultCheckAdvertisingCampaignExistByTitle,
  invokeUploadJustificationFile,
  resultUploadJustificationFile,
  invokeGetAdvertiserAdvetisingCampaigns,
  resultAdvertiserAdvetisingCampaigns,
} from "./advertisingCampaign.actions";
import {
  invokeApiDistroyed,
  invokeComponentDistroyed,
  setAPIStatus,
} from "../apiState.interface";
import { AdvertisingCampaignService } from "src/app/shared/services/advertising-campaign.service";
import { ResultFilterAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/result-filter-advertising-compaign.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { BestAdvertisingCampaign } from "src/app/shared/models/advertising-campaigns/best-advertising-campaign.interface";
import { AdsPublisherDetailsAndStatus } from "src/app/shared/models/advertising-campaigns/ads-publisher-details-and-status.interface";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";

@Injectable()
export class AdvertisingCampaignEffect {
  constructor(
    private actions$: Actions,
    private advertiserCampaignService: AdvertisingCampaignService
  ) {}

  invokeAdvertisingCampaign = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLatestAdvertisingCampaigns),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .getLatestAdvertisingCampaigns()
          .pipe(
            map((data: AdvertisingCampaignInterface[] | null) =>
              setAdvertisingCampaign({ advertisingCampaign: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeAddAdvertisingCampaign = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAddAdvertisingCampaign),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .addAdvertisingCampaign(data.addCampaign)
          .pipe(
            map((data: SuccessDto | null) =>
              SetAddAdvertisingCampaign({ adCreated: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeFilterAdvertisingCompaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAdvertisingCompaignFilter),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .filterAdvertisingCompaign(data.filter, data.refreshCache)
          .pipe(
            map((data: ResultFilterAdvertisingCompaign) =>
              advertisingCompaignResult({ listAdvertisingCompaign: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );
  invokeAdvertisingCompaignByTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAdvertisingCompaignByHashedId),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .getAdvertisingCompaignByHashedId(data.campaignHashedId)
          .pipe(
            map((data: AdvertisingCampaignInterface | null) =>
              getAdvertisingCompaignByHashedId({ resultCampaignByHashedId: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
           // takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeUpdateAdCampaignDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateAdCampaignDetails),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .updateAdCampaignDetails(data.campaignHashedId, data.adCompaign)
          .pipe(
            map((data: SuccessDto | null) =>
              updateAdCampaignDetails({ successMessage: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeUpadateAdPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpadateAdPaymentDetails),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .invokeUpadateAdPaymentDetails(data.payment)
          .pipe(
            map((data: SuccessDto | null) =>
              updateAdCampaignDetails({ successMessage: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  deleteAdCampaignByHashedId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteAdCampaign),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .deleteAdCampaignByHashedId(data.campaignHashedId)
          .pipe(
            map((data: SuccessDto | null) =>
              upadateAdCampaignStatus({ successMessage: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  bestAdvertisingCampaignResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeBestAdvertisingCampaign),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .bestAdvertisingCampaignResult(data.isRefreshCache)
          .pipe(
            map((data: BestAdvertisingCampaign | null) =>
              bestAdvertisingCampaign({ adCompaign: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  resultAdsByPublisherRoleAndStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllAdsByStatusAndPublisher),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .getAllAdsByStatusAndPublisherRole(data.filterCalendar)
          .pipe(
            map((data: AdvertisingCampaignInterface[] | null) =>
              resultAllAdsByStatusAndPublisher({ advertisingCampaignDto: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );
  resultAddSynthesisReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAddSynthesisReport),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .addSynthesisReport(data.campaignHashedId, data.synthesisReport)
          .pipe(
            map((data: SuccessDto | null) =>
              resultAddSynthesisReport({ successMessage: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeUpdateAdCampaignStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateAdCampaignStatus),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .updateAdCampaignStatus(data.campaignHashedId, data.status)
          .pipe(
            map((data: SuccessDto | null) =>
              upadateAdCampaignStatus({ successMessage: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeChangeAdvertisingCampaignStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeChangeAdStatus),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .updateAdvertisingCampaignStatus(data.campaignHashedId, data.status)
          .pipe(
            map((data: SuccessDto | null) =>
              resultSuccessAd({ successMessage: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeStatusesByListTitles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeStatusesByListAds),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .getStatusesByAdTiles(data.campaignsHashedIds)
          .pipe(
            map((data: AdsPublisherDetailsAndStatus | null) =>
              resultStatusesByListAds({ publisherAndStatusDetails: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeCheckAdvertisingCampaignExistByTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCheckAdvertisingCampaignExistByTitle),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .getCheckAdvertisingCampaignExistByTitle(data.title)
          .pipe(
            map((data: boolean) =>
              resultCheckAdvertisingCampaignExistByTitle({ isExist: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeUploadJustificationFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUploadJustificationFile),
      mergeMap((data) => {
        return this.advertiserCampaignService
          .uploadJustificationFile(data.campaignHashedId, data.justificationFile)
          .pipe(
            map((data: SuccessDto | null) =>
              resultUploadJustificationFile({ successUpload: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );

  invokeGetAdvertiserAdvetisingCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGetAdvertiserAdvetisingCampaigns),
      mergeMap((data) => {
        return this.advertiserCampaignService.getAdvertiserAds(data.email).pipe(
          map((data: string[]) =>
            resultAdvertiserAdvetisingCampaigns({ advertisingCamapigns: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
