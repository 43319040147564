import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Constants } from "../data/constants";
import { AdminMainDashboard } from "../models/admin/admin-main-dashboard.interface";
import {
  CollaboratorInterface,
  CreateUpdateCollaboratorInterface,
} from "../models/admin/collaborator.interface";
import { SuccessDto } from "../models/success-dto";
import { AdminProfileInterface } from "../models/admin/admin-profile.interface";
import { SideBarNewsInterface } from "../models/admin/side-bar-news.interface";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  invokeSideBarNews() : Observable<SideBarNewsInterface> {
    return this.http.get<SideBarNewsInterface>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/getSideBarNews`
    );
  }
  constructor(private http: HttpClient) {}

  getMainDashboard(refreshCache : boolean): Observable<AdminMainDashboard> {
    return this.http.get<AdminMainDashboard>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/${refreshCache}/main`
    );
  }

  getCollaborators(): Observable<CollaboratorInterface[]> {
    return this.http.get<CollaboratorInterface[]>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/collaborators`
    );
  }

  createCollaborator(
    collaborator: CreateUpdateCollaboratorInterface
  ): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/create`,
      collaborator
    );
  }
  updateCollaborator(
    email: string,
    collaborator: CreateUpdateCollaboratorInterface
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/update/` + email,
      collaborator
    );
  }

  deleteCollaborator(email: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/delete/` + email
    );
  }

  getProfile(): Observable<AdminProfileInterface> {
    return this.http.get<AdminProfileInterface>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/getProfile`
    );
  }

  updateAdminProfile(profile: AdminProfileInterface): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${environment.APP_PORT + Constants.ADMIN_ENDPOINT}/updateProfile`,
      profile
    );
  }
}
