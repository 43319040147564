import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { ExportData, SummaryChartAdmin } from "src/app/shared/data/exportData";
import { SeriesTitlesToShow } from "src/app/shared/models/chart/seriesTitles";
import { ConsumerCountByDate } from "src/app/shared/models/consumers/consumerCountByDate";
import { DateFilter } from "src/app/shared/models/date-filte.interface";
import { InputAudience } from "src/app/shared/models/input-audience.interface";
import { SelectedLps } from "src/app/shared/models/location-partners/selectedLps";
import { LocationAndDateFilter } from "src/app/shared/models/LocationAndDateFilter";
import { SummaryStatics } from "src/app/shared/models/statistics/summary-statics";
import { RoleEnum } from "src/app/shared/models/user/role.interface";
import { JwtService } from "src/app/shared/services/jwt.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeConsumersCountInDay,
  invokeCountConsumersBetweenDates,
} from "src/app/store/consumers/consumers.actions";
import { countConsumersForAdminSelector } from "src/app/store/consumers/consumers.selectors";
import { saveSelectedLPsSelector } from "src/app/store/locationPartner/location-partner.selectors";
import {
  invokeAllSummaryChartInDateBetween,
  invokeSummaryChartInDay,
  invokeSummaryChartInDayForAd,
  invokeSummaryChartInDayForLP,
  invokeSummaryStaticsInDateBetweenForAd,
  invokeSummaryStaticsInDateBetweenForLP,
} from "src/app/store/statistics/statistics.actions";
import {
  loadingSummaryStatisticsSelector,
  summaryStatisticsSelector,
} from "src/app/store/statistics/statistics.selectors";

let primary_color = "#FF69B4";
@Component({
  selector: "app-spline",
  templateUrl: "./spline.component.html",
})
export class SplineComponent extends BaseComponent implements OnInit {
  @Input("isMain") isMain : boolean
  @Input("optionSelected") optionSelected: Observable<string>;
  @Input("chartOBS") chartOBS: Observable<SummaryStatics | null>;
  @Input("resultConsumerByDate")
  resultConsumerByDate: Observable<ConsumerCountByDate>;
  @Input("summaryChart$") summaryChart$: Observable<SummaryStatics>;
  @Input("resultChartObs") resultChartObs: Observable<SummaryStatics>;
  @ViewChild("chartApex", { static: false }) chartApex: ChartComponent;
  @Output() chart = new EventEmitter<ExportData[]>();
  @Output() resultSummaryStatics = new EventEmitter<SummaryStatics>();
  @Output() resultConsumersCount =
    new EventEmitter<ConsumerCountByDate | null>();
  public chartOptions: Partial<ChartOptions> | any;

  UVLength = 0;
  P_F_ADSLength = 0;
  length: number = 0;

  consumersLoading$: Observable<boolean | null>;
  consumersLoading: boolean | null;
  isLoading$: Observable<boolean | null>;
  loading : boolean = true
  xaxis: ApexXAxis = {};
  yaxis: ApexYAxis = {};
  @Input() title = "";
  @Input() style: any;
  @Input() xaxisTitle = "";
  @Input() yaxisTitle = "";
  @Input() seriesTitles: string[] = [];
  @Input() labels: any;
  @Input() stroke: any;
  //@Input() obs: Promise<SummaryStatics | null | undefined>
  campaignHashedId: string | null = null;
  
  companyName: string | null = null
  staticStatistics: SummaryStatics;
  canvas = SummaryChartAdmin;
  day: string | undefined = "";
  summaryStatisticsResult$: Observable<SummaryStatics | null>;
  resultSummaryStaticsInDay: SummaryStatics | null;
  resultSummaryChart: SummaryStatics | null;
  label: any;
  click: number = 0;
  role: string;

  selectedLp: string | null = null;
  saveSelectedLP$: Observable<SelectedLps | null>;
  @Input() isAdFinished : boolean
  @Input() isConsumerChart : boolean
  constructor(
    private store: Store<AppStateInterface>,
    private route: ActivatedRoute,
    private jwtService: JwtService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    super(store);
    this.isLoading$ = this.store
      .pipe(select(loadingSummaryStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.consumersLoading$ = this.store
      .pipe(select(countConsumersForAdminSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.summaryStatisticsResult$ = this.store
      .pipe(select(summaryStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.saveSelectedLP$ = this.store
      .pipe(select(saveSelectedLPsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.chartOptions = {
      chart: {
        type: "area",
        height: 380,
        toolbar: {
          show: false,
        },
      },
      series: [],
      colors: [primary_color],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },

      xaxis: {
        type: "datetime",
        title: {
          text: "Dates",
        },
        categories: null,
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      title: {
        text: "",
        align: "right",
        style: {
          fontSize: "14px",
        },
      },
      subtitle: {
        text: "",
        align: "center",
        margin: 0,
        offsetX: 0,
        offsetY: 25, // Ajustez cela pour contrôler l'espacement entre les titres
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "Arial, sans-serif",
          color: "#263238",
        },
      },
    };
  }
  ngOnInit(): void {
    this.saveSelectedLP$.subscribe((data) => {
      if (data) {
        this.selectedLp = data.companyNames[0];
      }else{
        this.selectedLp = null
      }
    });
    this.consumersLoading$.subscribe((data) => {
      this.consumersLoading = data;
    });
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());
    if (this.summaryChart$ != undefined) {
      this.summaryChart$.subscribe((data) => {
        this.configChart(data);
        this.resultSummaryChart = data;
        this.length = Object.values(data).reduce((total, value) => total + value, 0);
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 0);
        if (this.click > 0) {
          setTimeout(() => {
            this.updateOptionsConsumer(data);
            this.cdr.detectChanges();
          }, 0);
        }
      });
    }
    if (this.resultConsumerByDate != undefined) {
      this.resultConsumerByDate.subscribe((data) => {
        // this.configChartConsumer(data);
        this.length = data.count.length;
        this.resultConsumersCount.emit(data);
      });
    }
    if (this.resultChartObs != undefined) {
      this.resultChartObs.subscribe((data) => {
        this.configChart(data);
        this.loading = false;
        this.length = Object.values(data).reduce((total, value) => total + value, 0);
        this.resultSummaryStatics.emit(data);
      });
    }
    //this.canvas = document.getElementById("spline");
    this.chart.emit(this.canvas);
    /** filter spline */
    if(this.optionSelected != undefined){
      this.optionSelected.subscribe((data) => {
        
        switch (data) {
          case SeriesTitlesToShow.COMPLETED:
            this.chartApex.toggleSeries(
              this.translate.instant(SeriesTitlesToShow.COMPLETED)
            );
            break;
          case SeriesTitlesToShow.UNIQUE_VIEWS:
            this.chartApex.toggleSeries(
              this.translate.instant(SeriesTitlesToShow.UNIQUE_VIEWS)
            );
            break;
          case SeriesTitlesToShow.VIEWS:
            this.chartApex.toggleSeries(
              this.translate.instant(SeriesTitlesToShow.VIEWS)
            );
            break;
          default:
            break;
        }
      });
    }
    /** fin filter spline */
    this.campaignHashedId = this.route.snapshot.params["campaignHasedID"];
    if(this.jwtService.getRoleName(this.jwtService.getToken()) == RoleEnum.ROLE_ADMIN){
      this.companyName = this.route.snapshot.queryParamMap.get("companyName");
    }else{
      this.companyName = this.jwtService.getCompanyName()
    }
    this.companyName = this.route.snapshot.queryParamMap.get("companyName");
    this.summaryStatisticsResult$.subscribe((result: SummaryStatics | null) => {
      if (result != null) {
        this.configChart(result);
        this.length = Object.values(result).reduce((total, value) => total + value, 0);
        this.UVLength = result.uniqueViews.length;
        this.P_F_ADSLength = result.peopleFinishAds.length;
        this.resultSummaryStaticsInDay = result;
        this.loading = false;
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 0);
        if (this.click > 0) {
          setTimeout(() => {
            this.updateOptions();
            this.cdr.detectChanges();
          }, 0);
        }
      }
    });
    this.isLoading$.subscribe((data) => {if(data)this.loading = data; this.consumersLoading = true});
  }
  initialChart() {
    this.loading = true;
    this.click = 0;
    this.day = "";
    const filter : LocationAndDateFilter = {
      startDate: null,
      endDate: null,
      locationPartner : null,
      finished : this.isAdFinished,
    }
    const dateFilter: DateFilter = {
      startDate: null,
      endDate: null,
    };
    if (this.isConsumerChart) {
      this.store.dispatch(
        invokeCountConsumersBetweenDates({
          dateFilter: dateFilter,
          refreshCache : false
        })
      );
      this.chartApex.updateOptions({
        chart: {
          toolbar: {
            show: false,
          },
        },
        series: [
          {
            name: SeriesTitlesToShow.CONSUMERS,
            data: this.resultSummaryChart?.views,
            color: primary_color,
          },
        ],
        labels: this.resultSummaryChart?.dates,
      });
    } else {
      
      switch (this.role) {
        case RoleEnum.ROLE_ADMIN: {
          if(this.campaignHashedId!=null){
            this.store.dispatch(
              invokeSummaryStaticsInDateBetweenForAd({
                campaignHashedId: this.campaignHashedId,
                refreshCache : false,
                filter: filter
              })
            );
          }else{
            if(this.isMain){
              const dateFilter: DateFilter = {
                startDate: null,
                endDate: null,
              };
              this.store.dispatch(
                invokeAllSummaryChartInDateBetween({ dateFilter: dateFilter, refreshCache : false })
              );
            }else{
              this.store.dispatch(
                invokeSummaryStaticsInDateBetweenForLP({
                  companyName: this.companyName,
                  dateFilter: dateFilter,
                  refreshCache : false
                })
              );
            }
          }
          break;
        }
        case RoleEnum.ROLE_ADVERTISER: {
          this.store.dispatch(
            invokeSummaryStaticsInDateBetweenForAd({
              campaignHashedId: this.campaignHashedId,
              refreshCache : false,
              filter: filter
            })
          );
          break;
        }
        case RoleEnum.ROLE_LOCATION: {
          this.store.dispatch(
            invokeSummaryStaticsInDateBetweenForLP({
              companyName: this.companyName,
              dateFilter: dateFilter,
              refreshCache : false
            })
          );
        }
      }
      this.chartApex.updateOptions({
        chart: {
          toolbar: {
            show: false,
          },
        },
        series: [
          {
            name: this.translate.instant(SeriesTitlesToShow.VIEWS),
            data: this.resultSummaryChart?.views,
            color: primary_color,
          },
          {
            name: this.translate.instant(SeriesTitlesToShow.UNIQUE_VIEWS),
            data: this.resultSummaryChart?.uniqueViews,
          },
          {
            name: SeriesTitlesToShow.COMPLETED,
            data: this.resultSummaryChart?.peopleFinishAds,
          },
        ],
        labels: this.resultSummaryChart?.dates,
      });
    }
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0);
  }
  updateOptionsConsumer(result: SummaryStatics | null) {
    if(this.chartApex != undefined){
      this.chartApex.updateOptions({
        chart: {
          toolbar: {
            show: false,
          },
          events: {
            click: (event: any, chartData: any, config: any) => {
              //NONE_TYPE;
              event.preventDefault();
            },
          },
        },
        series: [
          {
            name: this.translate.instant(SeriesTitlesToShow.CONSUMERS),
            data: result?.views,
            color: primary_color,
          },
        ],
        labels: result?.dates.map((e) => e + "H").sort(),
        xaxis: {
          title: {
            text: this.translate.instant("Charts.hours"),
          },
        },
      });
    }
  }
  updateOptions() {
    this.chartApex.updateOptions({
      chart: {
        toolbar: {
          show: false,
        },
        events: {
          click: (event: any, chartData: any, config: any) => {
            //NONE_TYPE;
            event.preventDefault();
          },
        },
      },
      series: [
        {
          name: this.translate.instant(SeriesTitlesToShow.VIEWS),
          data: this.resultSummaryStaticsInDay?.views,
          color: primary_color,
        },
        {
          name: this.translate.instant(SeriesTitlesToShow.UNIQUE_VIEWS),
          data: this.resultSummaryStaticsInDay?.uniqueViews,
        },
        {
          name: this.translate.instant(SeriesTitlesToShow.COMPLETED),
          data: this.resultSummaryStaticsInDay?.peopleFinishAds,
        },
      ],
      labels: this.resultSummaryStaticsInDay?.dates.map((e) => e + "H").sort(),
      xaxis: {
        title: {
          text: this.translate.instant("Charts.hours"),
        },
      },
      /*title: {
        text: this.day,
      },*/
    });
  }
  invokeChartByDay(event: any, chartData: any, config: any, result: any) {
    this.loading = true;
    if (this.role == RoleEnum.ROLE_ADVERTISER || (this.role == RoleEnum.ROLE_ADMIN && this.campaignHashedId != null)) {
      this.store.dispatch(
        invokeSummaryChartInDayForAd({
          campaignHashedId: this.campaignHashedId,
          isFinished : false,
          day: String(result?.dates[config.dataPointIndex]),
          locationPartner : this.selectedLp
        })
      );
    }
    if (this.role == RoleEnum.ROLE_ADMIN && this.campaignHashedId == null && this.companyName == null) {
      if(this.isMain){
        this.store.dispatch(invokeSummaryChartInDay({day: String(result?.dates[config.dataPointIndex])}))
      }else{
        this.store.dispatch(
          invokeConsumersCountInDay({
            day: String(result?.dates[config.dataPointIndex]),
          })
        );
      }
    }
    if (this.role == RoleEnum.ROLE_LOCATION || (this.role == RoleEnum.ROLE_ADMIN && this.companyName != null)) {
      this.store.dispatch(
        invokeSummaryChartInDayForLP({
          companyName: this.companyName,
          day: String(result?.dates[config.dataPointIndex]),
        })
      );
    }
  }
  configChart(result: SummaryStatics | null) {
    let customLegendItems : string | null = null
    if(this.xaxisTitle) customLegendItems = this.translate.instant(this.xaxisTitle)
    let series: (
      | {
          name: SeriesTitlesToShow;
          data: number[] | undefined;
          color: string;
        }
      | {
          name: SeriesTitlesToShow;
          data: number[] | undefined;
        }
    )[];
    if (this.isConsumerChart)
      series = [
        {
          name: this.translate.instant(SeriesTitlesToShow.CONSUMERS),
          data: result?.views,
          color: primary_color,
        },
      ];
    else
      series = [
        {
          name: this.translate.instant(SeriesTitlesToShow.VIEWS),
          data: result?.views,
          color: primary_color,
        },
        {
          name: this.translate.instant(SeriesTitlesToShow.UNIQUE_VIEWS),
          data: result?.uniqueViews,
        },
        {
          name: this.translate.instant(SeriesTitlesToShow.COMPLETED),
          data: result?.peopleFinishAds,
        },
      ];
    if (this.click == 0) {
      this.chartOptions = {
        ...this.chartOptions,
        chart: {
          toolbar: {
            show: false,
          },
          type: "area",
          height: 350,
          events: {
            click: (event: any, chartData: any, config: any) => {
              this.invokeChartByDay(event, chartData, config, result);
              this.click++;
              this.length = 0;
              this.day = String(result?.dates[config.dataPointIndex]);
              this.cdr.detectChanges();
            },
          },
        },
        series: series,
        labels: result?.dates,
        xaxis: {
          title: {
            text: customLegendItems,
          },
        },
        yaxis: {
          title: {
            text: this.yaxisTitle,
          },
        },
      };
    }
    this.chartOptions = {
      ...this.chartOptions,
    };
  }
}

export type ChartOptions = {
  series?: ApexAxisChartSeries;
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  labels?: string[];
  stroke?: ApexStroke;
  title?: ApexTitleSubtitle;
};
