import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  mergeMap,
  of,
  Subject,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import {
  invokeApiDestroyedLocation_overview,
  invokeApiDistroyed,
  invokeComponentDistroyed,
  setAPIStatus,
} from "../apiState.interface";
import { LocationPartnersService } from "src/app/shared/services/location-partners.service";
import { AppStateInterface } from "../appState.interface";
import { select, Store } from "@ngrx/store";
import {
  filterLocationPartnerResultSelector,
  locationPartnerFilterSelector,
  locationPartnersToCreateCampaignFilterSelector,
} from "./location-partner.selectors";
import {
  calculateTotalLPCostByCampanyNames,
  createLocationPartnerSuccess,
  deleteLocationPartnerSuccess,
  invokeCalculateTotalLPCostByCampanyNames,
  invokeCreateLocationPartner,
  invokeDeleteLocationPartner,
  invokePricingByCompanyName,
  setPricingByCompanyName,
  invokeLocationPartnersByZone,
  resultLocationPartnerByRegion,
  invokeGrantPremiumAccessForLP,
  resultGrantPremiumAccessForLP,
  invokeUpdatePaymentStatusLP,
  resultSuccess,
  invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds,
  resultCalculateViewsOrUniqueViewsBasedOnNotPaidAds,
  invokeLocationPartnerAdvertisingCampignFilter,
  resultLocationPartnerAdvertisingCampignFilter,
} from "./location-partner.action";
import { TotalCostWithDiscount } from "src/app/shared/models/calcul/totalCost";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import { LocationPartnersResultInterface } from "src/app/shared/models/location-partners/locationPartnersResult.interface";
import { ResultMainStaticsForLP } from "src/app/shared/models/location-partners/resultMainStaticsLP";
import { PricingInterface } from "src/app/shared/models/location-partners/pricing";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import { FilterLastWeekForLPReview } from "src/app/shared/models/location-partners/filterLastWeekForLPReview";
import { LocationPartnersImpressionsResult } from "src/app/shared/models/location-partners/locationPartnersImpressions";
import {
  getLocationPartnersSuccess,
  invokeGetLocationPartnerByCompanyName,
  invokeLPInfo,
  invokeLocationPartnerByAdTitle,
  invokeLocationPartnerImpressions,
  invokeLocationPartners,
  invokeLocationPartnersByCompanyNames,
  invokeLocationPartnersCompanyNames,
  invokeLocationsToCreateCampaign,
  locationPartnerByAdTitle,
  locationPartnersToCreateCampaignSuccess,
  resultLPInfo,
  setLocationPartnerImpressions,
  setLocationPartnersByCompanyNames,
  setLocationPartnersCompanyNames,
  invokeFilterLocationsForPayment,
} from "./actions/get-locations.actions";
import {
  invokeCalculateAndUpdateViewAverage,
  invokeUpdateAccount,
  invokeUpdateLocationPartner,
  invokeUpdateViewsAverageManually,
  resultCalculateAndUpdateViewsAverage,
  resultUpdateViewsAverage,
  updateLocationPartnerAccountSuccess,
  updateLocationPartnerSuccess,
} from "./actions/update.actions";
import {
  invokeLocationPartnerReview,
  invokeMainStaticsForLocationPartner,
  resultLocationPartnerReview,
  resultMainStaticsForLocationPartner,
} from "./actions/get-impressions.actions";
import { AdvertisingCampaignsPaymentLP } from "src/app/shared/models/advertising-campaigns/advertising-campaigns-payment-LP.interface";

@Injectable()
export class LocationPartnerEffect {
  private destroy$ = new Subject<void>();
  constructor(
    private actions$: Actions,
    private locationPartnerService: LocationPartnersService,
    private store: Store<AppStateInterface>
  ) {}

  invokeLocationPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartners),
      withLatestFrom(this.store.pipe(select(locationPartnerFilterSelector))),
      mergeMap(([, locationPartnerFilter]) => {
        return this.locationPartnerService
          .getAllLocationPartnerByFilter(locationPartnerFilter)
          .pipe(
            map((data: LocationPartnersResultInterface) =>
              getLocationPartnersSuccess({ locationPartnersResult: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeLocationPartnersForPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeFilterLocationsForPayment),
      mergeMap((state) => {
        return this.locationPartnerService
          .getAllLocationPartnerByFilterForPayment(state.locationPartnerFilter)
          .pipe(
            map((data: LocationPartnersResultInterface | null) =>
              getLocationPartnersSuccess({ locationPartnersResult: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );

  invokeLocationPartnersByCompanyNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartnersByCompanyNames),
      mergeMap((state) => {
        return this.locationPartnerService
          .getAllLocationPartnersByCompanyNames(state.companyNames)
          .pipe(
            map((data: LocationPartnerInterface[] | null) =>
              setLocationPartnersByCompanyNames({ locationPartners: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
            //takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeGetLocationPartnerByCompanyName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGetLocationPartnerByCompanyName),
      mergeMap((state) => {
        return this.locationPartnerService
          .getLocationPartnerByCompanyName(state.companyName)
          .pipe(
            map((data: LPInfo | null) => resultLPInfo({ lpInfo: data })),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeLPInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLPInfo),
      mergeMap((state) => {
        return this.locationPartnerService.getLPInfo().pipe(
          map((data: LPInfo | null) => resultLPInfo({ lpInfo: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeCreateLocationPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCreateLocationPartner),
      mergeMap((state) => {
        return this.locationPartnerService
          .createLocationPartner(state.createLocationPartner)
          .pipe(
            map((data: SuccessDto) =>
              createLocationPartnerSuccess({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeUpdateLocationPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateLocationPartner),
      mergeMap((state) => {
        return this.locationPartnerService
          .updateLocationPartner(state.locationPartnerDto, state.email)
          .pipe(
            map((data: SuccessDto) =>
              updateLocationPartnerSuccess({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeGetAllCampanyNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartnersCompanyNames),
      mergeMap((state) => {
        return this.locationPartnerService
          .getAllLocationPartnersCompanyNames()
          .pipe(
            map((data: string[] | null) =>
              setLocationPartnersCompanyNames({ companyNames: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeDeleteLocationPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteLocationPartner),
      mergeMap((state) => {
        return this.locationPartnerService.deleteAccount(state.email).pipe(
          map((data: SuccessDto) =>
            deleteLocationPartnerSuccess({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeLocationsToCreateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationsToCreateCampaign),
      withLatestFrom(
        this.store.pipe(select(locationPartnersToCreateCampaignFilterSelector))
      ),
      mergeMap(([state, filter]) => {
        return this.locationPartnerService
          .filterLocationPartnersToCreateCampaign(filter)
          .pipe(
            map((data: LocationPartnerInterface[] | null) =>
              locationPartnersToCreateCampaignSuccess({
                locationPartnersToCreateCampaign: data,
              })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeCalculateTotalLPCostByCampanyNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCalculateTotalLPCostByCampanyNames),
      mergeMap((state) => {
        return this.locationPartnerService
          .calculateTotalLPCostByCampanyNames(state.calculateCost)
          .pipe(
            map((data: TotalCostWithDiscount) =>
              calculateTotalLPCostByCampanyNames({
                totalCostWithDiscount: data,
              })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeLocationPartnerByAdTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartnerByAdTitle),
      mergeMap((state) => {
        return this.locationPartnerService
          .getlLocationPartnerByAdTitle(state.adTitle)
          .pipe(
            map((data: LocationPartnerInterface[] | null) =>
              locationPartnerByAdTitle({ allLocationPartners: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeMainStaticsForLocationPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeMainStaticsForLocationPartner),
      mergeMap((state) => {
        return this.locationPartnerService
          .getMainStaticsForLP(state.authentification)
          .pipe(
            map((data: ResultMainStaticsForLP) =>
              resultMainStaticsForLocationPartner({
                resultMainStaticsForLP: data,
              })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokePricingByCompanyName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokePricingByCompanyName),
      mergeMap((state) => {
        return this.locationPartnerService
          .getPricingByCompanyName(state.companyName)
          .pipe(
            map((data: PricingInterface | null) =>
              setPricingByCompanyName({ pricing: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeLocationPartnerReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartnerReview),
      mergeMap((state) => {
        return this.locationPartnerService
          .filterLocationPartnerReview(state.pager, state.refreshCache)
          .pipe(
            map((data: FilterLastWeekForLPReview | null) =>
              resultLocationPartnerReview({ lastWeekReviewLP: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(
              this.actions$.pipe(ofType(invokeApiDestroyedLocation_overview))
            )
          );
      })
    )
  );

  invokeLocationPartnersByZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartnersByZone),
      mergeMap((state) => {
        return this.locationPartnerService
          .getLocationPartnersByRegion(state.regionNames)
          .pipe(
            map((data: string[][] | null) =>
              resultLocationPartnerByRegion({ lps: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeUpdateLPAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateAccount),
      mergeMap((state) => {
        return this.locationPartnerService
          .updateLocationAccount(state.Authorization, state.locationPartnerDto)
          .pipe(
            map((data: SuccessDto | null) =>
              updateLocationPartnerAccountSuccess({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  //impressions table
  invokeLocationPartnersImpressions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartnerImpressions),
      withLatestFrom(
        this.store.pipe(select(filterLocationPartnerResultSelector))
      ),
      mergeMap(([, filter]) => {
        return this.locationPartnerService
          .getLocationPartnersImpressions(filter)
          .pipe(
            map((data: LocationPartnersImpressionsResult) =>
              setLocationPartnerImpressions({ result: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            //takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );

  invokeCalculateAndUpdateViewsAverage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCalculateAndUpdateViewAverage),
      mergeMap((state) => {
        return this.locationPartnerService
          .calculateAndUpdateViewsAverage(
            state.calculateAverageViews,
            state.sendMail
          )
          .pipe(
            map((data: SuccessDto) =>
              resultCalculateAndUpdateViewsAverage({
                successMessage: data.message,
              })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeGrantPremiumAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGrantPremiumAccessForLP),
      mergeMap((state) => {
        return this.locationPartnerService
          .grantPremiumAccessForLP(state.grantPremiumAccess)
          .pipe(
            map((data: SuccessDto | null) =>
              resultGrantPremiumAccessForLP({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeUpdatePaymentStatusLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdatePaymentStatusLP),
      mergeMap((state) => {
        return this.locationPartnerService
          .updatePaymentStatusLP(state.updatePaymentStatusLP)
          .pipe(
            map((data: SuccessDto | null) =>
              resultSuccess({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds),
      mergeMap((state) => {
        return this.locationPartnerService
          .calculateViewsOrUniqueViewsBasedOnNotPaidAds(state.pricingMethode)
          .pipe(
            map((data: number) =>
              resultCalculateViewsOrUniqueViewsBasedOnNotPaidAds({
                views: data,
              })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeLocationPartnerAdvertisingCampignFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeLocationPartnerAdvertisingCampignFilter),
      mergeMap((state) => {
        return this.locationPartnerService
          .locationPartnerAdvertisingCampignFilter(
            state.locationPartnerCampaignsFilter,
            state.isWithViews,
            state.refreshCache
          )
          .pipe(
            map((data: AdvertisingCampaignsPaymentLP | null) =>
              resultLocationPartnerAdvertisingCampignFilter({
                advertisingCampaignsPaymentLP: data,
              })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );

  invokeUpdateViewsAverageManually$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateViewsAverageManually),
      mergeMap((state) => {
        return this.locationPartnerService
          .updateViewsAverage(state.updateViewsAverage)
          .pipe(
            map((data: SuccessDto) =>
              resultUpdateViewsAverage({
                successMessage: data,
              })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
}
