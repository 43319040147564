<div class="card">
  <div class="card-header pb-0">
    <div class="m-20 d-flex justify-content-between">
      <h4 class="m-l-0">
        {{ "dashboard.titleBestResult" | translate }}
        <span style="font-size: smaller" class="color-darkGray">
          | {{ companyName }}</span
        >
      </h4>
      <div
        *ngIf="
          resultBestAdvertisingCampaign &&
          resultBestAdvertisingCampaign.cachedAt
        "
      >
        <button
          class="btn badge badge-light text-dark"
          type="button"
          style="margin-right: 4rem"
          (click)="onClickRefreshCache()"
        >
          <i class="icofont icofont-refresh"></i>
          <span>
            {{ "cachedAt" | translate }}
            {{
              resultBestAdvertisingCampaign.cachedAt | localizedDate : "short"
            }}
          </span>
        </button>
      </div>
    </div>
    <img
      style="position: absolute; top: 0; right: 0"
      src="assets/identity/badge.svg"
      alt="badge"
    />
  </div>
  <div class="card-body p-t-0">
    <div *ngIf="loading$ | async">
      <div class="loader-box">
        <div class="loader-34"></div>
      </div>
      <h6 class="col align-self-center f-w-600" style="text-align: center">
        {{ "fetching" | translate }}
      </h6>
    </div>
    <div class="row" *ngIf="!(loading$ | async)">
      <div class="col-3" style="height: 250px">
        <video
          #myVideo
          controls
          loop
          style="padding: 14px; border-radius: 20px; width: 80%; height: 100%"
          [src]="videoLink"
        ></video>
      </div>
      <div class="col-9" *ngIf="resultBestAdvertisingCampaign">
        <div class="col-12">
          <h6 class="f-w-600 m-b-20">
            {{ resultBestAdvertisingCampaign.title }}
          </h6>
        </div>
        <div class="row">
          <div class="col-md-5 col-xl-4 col-sm-6">
            <app-card-inseights
              [title]="'dashboard.vues' | translate"
              [icon]="impression.icon"
              [value]="impression.value"
              [class]="'eye'"
              [isLoading]="isLoading"
            >
            </app-card-inseights>
          </div>
          <div class="col-md-5 col-xl-4 col-sm-6">
            <app-card-inseights
              [title]="'dashboard.completed' | translate"
              [icon]="completedVideos.icon"
              [value]="completedVideos.value"
              [class]="'eye'"
              [isLoading]="isLoading"
            >
            </app-card-inseights>
          </div>
          <div class="col-md-5 col-xl-4 col-sm-6">
            <app-card-inseights
              [title]="'dashboard.click' | translate"
              [icon]="clicks.icon"
              [value]="clicks.value"
              [class]="'eye'"
              [isLoading]="isLoading"
            >
            </app-card-inseights>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <label class="form-label label">
                  <b> {{ "dashboard.company" | translate }} </b>
                </label>
              </div>
              <div class="col-6">
                <p>{{ companyName }}</p>
              </div>

              <div class="col-6">
                <label class="form-label label">
                  <b> {{ "campaignDetails.objective" | translate }} </b>
                </label>
              </div>
              <div class="col-6">
                <p>
                  {{
                    objectiveTypeEnumMapping[
                      resultBestAdvertisingCampaign.objectiveName
                    ] | translate
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <label class="form-label label">
                  <b> {{ "campaignDetails.startDate" | translate }} </b>
                </label>
              </div>
              <div class="col-6">
                <p>
                  {{
                    resultBestAdvertisingCampaign.startingDate | localizedDate
                  }}
                </p>
              </div>

              <div class="col-6">
                <label class="form-label label">
                  <b> {{ "campaignDetails.endDate" | translate }} </b>
                </label>
              </div>
              <div class="col-6">
                <p>
                  {{ resultBestAdvertisingCampaign.endingDate | localizedDate }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-xl-6"></div>
          <div class="col-12 col-md-6 col-xl-6">
            <button
              class="btn btn-primary px-3"
              style="margin-left: auto; display: block"
              [routerLink]="[
                '/',
                'dashboard',
                'advertiser',
                'insights',
                'statistics',
                resultBestAdvertisingCampaign.advertisingCampaignHashedId
              ]"
            >
              {{ "dashboard.showDetails" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
