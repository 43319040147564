<div class="container-fluid" id="ad-statistics">
  <div class="row align-items-center">
    <div class="col-8 col-sm-12 col-md-12 col-lg-7 col-xl-8 col-xxl-8">
      <div class="card m-b-0">
        <div class="card-header">
          <div class="media-space-between">
            <h4>{{ "inseights.details.title" | translate }}</h4>
            <form class="d-inline-flex">
              <button
                *ngIf="role == roleEnum.ROLE_ADMIN"
                class="btn btn-primary m-r-10"
                style="padding-left: 0.8rem; padding-right: 0.8rem"
                (click)="onClickShowLocations()"
              >
                <i class="icofont icofont-location-pin"></i>
              </button>
              <div
                class="dartboard m-r-10"
                *ngIf="objectiveName == objectives.GENERATION_DE_PROSPECTS"
              >
                <button
                  class="btn btn-primary"
                  style="padding-left: 1rem; padding-right: 1rem"
                  (mouseenter)="showButtonDesc = true"
                  (mouseleave)="showButtonDesc = false"
                  (click)="sendRequestDownloadLeadToAdmin()"
                >
                  <div class="media-space-between">
                    <div
                      class="loader-box"
                      style="height: 10px"
                      *ngIf="loadingSendingEmail$ | async; else default"
                    >
                      <div class="loader-button-34"></div>
                    </div>
                    <ng-template #default>
                      <i class="icofont icofont-download-alt"></i>
                    </ng-template>
                    <span *ngIf="showButtonDesc" class="m-l-5">{{
                      "inseights.details.card1.download" | translate
                    }}</span>
                  </div>
                </button>
              </div>
              <button
                *ngFor="let item of adCompaign"
                class="btn btn-primary"
                style="padding-left: 0.8rem; padding-right: 0.8rem"
                (click)="onClickDownloadSynthesisReport()"
                [disabled]="!item.synthesisReport"
              >
                <i class="icofont icofont-download-alt"></i>
                {{ "inseights.details.card1.synthesisRapport" | translate }}
              </button>
            </form>
          </div>
        </div>
        <div class="card-body align-items-center" style="min-height: 250px">
          <div class="text-center" *ngIf="loading$ | async; else details">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
          </div>
          <ng-template #details>
            <ng-container *ngFor="let item of adCompaign">
              <div class="pl-12 pr-20 grid-column">
                <div class="grid-column">
                  <label class="col-form-label label f-w-600">
                    {{ "inseights.details.card1.title" | translate }}:
                  </label>
                  <p class="col-form-label label">{{ item.title }}</p>
                  <label class="col-form-label label f-w-600">
                    {{ "inseights.details.card1.advertiser" | translate }}:
                  </label>
                  <p class="col-form-label label">{{ fullName }}</p>
                  <label class="col-form-label label f-w-600">
                    {{ "inseights.details.card1.objectiveName" | translate }}:
                  </label>
                  <p class="col-form-label label">
                    {{
                      "enumMapping.objectives." + item.objectiveName | translate
                    }}
                  </p>
                  <label class="col-form-label label f-w-600">
                    {{ "inseights.details.card1.startDate" | translate }}:
                  </label>
                  <p
                    class="col-form-label label"
                    *ngIf="item.startingDate; else none"
                  >
                    {{ item.startingDate | localizedDate }}
                  </p>
                  <ng-template #none>
                    <p class="col-form-label label">
                      {{ "undefined" | translate }}
                    </p>
                  </ng-template>
                </div>

                <div class="grid-column">
                  <label class="col-form-label label f-w-600">
                    {{ "inseights.details.card1.publishDay" | translate }}:
                  </label>
                  <p class="col-form-label label">
                    {{ item.publishDate | localizedDate }}
                  </p>
                  <label class="col-form-label label f-w-600">
                    <b
                      >{{
                        "inseights.details.card1.companyName" | translate
                      }}:</b
                    >
                  </label>
                  <p class="col-form-label label">
                    {{ AdvertiserCompanyName }}
                  </p>
                  <label class="col-form-label label f-w-600">
                    {{ "inseights.details.card1.status" | translate }}:
                  </label>
                  <p class="col-form-label label">
                    <span
                      [ngClass]="
                        'badge ' +
                        badgeService.getBadgeForAdvertisingCampaingStatus(
                          item.status
                        )
                      "
                      >{{
                        "enumMapping.campaigns.status." + item.status
                          | translate
                      }}
                    </span>
                  </p>
                  <label class="col-form-label label f-w-600">
                    {{
                      "inseights.details.card1.endDate" | translate
                    }}:&MediumSpace;
                  </label>
                  <p
                    class="col-form-label label"
                    *ngIf="item.startingDate; else none"
                  >
                    {{ item.endingDate | localizedDate }}
                  </p>
                </div>
              </div>
              <div class="m-t-15">
                <label class="col-form-label label f-w-600 m-r-10">
                  {{ "inseights.details.card1.locations" | translate }}
                </label>
                <span
                  style="margin-bottom: 6px"
                  *ngFor="let locations of item.locationPartners; let i = index"
                  [ngClass]="'badge badge-light-success'"
                >
                  {{ locations }}
                </span>
              </div>
            </ng-container></ng-template
          >
        </div>
      </div>
    </div>
    <div
      class="col-4 col-sm-12 col-md-12 col-lg-5 col-xl-4 col-xxl-4 justify-content-center"
    >
      <h3>{{ "profile.overview" | translate }}</h3>
      <hr />
      <div class="btn-showcase mt-2">
        <app-ad-video-preview-statistics
          class="phone-preview"
          style="height: 70%; position: sticky"
          [changeMuting]="mute"
          [isUrl]="true"
          [url]="videoUrl"
        ></app-ad-video-preview-statistics>
        <div class="mt-2"></div>
      </div>
    </div>
    <div class="d-flex justify-content-beetween">
      <h4 class="m-15">
        {{ "inseights.details.engagementOverview.title" | translate }}
      </h4>
      <div class="d-flex mb-3">
        <button
          *ngIf="cachedAt"
          class="btn badge badge-light text-dark"
          type="button"
          (click)="fetchGlobalStatistics(true)"
        >
          <i class="icofont icofont-refresh"></i>
          <span>
            {{ "cachedAt" | translate }}
            {{ cachedAt | localizedDate : "short" }}
          </span>
        </button>
      </div>
    </div>
    <div class="row">
      <!-- If the visionnage card exists, the class will be : 'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4 p-r-0'-->
      <div
        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4 p-r-0"
      >
        <app-card-inseights
          [title]="impression.title"
          [icon]="impression.icon"
          [value]="impression.value"
          [unit]="impression.unit"
          [isFinished]="isFinished"
          [isLoading]="statisticsLoading"
        >
        </app-card-inseights>
      </div>
      <!--<div
        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4 p-r-0"
        *ngIf="
          (adCompaignByTitle$ | async)?.objectiveName !=
          objectives.GENERATION_DE_PROSPECTS
        "
      >
        <app-card-inseights
          [title]="visionnage.title"
          [icon]="visionnage.icon"
          [value]="visionnage.value"
          [unit]="visionnage.unit"
          [isFinished]="isFinished"
          [isLoading]="statisticsLoading"
        >
        </app-card-inseights>
      </div>-->
      <div
        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4 p-r-0"
        *ngIf="
          (adCompaignByHashedId$ | async)?.objectiveName !=
          objectives.WEB_SITE_TRAFFIC
        "
      >
        <app-card-inseights
          [title]="uniqueViews.title"
          [icon]="uniqueViews.icon"
          [value]="uniqueViews.value"
          [unit]="uniqueViews.unit"
          [isFinished]="isFinished"
          [class]="'eye'"
          [isLoading]="statisticsLoading"
        >
        </app-card-inseights>
      </div>
      <div
        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4 p-r-0"
      >
        <app-card-inseights
          [title]="completed.title"
          [icon]="completed.icon"
          [value]="completed.value"
          [unit]="completed.unit"
          [isFinished]="isFinished"
          [isLoading]="statisticsLoading"
        >
        </app-card-inseights>
      </div>
      <!--<div
        *ngIf="
          (adCompaignByTitle$ | async)?.objectiveName ==
          objectives.GENERATION_DE_PROSPECTS
        "
        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4 p-r-0"
      >
        <app-card-inseights
          [title]="prospect.title"
          [icon]="completed.icon"
          [value]="prospect.value"
          [isFinished]="isFinished"
          [isLoading]="statisticsLoading"
        >
        </app-card-inseights>
      </div>-->
      <div
        *ngIf="
          (adCompaignByHashedId$ | async)?.objectiveName ==
            objectives.WEB_SITE_TRAFFIC ||
          (adCompaignByHashedId$ | async)?.objectiveName ==
            objectives.APP_PROMOTION_AND_INSTALL
        "
        class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 box-col-4 p-r-0"
      >
        <app-card-inseights
          [title]="clickThrowRate.title"
          [icon]="clickThrowRate.icon"
          [value]="clickThrowRate.value"
          [isPercentage]="true"
          [isFinished]="isFinished"
          [isLoading]="statisticsLoading"
        >
        </app-card-inseights>
      </div>
    </div>
    <!--if generation de prospect-->
    <!--<div class="row p-l-25">
      <div
        class="card p-0"
        *ngIf="
          (adCompaignByTitle$ | async)?.objectiveName ==
          objectives.GENERATION_DE_PROSPECTS
        "
      >
        <div class="card-header p-b-0">
          <h4>{{ "inseights.details.formInsights.title" | translate }}</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive data-table" style="font-size: 12px">
            <table class="table table-striped table-border-vertical">
              <thead>
                <tr>
                  <ng-container *ngFor="let column of formInsightcolumns">
                    <th>
                      {{ column.label | translate }}
                    </th>
                  </ng-container>
                  <th>{{ "dashboard.options" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-template #exist>
                  <tr>
                    <td></td>
                    <td class="img-content-box"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>-->
    <!--audiences-->
    <app-audience [inputAudience$]="inputAudience"></app-audience>
    <!--donnée démographique-->
    <div class="row p-l-25">
      <div class="card">
        <div class="card-body">
          <div class="media-space-between">
            <h4 class="m-t-15">
              {{ "inseights.details.geographicData" | translate }}
            </h4>
          </div>
          <div class="card-locations mt-4">
            <app-map [listLocations]="locations"></app-map>
          </div>
        </div>
      </div>
    </div>
    <!--Données techniques-->
    <app-technical-data
      [search]="search"
      [isPremium]="true"
    ></app-technical-data>
  </div>
</div>
