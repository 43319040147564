import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, takeUntil, withLatestFrom } from "rxjs";
import { Store, select } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";
import {
  invokeMainDashboard,
  setMainDashboard,
  invokeProfileDetails,
  setProfileDetails,
  invokeRegister,
  registerSuccess,
  getAllAdvertiserByFilter,
  invokeApproveAdvertiser,
  approveAdvertiserByEmail,
  invokeConfirmEmail,
  confirmEmailForRegistrationSuccess,
  invokeAdvertiserByAdHashedId,
  advertiserByAdHashedId,
  invokeDeleteAdvertiser,
  resultDeleteAdvertiser,
  invokeUpdateAdvertiser,
  resultUpdateAdvertiser,
  invokeUpdateSubRoleForAdvertiser,
  resultUpdateSubRoleForAdvertiser,
  invokeIdIberis,
  resultIdIberis,
  confirmEmailForRegistrationFailed,
  regenerateConfirmRegestration,
  regenerateConfirmRegestrationSuccess,
  regenerateConfirmRegestrationFailed,
  invokeAllActivatedAdvertisers,
  resultAllActivatedAdvertisers,
  invokeAllAdvertiserByFilter,
} from "./advertiser.actions";
import { AdvertiserService } from "src/app/shared/services/advertiser.service";
import { invokeComponentDistroyed, setAPIStatus } from "../apiState.interface";
import { AdvertiserDashboard } from "src/app/shared/models/advertiser/advertiser-dashboard.interface";
import { AdvertiserDetailsInterface } from "src/app/shared/models/advertiser/profile.interface";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { ResultFilterAdvertiser } from "src/app/shared/models/advertiser/result-filter-advertiser.interface";
import { filterAdvertiserSelector } from "./advertiser.selectors";

@Injectable()
export class AdvertiserEffect {
  constructor(
    private actions$: Actions,
    private advertiserService: AdvertiserService,
    private store: Store<AppStateInterface>
  ) {}

  invokeRegistre$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeRegister),
      mergeMap((state) => {
        return this.advertiserService.register(state.advertiser).pipe(
          map((data: SuccessDto | null) =>
            registerSuccess({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeConfirmEmailForRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeConfirmEmail),
      mergeMap((state) => {
        return this.advertiserService
          .confirmEmailForRegistration(state.token)
          .pipe(
            map((data: any | null) =>
              confirmEmailForRegistrationSuccess({ successMessage: data })
            ),
            catchError((error) =>
              of(
                confirmEmailForRegistrationFailed({
                  result: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  regenerateConfirmRegestration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(regenerateConfirmRegestration),
      mergeMap((state) => {
        return this.advertiserService
          .regenerateConfirmRegestration(state.token)
          .pipe(
            map((data: any | null) =>
              regenerateConfirmRegestrationSuccess({ successMessage: data })
            ),
            catchError((error) =>
              of(
                regenerateConfirmRegestrationFailed({
                  result: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeMainDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeMainDashboard),
      mergeMap((state) => {
        return this.advertiserService.getMainDashboard(state.isRefreshCache).pipe(
          map((data: AdvertiserDashboard | null) =>
            setMainDashboard({ dashboardAdvertiser: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          ),
        );
      })
    )
  );

  invokeProfileDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeProfileDetails),
      mergeMap((state) => {
        return this.advertiserService.getProfileDetails().pipe(
          map((data: AdvertiserDetailsInterface | null) =>
            setProfileDetails({ profileDetails: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeResultFilterAdvertiser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllAdvertiserByFilter),
      withLatestFrom(this.store.pipe(select(filterAdvertiserSelector))),
      mergeMap(([, filter]) => {
        return this.advertiserService.getAdvertiserByFilter(filter).pipe(
          map((data: ResultFilterAdvertiser) =>
            getAllAdvertiserByFilter({ resultFilterAdvertiser: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeApproveAdvertiser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeApproveAdvertiser),
      mergeMap((state) => {
        return this.advertiserService.updateAdvertiserStatus(state.dto).pipe(
          map((data: SuccessDto | null) =>
            approveAdvertiserByEmail({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeAdvertiserByAdTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAdvertiserByAdHashedId),
      mergeMap((state) => {
        return this.advertiserService.advertiserByAdHashedId(state.campaignHashedId).pipe(
          map((data: AdvertiserDetailsInterface | null) =>
            advertiserByAdHashedId({ profileDetails: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeDeleteAdvertiser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeleteAdvertiser),
      mergeMap((state) => {
        return this.advertiserService.deleteAdvertiser(state.email).pipe(
          map((data: SuccessDto | null) =>
            resultDeleteAdvertiser({ successMessage: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
  invokeUpdateAdvertiser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateAdvertiser),
      mergeMap((state) => {
        return this.advertiserService
          .updateAdvertiser(state.email, state.profileDetails)
          .pipe(
            map((data: SuccessDto | null) =>
              resultUpdateAdvertiser({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );
  invokeUpdateSubRoleForAdvertiser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUpdateSubRoleForAdvertiser),
      mergeMap((state) => {
        return this.advertiserService
          .updatesRoleForAdvertiser(state.email, state.subRole)
          .pipe(
            map((data: SuccessDto | null) =>
              resultUpdateSubRoleForAdvertiser({ successMessage: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            )
          );
      })
    )
  );

  invokeIdIberis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeIdIberis),
      mergeMap((state) => {
        return this.advertiserService.getIdIberis().pipe(
          map((data: string) => resultIdIberis({ idIberis: data })),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );

  invokeActivatedAdvertisers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllActivatedAdvertisers),
      mergeMap((state) => {
        return this.advertiserService.getActivatedAdvertisers().pipe(
          map((data: AdvertiserDetailsInterface[] | null) =>
            resultAllActivatedAdvertisers({ publishers: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
