<div class="col-xl-12">
  <app-audience
    [inputAudience$]="inputAudience"
    [isConsummer]="true"
  ></app-audience>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-12 col-md-6 col-xl-6 col-sm-6 col-xxl-6">
      <div class="card border-style">
        <div class="card-header d-flex justify-content-between">
          <h5 class="d-flex align-items-baseline">
            {{ pieGenderChart.title | translate }}
            <p style="color: darkgrey; margin-left: 5px">
              |{{ "admin.consumers.total" | translate }}
            </p>
          </h5>
          <div class="d-flex mb-3">
            <button
              *ngIf="genderChartCachedAt"
              class="btn badge badge-light text-dark"
              type="button"
              (click)="onClickRefreshCacheGenderChart()"
            >
              <i class="icofont icofont-refresh"></i>
              <span>
                {{ "cachedAt" | translate }}
                {{ genderChartCachedAt | localizedDate : "short" }}
              </span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <app-donut
            [title]="pieGenderChart.title"
            [resultConsumerGender]="resultConsumerGender"
            [chartName]="pieGenderChart.genderType"
          ></app-donut>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-6 col-sm-6 col-xxl-6">
      <div class="card border-style">
        <div class="card-header">
          <h5 class="d-flex align-items-baseline">
            {{ pieDemographicChart.title | translate }}
            <p style="color: darkgrey; margin-left: 10px">
              |{{ "admin.consumers.total" | translate }}
            </p>
          </h5>
          <div class="d-flex mb-3">
            <button
              *ngIf="ageRangeChartCachedAt"
              class="btn badge badge-light text-dark"
              type="button"
              (click)="onClickRefreshCacheAgeRangeChart()"
            >
              <i class="icofont icofont-refresh"></i>
              <span>
                {{ "cachedAt" | translate }}
                {{ ageRangeChartCachedAt | localizedDate : "short" }}
              </span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <app-donut
            [title]="pieDemographicChart.title"
            [resultConsumerAgeRange]="resultConsumerAgeRange"
            [chartName]="pieGenderChart.ageRangeType"
          ></app-donut>
        </div>
      </div>
    </div>
  </div>

  <!--Données techniques-->
  <app-technical-data [search]="search" [isPremium]="true"></app-technical-data>
</div>
