<div
  [ngClass]="{
    flow: !premium == true
  }"
>
  <app-welcome-card
    [result]="resultRetainedConsumers"
    [loading$]="loadingReteinedConsumers$ | async"
    [firstName]="(profile$ | async)?.firstName"
    [lastName]="(profile$ | async)?.lastName"
  ></app-welcome-card>
  <div class="d-flex">
    <h4 class="m-15">{{ "location.statistics.title" | translate }}</h4>
    <div class="d-flex mx-3">
      <button
        *ngIf="cachedAt"
        class="btn badge badge-light text-dark"
        type="button"
        (click)="fetchCardsStatistics(true)"
      >
        <i class="icofont icofont-refresh"></i>
        <span>
          {{ "cachedAt" | translate }}
          {{ cachedAt | localizedDate : "short" }}
        </span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
      <app-card
        [title]="Retention_time.title"
        [icon]="Retention_time.icon"
        [value]="Retention_time.value"
        [class]="'eye'"
        [isLoading]="loadingRetentionTime$ | async"
      >
      </app-card>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
      <app-card
        [title]="consummers.title"
        [icon]="consummers.icon"
        [value]="consummers.value"
        [class]="'eye'"
        [isLoading]="loadingConsumers$ | async"
      >
      </app-card>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
      <app-card
        [title]="impression.title"
        [icon]="impression.icon"
        [value]="impression.value"
        [class]="'eye'"
        [isLoading]="loadingGlobalStatics$ | async"
      >
      </app-card>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
      <app-card
        [title]="uniqueViews.title"
        [icon]="uniqueViews.icon"
        [value]="uniqueViews.value"
        [class]="'eye'"
        [isLoading]="loadingGlobalStatics$ | async"
      >
      </app-card>
    </div>
    <app-audience
      [inputAudience$]="inputAudience"
      [adTitle]="this.companyName"
      [isPremium]="premium"
    ></app-audience>
    <div>
      <div class="card">
        <div class="card-header">
          <div class="media-space-between">
            <div class="d-flex">
              <h4 class="m-t-15">
                {{ "location.statistics.retentionRate" | translate }}
              </h4>
              <div class="d-flex mx-3">
                <button
                  *ngIf="retentionRateCachedAt"
                  class="btn badge badge-light text-dark"
                  type="button"
                  (click)="fetchRetentionRate(true)"
                >
                  <i class="icofont icofont-refresh"></i>
                  <span>
                    {{ "cachedAt" | translate }}
                    {{ retentionRateCachedAt | localizedDate : "short" }}
                  </span>
                </button>
              </div>
            </div>
            <form
              [formGroup]="form"
              class="media-space-between col-10 col-xl-4 col-md-5 col-sm-8 col-xxl-3"
            >
              <div class="input-group m-r-10">
                <div
                  class="input-group-text bg-primary"
                  data-target="#dt-date"
                  [owlDateTimeTrigger]="dtPicker3"
                >
                  <i
                    class="icofont icofont-ui-calendar"
                    style="color: white; font-size: 12px"
                  ></i>
                </div>
                <input
                  style="font-size: small; border-radius: 0 5px 5px 0"
                  width="max-content"
                  formControlName="date"
                  class="form-control"
                  type="text"
                  name="daterange"
                  (ngModelChange)="getChartInDate()"
                  [placeholder]="'placehorderPickDate' | translate"
                  placehorderPickDate
                  [selectMode]="'range'"
                  [owlDateTimeTrigger]="dtPicker3"
                  [owlDateTime]="dtPicker3"
                />
                <owl-date-time
                  [pickerType]="'calendar'"
                  #dtPicker3
                ></owl-date-time>
              </div>
              <app-export
                class="m-auto"
                [buttonName]="'export'"
                [data]="retentionChart"
                [title]="title"
              ></app-export>
            </form>
          </div>
        </div>
        <div class="card-body" id="location.statistics.retentionRate">
          <app-column-chart
            [xaxisTitle]="columnChart.xaxisTitle"
            [yaxisTitle]="columnChart.yaxisTitle"
            [xaxisTitle]="columnChart.xaxisTitle"
            [resultRetentionRate]="retentionRate"
          ></app-column-chart>
        </div>
      </div>
    </div>
    <app-technical-data
      [search]="search"
      [isPremium]="premium"
    ></app-technical-data>
  </div>
</div>
<div class="centered" *ngIf="!premium">
  <img src="/assets/images/premium.png" style="width: 10%" alt="" />
  <h4 class="mt-3 f-w-600">
    {{ "location.statistics.premiumDescription" | translate }}
  </h4>
  <div class="mt-3">
    <a target="_blank" [href]="bookMeeting" class="btn btn-primary m-r-10">
      {{ "location.statistics.goPremium" | translate }}
    </a>
  </div>
</div>
