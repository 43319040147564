import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, withLatestFrom } from "rxjs";
import { setAPIStatus } from "../apiState.interface";
import { UsersHistoryService } from "src/app/shared/services/usersHistory.service";
import { invokeUsersHistory, setUsersHistory } from "./userHistory.actions";
import { AppStateInterface } from "../appState.interface";
import { Store, select } from "@ngrx/store";
import { usersHistoryFilterSelector } from "./userHistory.selectors";
import { ResultUserHistoryInterface } from "src/app/shared/models/user-history/result-user-history.interface";

@Injectable()
export class UsersHistoryEffect {
  constructor(
    private actions$: Actions,
    private usersHistoryService: UsersHistoryService,
    private store: Store<AppStateInterface>
  ) {}

  invokeUsersHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUsersHistory),
      withLatestFrom(this.store.pipe(select(usersHistoryFilterSelector))),
      mergeMap(([refreshCache, filter]) => {
        return this.usersHistoryService.getUsersHistoryByFilter(filter, refreshCache.refreshCache).pipe(
          map((data: ResultUserHistoryInterface) =>
            setUsersHistory({ history: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  );
}
