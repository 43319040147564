import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "./shared/shared.module";
//import { OverlayModule } from "@angular/cdk/overlay";
import { LoadingBarModule } from "@ngx-loading-bar/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AuthModule } from "./auth/auth.module";
import { BaseComponent } from "./base.component";
import { select, Store, StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ApiReducer } from "./store/apiState.interface";
import { AuthReducer } from "./store/auth/auth.reducers";
import { AuthEffects } from "./store/auth/auth.effects";
import { environment } from "src/environments/environment";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { ToastrModule } from "ngx-toastr";
import { AdminModule } from "./components/admin/admin.module";
import { AdvertiserModule } from "./components/advertiser/advertiser.module";
import { GuestGuardService } from "./shared/services/guard/guest-guard.service";
import { AdminGuardService } from "./shared/services/guard/admin-guard.service";
import { AdvertiserGuardService } from "./shared/services/guard/advertiser-guard.service";
import { LocationPartnerGuardService } from "./shared/services/guard/location-partner-guard.service";
import { AdminReducer } from "./store/admin/admin.reducers";
import { AdminEffects } from "./store/admin/admin.effects";
import { StatisticsReducer } from "./store/statistics/statistics.reducers";
import { StatisticsEffects } from "./store/statistics/statistics.effects";
import { AdvertiserReducer } from "./store/advertiser/advertiser.reducers";
import { AdvertiserEffect } from "./store/advertiser/advertiser.effects";
import { AdvertisingCampaignReducer } from "./store/advertisingCampaign/advertisingCampaign.reducers";
import { AdvertisingCampaignEffect } from "./store/advertisingCampaign/advertisingCampaign.effects";
import { BugReportEffect } from "./store/bug-report/bug-report.effects";
import { BugReportReducer } from "./store/bug-report/bug-report.reducers";
import { UserEffect } from "./store/user/user.effects";
import { UserReducer } from "./store/user/user.reducers";
import { RouterReducer } from "./store/router/router.reducers";
import { RouterEffect } from "./store/router/router.effects";
import { LocationPartnerReducer } from "./store/locationPartner/location-partner.reducers";
import { LocationPartnerEffect } from "./store/locationPartner/location-partner.effects";
import {
  TranslateLoader,
  TranslateModule,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ArchwizardModule } from "angular-archwizard";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CategoryEffect } from "./store/category/category.effects";
import { CategoryReducer } from "./store/category/category.reducers";
import { SubCategoryReducer } from "./store/subCategory/subCategory.reducers";
import { SubCategoryEffect } from "./store/subCategory/subCategory.effects";
import { TagEffect } from "./store/tag/tag.effects";
import { TagReducer } from "./store/tag/tag.reducers";
import { ZoneReducer } from "./store/zone/zone.reducers";
import { ZoneEffect } from "./store/zone/zone.effects";
import { fileEffect } from "./store/file/file.effects";
import { FileReducer } from "./store/file/file.reducres";
import { ObjectiveEffect } from "./store/objective/objective.effects";
import { ObjectiveReducer } from "./store/objective/objective.reducers";
import { FixedChargeReducer } from "./store/fixed-charge/fixed-charge.reducers";

import { httpInterceptorProviders } from "./_helper/jwt-interceptor";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { ConsumersReducer } from "./store/consumers/consumers.reducers";
import { ConsumerEffect } from "./store/consumers/consumers.effects";
import { LocationPartnerModule } from "./components/location-partner/location-partner.module";
import { RegionReducer } from "./store/region/region.reducers";
import { RegionEffect } from "./store/region/region.effects";
import { SettingReducer } from "./store/settings/settings.reducer";
import { BillingReducer } from "./store/billing/billing.reducers";
import { BillingEffect } from "./store/billing/billing.effects";
import { CommandeReducer } from "./store/commande/commande.reducers";
import { CommandeEffect } from "./store/commande/commande.effects";
import { ClientIberisReducer } from "./store/clientIberis/clientIberis.reducers";
import { ClientIberisEffect } from "./store/clientIberis/clientIberis.effects";
import { FactureReducer } from "./store/facture/facture.reducers";
import { FactureEffect } from "./store/facture/facture.effects";
import { RoleReducer } from "./store/role/role.reducers";
import { RoleEffect } from "./store/role/role.effects";
import { SupplierEffect } from "./store/suppliers/suppliers.effects";
import { SupplierReducer } from "./store/suppliers/suppliers.reducers";
import { UserHistoryReducer } from "./store/userHistory/userHistory.reducers";
import { UsersHistoryEffect } from "./store/userHistory/userHistory.effects";
import { PaymentReceivedEffect } from "./store/payments-received/payments-received.effects";
import { PaymentReceivedReducer } from "./store/payments-received/payments-received.reducers";
import en from "@angular/common/locales/en";
import fr from "@angular/common/locales/fr";
import { DatePipe, registerLocaleData } from "@angular/common";
import { MailingReducer } from "./store/mailing/mailing.reducers";
import { MailingEffect } from "./store/mailing/mailing.effects";
import { PromotionCodeEffect } from "./store/promotion-code/promotion-code.effects";
import { FixedChargeEffect } from "./store/fixed-charge/fixed-charge.effects";
import { PromotionCodeReducer } from "./store/promotion-code/promotion-code.reducers";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { RecaptchaModule } from 'ng-recaptcha';

registerLocaleData(en);
registerLocaleData(fr);
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
//owlDateTimeLocaleProvider : OWL_DATE_TIME_LOCALE_PROVIDER
@NgModule({
  declarations: [AppComponent, BaseComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    SharedModule,
    AdminModule,
    AdvertiserModule,
    LocationPartnerModule,
    NgxSliderModule,
    //OverlayModule,
    LoadingBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    ArchwizardModule,
    ToastrModule.forRoot({
      closeButton: true,
      enableHtml: true,
      tapToDismiss: false,
      preventDuplicates: false,
      newestOnTop: true,
      progressBar: true,
    }),
    RecaptchaModule,
    StoreModule.forRoot({
      apiState: ApiReducer,
      authState: AuthReducer,
      adminState: AdminReducer,
      statisticsState: StatisticsReducer,
      advertiserState: AdvertiserReducer,
      advertisingCampaignState: AdvertisingCampaignReducer,
      bugReportState: BugReportReducer,
      userState: UserReducer,
      routerState: RouterReducer,
      locationPartnerState: LocationPartnerReducer,
      categoryState: CategoryReducer,
      subCategoryState: SubCategoryReducer,
      tagState: TagReducer,
      zoneState: ZoneReducer,
      regionState: RegionReducer,
      fileState: FileReducer,
      objectiveState: ObjectiveReducer,
      consumersState: ConsumersReducer,
      settingsState: SettingReducer,
      billingsState: BillingReducer,
      commandeState: CommandeReducer,
      clientIberisState: ClientIberisReducer,
      factureState: FactureReducer,
      roleState: RoleReducer,
      supplierState: SupplierReducer,
      usersHistoryState: UserHistoryReducer,
      paymentReceivedState: PaymentReceivedReducer,
      mailingState: MailingReducer,
      promotionCodeState: PromotionCodeReducer,
      fixedChargeState: FixedChargeReducer,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      AdminEffects,
      StatisticsEffects,
      AdvertiserEffect,
      AdvertisingCampaignEffect,
      BugReportEffect,
      UserEffect,
      RouterEffect,
      LocationPartnerEffect,
      CategoryEffect,
      SubCategoryEffect,
      TagEffect,
      ZoneEffect,
      RegionEffect,
      fileEffect,
      ObjectiveEffect,
      ConsumerEffect,
      BillingEffect,
      CommandeEffect,
      ClientIberisEffect,
      FactureEffect,
      RoleEffect,
      SupplierEffect,
      UsersHistoryEffect,
      PaymentReceivedEffect,
      MailingEffect,
      PromotionCodeEffect,
      FixedChargeEffect
    ]),
    StoreDevtoolsModule.instrument({
      name: "Relead",
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
    TranslateModule.forRoot({
      defaultLanguage: "fr",
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    httpInterceptorProviders,
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },*/
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    JwtHelperService,
    GuestGuardService,
    AdminGuardService,
    AdvertiserGuardService,
    LocationPartnerGuardService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
})
export class AppModule {}
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}