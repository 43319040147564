import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Observable, of, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  lpDetailsSelector,
  saveSelectedLPsSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import {
  invokeCompletedByAgeRangeAndGenderForAd,
  invokeCompletedByGenderDemographicForLocationPartner,
  invokeSummaryStaticsInDateBetweenForLP,
  invokeSummaryStaticsInDateBetweenForAd,
  invokeUniqueViewsByGenderAndAgeForAd,
  invokeUniqueViewsByGenderDemographicForLP,
  invokeViewsByAgeRangeAndGenderByAd,
  invokeViewsByGenderDemographicForLocationPartner,
  resultSummaryStatics,
  resultViewsByGenderDemographicForLocationPartner,
} from "src/app/store/statistics/statistics.actions";
import {
  genderAgeStatisticsForAdSelector,
  resultCompletedByGenderDemographicForLocationPartnerSelector,
  resultUniqueViewsByGenderDemographicForLocationPartnerSelector,
  resultViewsGenderAgeStaticsForLPSelector,
  summaryStatisticsSelector,
} from "src/app/store/statistics/statistics.selectors";
import { Style } from "src/app/utils/style/style";
import { DateFilter } from "../../models/date-filte.interface";
import { InputAudience } from "../../models/input-audience.interface";
import { LPdetails } from "../../models/location-partners/LPdetails";
import { GenderAgeStatistics } from "../../models/statistics/gender-age-statistics";
import { SummaryStatics } from "../../models/statistics/summary-statics";
import { JwtService } from "../../services/jwt.service";
import { AudienceType } from "../../enum/chart/audiance";
import {
  invokeConsumerDemographicDetails,
  invokeCountConsumersBetweenDates,
} from "src/app/store/consumers/consumers.actions";
import { ConsumerCountByDate } from "../../models/consumers/consumerCountByDate";
import { countConsumersBetweenDatesSelector } from "src/app/store/consumers/consumers.selectors";
import {
  columnChartFilter,
  FilterInterface,
  splineChartFilter,
} from "../../data/filter-data";
import {
  audienceData,
  ExportData,
  exportDataType,
} from "../../data/exportData";
import { RoleEnum } from "../../models/user/role.interface";
import { ChartStatus, ChartStatusToShow } from "../../enum/chartStatus";
import { TranslateService } from "@ngx-translate/core";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { LocationAndDateFilter } from "../../models/LocationAndDateFilter";
import { SelectedLps } from "../../models/location-partners/selectedLps";
import { CampaignStatusEnum } from "../../enum/campaign-status";
import { AdvertisingCampaignInterface } from "../../models/advertising-campaigns/advertising-campaign.interface";
import { getAdCompaignByHashedIdSelector } from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";

@Component({
  selector: "app-audience",
  templateUrl: "./audience.component.html",
})
export class AudienceComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  Audience: ExportData[] = audienceData;
  @Input() isPremium: boolean;
  @Input() isConsummer: boolean = false;
  @Output() resultSummaryChart = new EventEmitter<SummaryStatics>();
  @Output() optionSelected = new EventEmitter<string>();
  @Output() genderAgeStatics = new EventEmitter<GenderAgeStatistics | null>();
  @Output() genderAgeStaticsForAd =
    new EventEmitter<GenderAgeStatistics | null>();
  @Input() type: string;
  // @Input() title: string;
  @Input("inputAudience$") inputAudience$: Observable<InputAudience> = of({
    name: null,
    type: null,
    title: null,
    titleSpline: "invoices.summary",
    titleColumnChart: "invoices.distributionAgeGender",
  });
  audienceType = AudienceType;
  inputAudience: InputAudience = {
    name: null,
    type: null,
    title: null,
    titleSpline: "invoices.summary",
    titleColumnChart: "invoices.distributionAgeGender",
  };
  summaryChart = {
    title: "Summary chart",
    yaxisTitle: "Charts.nombreViews",
    xaxisTitle: "Charts.days",
    xaxis: {
      title: { text: "Charts.days" },
    },
    seriesTitles: ["Impressions", "Unique views", "Engagement"],
  };
  columnChart = {
    title: "Données démographiques",
    yaxisTitle: "Charts.nombreViews",
    xaxisTitle: "Days",
    xaxis: {
      title: { text: "Days" },
      categories: Style.ageRange,
    },

    seriesTitles: ["Impressions", "Unique views", "Engagement"],
  };
  receivedChart: any;
  dashboardLP: boolean = false;

  resultSummaryChartForLP: SummaryStatics | null;
  resultSummaryResult$: Observable<SummaryStatics | null>;
  summaryStatics: SummaryStatics | null;
  resultSummaryChartBetweenDates: SummaryStatics | null;
  startDate: Date;
  endDate: Date;

  genderAgeStaticsForAd$: Observable<GenderAgeStatistics | null>;
  resultGenderAgeStatics: GenderAgeStatistics | null;
  resultViewsByGenderDemographicForLP$: Observable<GenderAgeStatistics | null>;
  resultViewsByGenderDemographicForLP: GenderAgeStatistics | null;
  resultCompletedByGenderDemographicForLP$: Observable<GenderAgeStatistics | null>;
  resulCompletedByGenderDemographicForLP: GenderAgeStatistics | null;
  resultUniqueViewsByGenderDemographicForLP$: Observable<GenderAgeStatistics | null>;
  resulUniqueViewsByGenderDemographicForLP: GenderAgeStatistics | null;
  lp: string;
  @Input() adTitle: string | null;
  companyName: string;
  lpDetails$: Observable<LPdetails | null>;
  countConsumersByDate$: Observable<ConsumerCountByDate | null>;
  filterColumnChart: FilterInterface = columnChartFilter;
  filterSpline: FilterInterface = splineChartFilter;
  role: string;

  selectedLp: string | null = null;
  saveSelectedLP$: Observable<SelectedLps | null>;
  selectedOption: string | null = null;

  title: string;
  seriesTitles: string[] = [];
  yaxisTitle: string;
  isAdFinished: boolean;
  adCompaignByHashedId$: Observable<AdvertisingCampaignInterface | null>;
  cachedAt: Date | null;
  loading : boolean
  constructor(
    private fb: FormBuilder,
    private store: Store<AppStateInterface>,
    private jwtService: JwtService,
    private translate: TranslateService
  ) {
    super(store);
    this.adCompaignByHashedId$ = this.store
      .pipe(select(getAdCompaignByHashedIdSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultViewsByGenderDemographicForLP$ = this.store
      .pipe(select(resultViewsGenderAgeStaticsForLPSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultCompletedByGenderDemographicForLP$ = this.store
      .pipe(
        select(resultCompletedByGenderDemographicForLocationPartnerSelector)
      )
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultUniqueViewsByGenderDemographicForLP$ = this.store
      .pipe(
        select(resultUniqueViewsByGenderDemographicForLocationPartnerSelector)
      )
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultSummaryResult$ = this.store
      .pipe(select(summaryStatisticsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.genderAgeStaticsForAd$ = this.store
      .pipe(select(genderAgeStatisticsForAdSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.lpDetails$ = this.store
      .pipe(select(lpDetailsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.countConsumersByDate$ = this.store
      .pipe(select(countConsumersBetweenDatesSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.saveSelectedLP$ = this.store
      .pipe(select(saveSelectedLPsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.form = this.fb.group({
      date: [[null, null]],
    });
  }

  ngOnInit(): void {
    if (this.inputAudience.title) this.title = this.inputAudience.title;
    this.saveSelectedLP$.subscribe((data) => {
      if (data) {
        this.selectedLp = data.companyNames[0];
      } else {
        this.selectedLp = null;
      }
      this.getChartInDate(false);
    });
    this.filterColumnChart.name.map(
      (e) =>
        (e.data = Object.keys(ChartStatus)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              ChartStatusToShow[key as keyof typeof ChartStatus]
          ))
    );
    this.filterSpline.name.map(
      (e) =>
        (e.data = Object.keys(ChartStatus)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              ChartStatusToShow[key as keyof typeof ChartStatus]
          ))
    );
    this.role = this.jwtService.getRoleName(this.jwtService.getToken());
    this.store.dispatch(resultSummaryStatics({ summaryStatistic: null }));
    this.store.dispatch(
      resultViewsByGenderDemographicForLocationPartner({
        genderAgeStatistics: null,
      })
    );
    this.inputAudience$.subscribe((input) => {
      if (input.type != null) {
        this.inputAudience = input;
        if (this.isPremium && this.role == RoleEnum.ROLE_LOCATION) {
          this.getChartInDate(false);
        } else {
          this.getChartInDate(false);
        }
      }
    });
    this.resultViewsByGenderDemographicForLP$.subscribe(
      (result: GenderAgeStatistics | null) => {
        if (result != null) {
          let csvRows: string[] = [];
          this.genderAgeStatics.emit(result);
          let headers = [];
          headers.push("age");
          headers.push(Object.keys(result));
          for (let i = 0; i < result.female.length; i++) {
            const cols = [
              this.translate.instant(Style.ageRange[i]),
              result.female[i],
              result.male[i],
            ];
            csvRows.push(cols.join(","));
          }
          for (let i = 0; i < this.Audience.length; i++) {
            if (
              this.Audience[i].type ==
              exportDataType.INVOICES_DISTRIBUTION_AGE_GENDER
            )
              this.Audience[i].data = [headers, ...csvRows].join("\n");
          }
        }
      }
    );
    this.resultSummaryResult$.subscribe((result: SummaryStatics | null) => {
      if (result != null) {
        this.summaryStatics = result;
        this.cachedAt = result.cachedAt
        this.resultSummaryChart.emit(result);
        this.seriesTitles = this.summaryChart.seriesTitles;
        this.yaxisTitle = this.summaryChart.yaxisTitle;
        const csvRows = [];
        let headers;
        if (this.summaryStatics != null) {
          headers = Object.keys(this.summaryStatics);
          for (let i = 0; i < this.summaryStatics.dates.length; i++) {
            const cols = [
              this.summaryStatics.dates[i],
              this.summaryStatics.views[i],
              this.summaryStatics.redirection[i],
              this.summaryStatics.peopleFinishAds[i],
              this.summaryStatics.uniqueViews[i],
            ];
            csvRows.push(cols.join(","));
          }
        }
        for (let i = 0; i < this.Audience.length; i++) {
          if (this.Audience[i].type == exportDataType.INVOICES_SUMMARY)
            this.Audience[i].data = [headers, ...csvRows].join("\n");
          if (this.form.get("date")?.value[0] != null) {
            this.Audience[i].dates = this.form.get("date")?.value;
          }
        }
      }
    });
    this.genderAgeStaticsForAd$.subscribe(
      (result: GenderAgeStatistics | null) => {
        if (result != null) {
          this.resultGenderAgeStatics = result;
          this.genderAgeStatics.emit(result);
          const csvRows = [];
          let headers = [];
          headers.push("age");
          headers.push(Object.keys(result));
          for (let i = 0; i < result.female.length; i++) {
            const cols = [
              this.translate.instant(Style.ageRange[i]),
              result.female[i],
              result.male[i],
            ];
            csvRows.push(cols.join(","));
          }
          for (let i = 0; i < this.Audience.length; i++) {
            if (
              this.Audience[i].type ==
              exportDataType.INVOICES_DISTRIBUTION_AGE_GENDER
            )
              this.Audience[i].data = [headers, ...csvRows].join("\n");
          }
        }
      }
    );
    this.countConsumersByDate$.subscribe(
      (result: ConsumerCountByDate | null) => {
        if (result != null) {
          this.cachedAt = result.cachedAt
          this.resultSummaryChart.emit({
            dates: result.dates,
            views: result.count,
            peopleFinishAds: [],
            redirection: [],
            uniqueViews: [],
            cachedAt: result.cachedAt,
          });
          this.seriesTitles = [];
          this.yaxisTitle = "Charts.consumers";
          const csvRows = [];
          let headers;
          if (this.summaryStatics != null) {
            headers = Object.keys(this.summaryStatics);
            for (let i = 0; i < this.summaryStatics.dates.length; i++) {
              const cols = [
                this.summaryStatics.dates[i],
                this.summaryStatics.views[i],
                this.summaryStatics.redirection[i],
                this.summaryStatics.peopleFinishAds[i],
                this.summaryStatics.uniqueViews[i],
              ];
              csvRows.push(cols.join(","));
            }
          }
          for (let i = 0; i < this.Audience.length; i++) {
            if (this.Audience[i].type == exportDataType.INVOICES_SUMMARY)
              this.Audience[i].data = [headers, ...csvRows].join("\n");
            if (this.form.get("date")?.value[0] != null) {
              this.Audience[i].dates = this.form.get("date")?.value;
            }
          }
        }
      }
    );
  }
  getChartInDate(refreshCache: boolean) {
    this.loading = true
    this.startDate = this.form.get("date")?.value[0];
    this.endDate = this.form.get("date")?.value[1];
    const dateFilter: DateFilter = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    if (this.endDate != null && this.startDate != null) {
      this.store.dispatch(invokeApiDistroyed());
    }
    /* if (this.startDate != null && this.endDate != null) { */
    switch (this.inputAudience.type) {
      case AudienceType.LOCATION:
        if (
          this.isPremium ||
          this.jwtService.getRoleName(this.jwtService.getToken()) ==
            RoleEnum.ROLE_ADMIN
        ) {
          this.store.dispatch(
            invokeSummaryStaticsInDateBetweenForLP({
              companyName: this.inputAudience.name ?? "",
              dateFilter: dateFilter,
              refreshCache : refreshCache
            })
          );
          this.store.dispatch(
            invokeViewsByGenderDemographicForLocationPartner({
              companyName: this.inputAudience.name ?? "",
              forMap: false,
              dateFilter: dateFilter,
              refreshCache : refreshCache
            })
          );
        } else {
          this.store.dispatch(resultSummaryStatics({ summaryStatistic: null }));
          this.store.dispatch(
            resultViewsByGenderDemographicForLocationPartner({
              genderAgeStatistics: null,
            })
          );
        }
        break;
      case AudienceType.INSIGHTS:
        this.adCompaignByHashedId$.subscribe((result) => {
          if (result) {
            this.isAdFinished = result.status == CampaignStatusEnum.FINISHED;
            const filter: LocationAndDateFilter = {
              startDate: this.startDate,
              endDate: this.endDate,
              locationPartner: this.selectedLp,
              finished: result.status == CampaignStatusEnum.FINISHED,
            };
            this.store.dispatch(
              invokeSummaryStaticsInDateBetweenForAd({
                campaignHashedId: this.inputAudience.name,
                refreshCache : refreshCache,
                filter: filter,
              })
            );
            this.store.dispatch(
              invokeViewsByAgeRangeAndGenderByAd({
                filter: filter,
                campaignHashedId: this.inputAudience.name,
                refreshCache : refreshCache
              })
            );
          }
        });
        break;
      case AudienceType.CONSUMERS:
        this.summaryChart = {
          title: "Summary chart",
          yaxisTitle: "Charts.consumers",
          xaxisTitle: "Charts.days",
          xaxis: {
            title: { text: "Charts.days" },
          },
          seriesTitles: [],
        };
        this.store.dispatch(
          invokeCountConsumersBetweenDates({
            dateFilter: dateFilter,
            refreshCache: refreshCache,
          })
        );

        this.store.dispatch(
          invokeConsumerDemographicDetails({
            dateFilter: dateFilter,
            refreshCache: refreshCache,
          })
        );
        break;
      default:
        break;
    }
  }
  resultOptionClicked(option: string) {
    this.optionSelected.emit(option);
  }
  saveOption(option: string) {
    if (this.selectedOption) {
      this.store.dispatch(invokeApiDistroyed());
    }
    const KeyFound = Object.keys(ChartStatusToShow).find(
      (key) => ChartStatusToShow[key as ChartStatus] === option
    );
    if (KeyFound) {
      this.selectedOption = KeyFound;
    }
    const filter: LocationAndDateFilter = {
      startDate: this.startDate,
      endDate: this.endDate,
      locationPartner: this.selectedLp,
      finished: this.isAdFinished,
    };
    const dateFilter: DateFilter = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    if (this.inputAudience.type == AudienceType.LOCATION) {
      switch (KeyFound) {
        case ChartStatus.ENGAGEMENT:
          this.store.dispatch(
            invokeCompletedByGenderDemographicForLocationPartner({
              companyName: this.inputAudience.name ?? "",
              dateFilter: dateFilter,
            })
          );
          break;
        case ChartStatus.UNIQUEVIEWS:
          this.store.dispatch(
            invokeUniqueViewsByGenderDemographicForLP({
              companyName: this.inputAudience.name ?? "",
              dateFilter: dateFilter,
            })
          );
          break;
        case ChartStatus.VIEWS:
          this.store.dispatch(
            invokeViewsByGenderDemographicForLocationPartner({
              companyName: this.inputAudience.name ?? "",
              forMap: false,
              dateFilter: dateFilter,
              refreshCache : false
            })
          );
          break;
        default:
          break;
      }
    }
    if (this.inputAudience.type == AudienceType.INSIGHTS) {
      switch (KeyFound) {
        case ChartStatus.ENGAGEMENT:
          this.store.dispatch(
            invokeCompletedByAgeRangeAndGenderForAd({
              campaignHashedId: this.inputAudience.name,
              filter: filter,
            })
          );
          break;
        case ChartStatus.UNIQUEVIEWS:
          this.store.dispatch(
            invokeUniqueViewsByGenderAndAgeForAd({
              campaignHashedId: this.inputAudience.name,
              filter: filter,
            })
          );
          break;
        case ChartStatus.VIEWS:
          this.store.dispatch(
            invokeViewsByAgeRangeAndGenderByAd({
              filter: filter,
              campaignHashedId: this.inputAudience.name,
              refreshCache : false
            })
          );
          break;
        default:
          break;
      }
    }
  }
  chart(chart: any) {
    this.receivedChart = chart;
  }
}
