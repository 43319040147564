import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts";
import { Observable, of } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { DeviceBrand } from "src/app/shared/models/statistics/device-brand";
import { AppStateInterface } from "src/app/store/appState.interface";
import { setDeviceBrand } from "src/app/store/consumers/consumers.actions";
import { TranslateService } from "@ngx-translate/core";
let primary_color = "#FF4FA7";
let secondary_color = "#F7DAED";
let third_color = "#F7DAED";
let fourth_color = "#FFE1F0";
let fifth_color = "#FFAFD7";
let sixth_color = "#9D77F4";
let seventh_color = "#FF69B4";
let eighth_color = "#ee368a";
let ninth_color = "rgb(238, 54, 171)";
let color_10 = "rgb(238, 111, 171)";
let color_11 = "rgb(238, 150, 171)";
let color_12 = "rgb(238, 150, 197)";
let color_13 = "rgb(238, 150, 231)";
@Component({
  selector: "app-pie",
  templateUrl: "./pie.component.html",
})
export class PieComponent extends BaseComponent implements OnInit {
  @Input("resultDeviceBrand")
  resultDeviceBrand: Observable<Map<string, number> | null> = of(null);

  @ViewChild("chartApex", { static: false }) chartApex: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  @Input() chart?: any = {};
  xaxis: ApexXAxis = {};
  yaxis: ApexYAxis = {};
  @Input() title = "";
  @Input() style: any;
  @Input() legend: ApexLegend;
  @Input() labels: any;
  @Input() xaxisTitle = "";
  @Input() yaxisTitle = "";
  @Input() seriesTitles: string[] = [];

  @Input() isLoading: boolean;

  deviceBrandForAd$: Observable<DeviceBrand | null>;
  deviceBrand: DeviceBrand | null;
  adTitle: string | null;
  length = 0;
  constructor(
    private store: Store<AppStateInterface>,
    private translate: TranslateService
  ) {
    super(store);
    this.chartOptions = {
      series: [],
      chart: {
        width: 450,
        height: 480,
        type: "pie",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        show: true,
        position: "bottom",
        floating: true,
        verticalAlign: "bottom",
        align: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: true,
              position: "bottom",
              floating: true,
              verticalAlign: "bottom",
              align: "center",
            },
          },
        },
      ],
    };
  }

  ngOnInit(): void {
    this.resultDeviceBrand.subscribe((result) => {
      if (result) {
        this.isLoading = false;
        this.configChart(result);
        this.length = Object.values(result).reduce(
          (total, value) => total + value,
          0
        );
      }
    });
    
    //this.adTitle = this.route.snapshot.queryParamMap.get("title");
    this.store.dispatch(setDeviceBrand({ deviceBrand: null }));
    if (this.deviceBrandForAd$ != undefined) {
      this.deviceBrandForAd$.subscribe((result: DeviceBrand | null) => {
        if (result != null) {
          this.isLoading = false;
          this.length = Object.values(result).reduce(
            (total, value) => total + value,
            0
          );
          //this.configChart(result);
        }
      });
    }
  }
  configChart(result: Map<string, number>) {
    let series: number[] = [];
    Object.keys(result).filter((value, index) => {
      series.push(Object.values(result)[index]);
    });
    this.chartOptions = {
      ...this.chartOptions,
      chart: {
        ...this.chartOptions.chart,
      },
      colors: [
        seventh_color,
        sixth_color,
        secondary_color,
        third_color,
        fourth_color,
        fifth_color,
        primary_color,
        eighth_color,
        ninth_color,
        color_10,
        color_11,
        color_12,
        color_13,
      ],
      series: series,
      labels: Object.keys(result),
      legend: {
        customLegendItems: Object.keys(result),
        show: true,
        position: "bottom",
        verticalAlign: "bottom",
        align: "center",
      },
    };
  }
}
export type ChartOptions = {
  series?: ApexAxisChartSeries;
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  labels?: string[];
  stroke?: ApexStroke;
  title?: ApexTitleSubtitle;
};
