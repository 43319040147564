import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, takeUntil } from "rxjs";
import { invokeComponentDistroyed, setAPIStatus } from "../apiState.interface";
import { ConsumersService } from "src/app/shared/services/consumers.service";
import {
  InvokeDeviceType,
  consumerAgeRangeDetails,
  consumerCountBetweenDates,
  consumerDemographiqueDetails,
  consumersGenderDetails,
  invokeBrowserFamily,
  invokeConsumerAgeRangeDetails,
  invokeConsumerDemographicDetails,
  invokeConsumersGenderDetails,
  invokeCountConsumersBetweenDates,
  invokeDeviceBrand,
  invokeOsFamily,
  setBrowserFamily,
  setDeviceBrand,
  setDeviceType,
  setOsFamily,
  invokeConsumersCountInDay,
} from "./consumers.actions";
import { ConsumerCountByDate } from "src/app/shared/models/consumers/consumerCountByDate";
import { GenderStatistics } from "src/app/shared/models/statistics/gender-statistics";
import { GenderAgeStatistics } from "src/app/shared/models/statistics/gender-age-statistics";
import { AgeStatistics } from "src/app/shared/models/statistics/age-statistics";
import { BrowserFamily } from "src/app/shared/models/statistics/browserFamily";
import { DeviceBrand } from "src/app/shared/models/statistics/device-brand";
import { DeviceType } from "src/app/shared/models/statistics/deviceType";
import { OsFamily } from "src/app/shared/models/statistics/osFamily-statics";

@Injectable()
export class ConsumerEffect {
  constructor(
    private actions$: Actions,
    private consumerService: ConsumersService
  ) {}
  /*  invokeCountConsumersByDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCountConsumersByDate),
      mergeMap((state) => {
        return this.consumerService.getConsumersCountByDate().pipe(
          map((data: ConsumerCountByDate | null) =>
            countConsumersByDate({ consumerCountByDate: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          )
        );
      })
    )
  ); */

  invokeBrowserFamily$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeBrowserFamily),
      mergeMap((state) => {
        return this.consumerService.getBrowserFamily(state.search, state.refreshCache).pipe(
          map((data: BrowserFamily | null) =>
            setBrowserFamily({ browserFamily: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );
  invokeDeviceType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvokeDeviceType),
      mergeMap((state) => {
        return this.consumerService.getDeviceType(state.search, state.refreshCache).pipe(
          map((data: DeviceType | null) => setDeviceType({ deviceType: data })),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );
  invokeDeviceBrand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeDeviceBrand),
      mergeMap((state) => {
        return this.consumerService.getDeviceBrand(state.search, state.refreshCache).pipe(
          map((data: Map<string, number> | null) =>
            setDeviceBrand({ deviceBrand: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );
  invokeOsFamily$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeOsFamily),
      mergeMap((state) => {
        return this.consumerService.getOsFamily(state.search, state.refreshCache).pipe(
          map((data: OsFamily | null) => setOsFamily({ osFamily: data })),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );

  invokeCountConsumersBetweenDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCountConsumersBetweenDates),
      mergeMap((state) => {
        return this.consumerService
          .getConsumerCountBetweenDates(state.dateFilter, state.refreshCache)
          .pipe(
            map((data: ConsumerCountByDate | null) =>
              consumerCountBetweenDates({ consumerCountByDate: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeConsumersGenderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeConsumersGenderDetails),
      mergeMap((state) => {
        return this.consumerService.getConsumersGenderDetails(state.refreshCache).pipe(
          map((data: GenderStatistics | null) =>
            consumersGenderDetails({ genderStatistics: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );
  invokeConsumerDemographicDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeConsumerDemographicDetails),
      mergeMap((state) => {
        return this.consumerService
          .getConsumerDemographicDetails(state.dateFilter, state.refreshCache)
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              consumerDemographiqueDetails({ genderAgeStatics: data })
            ),
            catchError((error) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeConsumerAgeRangeDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeConsumerAgeRangeDetails),
      mergeMap((state) => {
        return this.consumerService.getConsumerAgeRangeDetails(state.refreshCache).pipe(
          map((data: AgeStatistics | null) =>
            consumerAgeRangeDetails({ ageStatics: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );

  invokeConsumerCountByDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeConsumersCountInDay),
      mergeMap((state) => {
        return this.consumerService.getConsumersCountByDay(state.day).pipe(
          map((data: ConsumerCountByDate | null) =>
            consumerCountBetweenDates({ consumerCountByDate: data })
          ),
          catchError((error) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );
}
