import { createAction, props } from "@ngrx/store";
import { FilterUserHistoryInterface } from "src/app/shared/models/user-history/filter-user-history.interface";
import { ResultUserHistoryInterface } from "src/app/shared/models/user-history/result-user-history.interface";

export const invokeUsersHistory = createAction(
  "[ Invoke Get Users History ] invoke get Users History",
  props<{ refreshCache: boolean }>()
);
export const setUsersHistory = createAction(
  "[ Set Users History ] Set Users History",
  props<{ history: ResultUserHistoryInterface }>()
);

export const setUsersHistoryFilter = createAction(
  "[ Invoke Users History Filter ] invoke Users History Filter",
  props<{ filter: FilterUserHistoryInterface}>()
);
