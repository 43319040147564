import { createAction, props } from "@ngrx/store";
import { FilterLastWeekForLPReview } from "src/app/shared/models/location-partners/filterLastWeekForLPReview";
import { ResultMainStaticsForLP } from "src/app/shared/models/location-partners/resultMainStaticsLP";
import { Pager } from "src/app/shared/models/pagination";

//get Main statics
export const invokeMainStaticsForLocationPartner = createAction(
  "[ Invoke Main Statics For Location Partner ] Invoke main statics for location partner ",
  props<{ authentification: string }>()
);
export const resultMainStaticsForLocationPartner = createAction(
  "[ Result Main Statics For Location Partner ] Result main statics for location partner ",
  props<{ resultMainStaticsForLP: ResultMainStaticsForLP }>()
);

//get Review for the last week
export const invokeLocationPartnerReview = createAction(
  "[ Invoke Location Partner Review ] Invoke location partner review ",
  props<{ pager: Pager , refreshCache : boolean}>()
);
export const resultLocationPartnerReview = createAction(
  "[ Result Location Partner Review ] Result location partner review ",
  props<{ lastWeekReviewLP: FilterLastWeekForLPReview | null }>()
);
