<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="d-flex justify-content-center">
            <div
              class="img-contour d-flex justify-content-center align-items-center"
            >
              <img
                src="assets/identity/logo-footer.png"
                width="60"
                height="60"
                alt="logo-relead"
              />
            </div>
          </div>
          <form
            class="theme-form login-form"
            novalidate
            [formGroup]="form"
            (ngSubmit)="login($event)"
          >
            <h4 style="text-align: center">Vous êtes de retour !</h4>
            <h6 style="text-align: center; color: black">
              Heureux de vous revoir !
            </h6>
            <div class="form-group">
              <label style="color: black">E-mail</label>
              <div class="input-group">
                <span class="input-group-text">
                  <img src="assets/identity/email.png" alt="icone email" />
                </span>
                <input
                  formControlName="email"
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="Saisir votre adresse e-mail."
                  [ngClass]="{ 'is-invalid': submitted && email?.errors }"
                />
                <div
                  *ngIf="submitted && email?.errors"
                  class="invalid-feedback"
                >
                  <div class="invalid-feedback">
                    Veuillez saisir l'e-mail approprié.
                  </div>
                  <div *ngIf="email?.errors?.['required']">
                    L'e-mail est requis.
                  </div>
                  <div *ngIf="email?.errors?.['email']">
                    L'e-mail doit être une adresse e-mail valide.
                  </div>
                </div>
                <div *ngIf="email?.valid" class="valid-feedback">
                  Bien vérifié.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label style="color: black">Mot de passe</label>
              <div class="input-group">
                <span class="input-group-text">
                  <img
                    src="assets/identity/password.png"
                    alt="icone password"
                  />
                </span>
                <input
                  formControlName="password"
                  class="form-control"
                  type="password"
                  name="login[password]"
                  [ngClass]="{ 'is-invalid': submitted && password?.errors }"
                  [type]="show ? 'text' : 'password'"
                  required=""
                  placeholder="Saisir votre mot de passe."
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span>Afficher</span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span>Cacher</span>
                </div>
                <div
                  *ngIf="submitted && password?.errors"
                  class="invalid-feedback"
                >
                  <div class="invalid-feedback">
                    Veuillez saisir le mot de passe approprié.
                  </div>
                  <div *ngIf="password?.errors?.['required']">
                    Le mot de passe est requis.
                  </div>
                  <div *ngIf="password?.errors?.['pattern']">
                    Veuillez saisir le mot de passe approprié.
                  </div>
                </div>
                <div *ngIf="password?.valid" class="valid-feedback">
                  Bien vérifié.
                </div>
              </div>
            </div>
            <div class="form-group d-flex justify-content-center">
              <re-captcha
                (resolved)="resolved($event)"
                lang="fr"
                (error)="onError($event)"
                (expired)="onExpired()"
                errorMode="handled"
                [siteKey]="reCaptchaKey"
              ></re-captcha>
            </div>
            <div
              class="form-group d-flex justify-content-between align-items-center"
            >
              <div class="checkbox ms-1">
                <input id="checkbox1" checked type="checkbox" />
                <label
                  style="font-size: small; font-weight: 500"
                  for="checkbox1"
                  >Garder ma session active</label
                >
              </div>
              <a
                class="link color-primary"
                style="font-size: smaller"
                routerLink="../forget-password"
                >Mot de passe oublié?</a
              >
            </div>
            <div
              *ngIf="error"
              class="alert alert-danger outline alert-dismissible fade show"
              role="alert"
            >
              <app-feather-icon [icon]="'thumbs-down'"></app-feather-icon>
              <p>Votre temps Fini après <b>5</b> miniute</p>
              <button
                class="btn-close"
                type="button"
                (click)="error = null"
              ></button>
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary"
                type="submit"
                [disabled]="form.invalid || !isValidateCaptcha"
              >
                <loading-button
                  [loading]="loading$ | async"
                  [value]="'Se connecter'"
                  [icon]="null"
                ></loading-button>
              </button>
            </div>
            <hr />
            <p>
              Vous n’avez pas de compte ?<a
                class="ms-2 color-primary"
                routerLink="../register"
                >S’inscrire</a
              >
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
