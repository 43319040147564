import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { ActionInterface } from "src/app/shared/data/actions-data";
import {
  FilterInterface,
  paymentLPFilter,
} from "src/app/shared/data/filter-data";
import { SortEvent } from "src/app/shared/directive/sortable.directive";
import { PaymentFrequencyEnumMapping } from "src/app/shared/enum/payment-frequency";
import {
  PaymentStatus,
  PaymentStatusToShow,
} from "src/app/shared/enum/paymentStatus";
import { PricingMethodEnumMapping } from "src/app/shared/enum/pricing-method";
import { AdvertisingCampaignsPaymentLP } from "src/app/shared/models/advertising-campaigns/advertising-campaigns-payment-LP.interface";
import { LocationPartnersFilterInterface } from "src/app/shared/models/location-partners/locationPartnersFilter.interface";
import { PricingInterface } from "src/app/shared/models/location-partners/pricing";
import { ResultGlobalStatisticsForLP } from "src/app/shared/models/statistics/resultGlobalStaticsForLP";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { JwtService } from "src/app/shared/services/jwt.service";
import { AppStateInterface } from "src/app/store/appState.interface";
import { initialState } from "src/app/store/locationPartner/location-partner.reducers";
import {
  pricingByCompanyNameSelector,
  loadingLocationPartnersSelector,
  resultCalculateViewsOrUniqueViewsBasedOnNotPaidAdsSelector,
  resultLocationPartnerAdvertisingCampignFilterSelector,
  resultLoadingLocationAdsSelector,
  loadingViewsSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { invokeGlobalStatisticsForLP } from "src/app/store/statistics/statistics.actions";
import { resultGlobalStatisticsForLPSelector } from "src/app/store/statistics/statistics.selectors";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import {
  resultCalculateViewsOrUniqueViewsBasedOnNotPaidAds,
  resultLocationPartnerAdvertisingCampignFilter,
  setPricingByCompanyName,
  invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds,
  invokePricingByCompanyName,
  invokeLocationPartnerAdvertisingCampignFilter,
} from "src/app/store/locationPartner/location-partner.action";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import {
  invokeLPInfo,
  resultLPInfo,
} from "src/app/store/locationPartner/actions/get-locations.actions";
import { LPInfo } from "src/app/shared/models/location-partners/lpInfo";
import { resultLPInfoSelector } from "src/app/store/locationPartner/location-partner.selectors";
import { PartnerStatus } from "src/app/shared/enum/partner-status";
@Component({
  selector: "app-remuneration",
  templateUrl: "./remuneration.component.html",
  styleUrls: ["./remuneration.component.scss"],
})
export class RemunerationComponent extends BaseComponent implements OnInit {
  pricing$: Observable<PricingInterface | null>;
  pricing: PricingInterface;
  token: string;
  resultGlobalStaticsForLP$: Observable<ResultGlobalStatisticsForLP | null>;
  views: number;
  uniqueViews: number;
  columns: IColumn[] = [
    {
      key: "titre",
      label: "admin.locations.campaigns.campaignName",
      sorter: true,
    },
    {
      key: "startDate",
      label: "admin.locations.payment.campaignStartDate",
      sorter: true,
    },
    {
      key: "endDate",
      label: "admin.locations.payment.campaignEndDate",
      sorter: true,
    },
    {
      key: "impression",
      label: "admin.locations.payment.impressionsNumber",
      sorter: true,
    },
    {
      key: "uiqueViews",
      label: "admin.locations.payment.uniqueImpressionsNumber",
      sorter: true,
    },
    {
      key: "status",
      label: "admin.locations.campaigns.status",
      sorter: true,
    },
    {
      key: "TTC",
      label: "admin.locations.payment.totalTTC",
      sorter: true,
    },
  ];
  optionAction: ActionInterface[];
  status: string;
  companyName: string;
  globalStatics: ResultGlobalStatisticsForLP | null;
  loading$: Observable<boolean | null>;
  pricingMethod = PricingMethodEnumMapping;
  PaymentFrequency = PaymentFrequencyEnumMapping;
  totalViewsNotPaid$: Observable<number | null>;
  totalViewsNotPaid: number = 0;
  locationPartnerFilter: LocationPartnersFilterInterface = {
    ...initialState.locationPartnerFilter,
  };
  isPaid: boolean | null = null;
  formFilter: FormGroup;

  filterAdsForLocationPartners$: Observable<AdvertisingCampaignsPaymentLP | null>;
  filterAdsForLocationPartners: AdvertisingCampaignsPaymentLP;
  advertsingCampaigns: AdvertisingCampaignInterface[] = [];
  filterPayment: FilterInterface = paymentLPFilter;
  loadingAds$: Observable<boolean | null>;
  loadingViews$: Observable<boolean | null>;
  currentLocationPartner$: Observable<LPInfo | null>;
  currentLP: LPInfo | null;
  partnerStatus = PartnerStatus;
  cachedAt: Date | null;
  constructor(
    private store: Store<AppStateInterface>,
    private jwtService: JwtService,
    public badgeService: BadgeService,
    private fb: FormBuilder
  ) {
    super(store);
    this.currentLocationPartner$ = this.store
      .pipe(select(resultLPInfoSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.pricing$ = this.store
      .pipe(select(pricingByCompanyNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.resultGlobalStaticsForLP$ = this.store
      .pipe(select(resultGlobalStatisticsForLPSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingLocationPartnersSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.totalViewsNotPaid$ = this.store
      .pipe(select(resultCalculateViewsOrUniqueViewsBasedOnNotPaidAdsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.filterAdsForLocationPartners$ = this.store
      .pipe(select(resultLocationPartnerAdvertisingCampignFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loadingAds$ = this.store
      .pipe(select(resultLoadingLocationAdsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      companyName: [""],
      date: [[null, null]],
    });
    this.loadingViews$ = this.store
      .pipe(select(loadingViewsSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }
  ngOnInit(): void {
    this.store.dispatch(resultLPInfo({ lpInfo: null }));
    this.currentLocationPartner$.subscribe((result) => {
      if (result) {
        this.currentLP = result;
      } else {
        this.store.dispatch(invokeLPInfo());
      }
    });
    this.filterPayment.name.map(
      (e) =>
        (e.data = Object.keys(PaymentStatus)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              PaymentStatusToShow[key as keyof typeof PaymentStatus]
          ))
    );
    this.loading$.subscribe((data) => {});
    this.store.dispatch(
      resultCalculateViewsOrUniqueViewsBasedOnNotPaidAds({ views: null })
    );
    this.store.dispatch(
      resultLocationPartnerAdvertisingCampignFilter({
        advertisingCampaignsPaymentLP: null,
      })
    );
    this.store.dispatch(setPricingByCompanyName({ pricing: null }));
    this.loading$.subscribe((data) => {});
    this.loadingAds$.subscribe((data) => {});
    this.loadingViews$.subscribe((data) => {});
    this.token = this.jwtService.getToken();
    this.companyName = this.jwtService.getCompanyName();
    this.pricing$.subscribe((result) => {
      if (result != null) {
        this.pricing = result;
        this.store.dispatch(
          invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds({
            pricingMethode: this.pricing.pricingMethod,
          })
        );
      } else {
        this.store.dispatch(
          invokePricingByCompanyName({ companyName: this.companyName })
        );
      }
    });
    this.resultGlobalStaticsForLP$.subscribe(
      (result: ResultGlobalStatisticsForLP | null) => {
        if (result != null) {
          this.views = result.views;
          this.uniqueViews = result.uniqueViews;
        } else {
          this.store.dispatch(
            invokeGlobalStatisticsForLP({
              companyName: this.companyName,
              refreshCache: false,
            })
          );
        }
      }
    );
    this.totalViewsNotPaid$.subscribe((data) => {
      if (data) {
        this.totalViewsNotPaid = data;
      }
    });
    this.filterAdsForLocationPartners$.subscribe((data) => {
      if (data) {
        this.filterAdsForLocationPartners = data;
        this.cachedAt = data.cachedAt;
        if (data.advertisingCampaigns != null)
          this.advertsingCampaigns = data.advertisingCampaigns.flat();
        else {
          this.advertsingCampaigns = [];
        }
      } else {
        this.fetchCampaigns(false)
      }
    });
  }
  onSort({ column, direction }: SortEvent) {}
  FilterDate() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      resultLocationPartnerAdvertisingCampignFilter({
        advertisingCampaignsPaymentLP: null,
      })
    );
    this.fetchCampaigns(false)
  }
  saveOptionFilter(option: any | null) {
    this.store.dispatch(invokeApiDistroyed());
    const KeyFound = Object.keys(PaymentStatusToShow).find(
      (key) => PaymentStatusToShow[key as PaymentStatus] === option
    );
    switch (KeyFound) {
      case PaymentStatus.PAID:
        this.isPaid = true;
        break;
      case PaymentStatus.UNPAID:
        this.isPaid = false;
        break;
      default:
        this.isPaid = null;
    }
    this.store.dispatch(
      resultLocationPartnerAdvertisingCampignFilter({
        advertisingCampaignsPaymentLP: null,
      })
    );
    this.fetchCampaigns(false)
  }

  fetchCampaigns(refreshCache: boolean) {
    this.store.dispatch(
      invokeLocationPartnerAdvertisingCampignFilter({
        locationPartnerCampaignsFilter: {
          ...this.locationPartnerFilter,
          isPaid: this.isPaid,
          startDate: this.formFilter.get("date")?.value[0],
          endDate: this.formFilter.get("date")?.value[1],
        },
        isWithViews: true,
        refreshCache: refreshCache,
      })
    );
  }
}
