import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { DeleteComponent } from "src/app/shared/components/drob-zone-options/modal/delete/delete.component";
import { UpdateComponent } from "src/app/shared/components/drob-zone-options/modal/update/update.component";
import {
  ActionInterface,
  ActionReturned,
  OptionActionEnum,
  routerActions,
  updateRouterActions,
} from "src/app/shared/data/actions-data";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/app/shared/directive/sortable.directive";
import {
  RouterStatus,
  RouterStatusToShow,
} from "src/app/shared/enum/router-status";
import { RouterType } from "src/app/shared/enum/routerType";
import { SessionTimeOut, SessionTimeOutToShow } from "src/app/shared/enum/sessionTimeOut";
import { TypeCards } from "src/app/shared/enum/typeCards";
import { OptionActionInterface } from "src/app/shared/models/option-action.interface";
import { RouterInterface } from "src/app/shared/models/router/router.interface";
import { RoutersFilterInterface } from "src/app/shared/models/router/routersFilter.interface";
import { RoutersResultInterface } from "src/app/shared/models/router/routersResult.interface";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BadgeService } from "src/app/shared/services/badge.service";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { AppStateInterface } from "src/app/store/appState.interface";
import {
  invokeRouterFilter,
  invokeRouters,
} from "src/app/store/router/router.action";
import { initialState } from "src/app/store/router/router.reducers";
import {
  loadingRouterSelector,
  routerFilterSelector,
  routersResultSelector,
} from "src/app/store/router/router.selectors";
import { saveUpdateFields } from "src/app/store/settings/settings.actions";
import { DetailsRouterComponent } from "./details-router/details-router.component";
@Component({
  selector: "app-list-routers",
  templateUrl: "./list-routers.component.html",
})
export class ListRoutersComponent extends BaseComponent implements OnInit {
  @Input() status: RouterStatus;
  @Input() type: RouterType;
  @Input() index: number;
  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  columns: IColumn[] = [
    {
      key: "MAC",
      label: "routers.@mac",
      sorter: true,
    },
    {
      key: "locationPartner",
      label: "routers.location",
      sorter: true,
      width: 15,
    },
    {
      key: "productionDate",
      label: "routers.productionDate",
      sorter: true,
    },
    {
      key: "dateOfPurchase",
      label: "routers.dateOfPurshase",
      sorter: true,
    },
  ];
  columnsFsi: IColumn[] = [
    {
      key: "AcquisitionPrice",
      label: "routers.acquisitionPrice",
      sorter: true,
    },
    {
      key: "Supplier",
      label: "routers.supplier",
      sorter: true,
    },
  ];
  columnsRelead: IColumn[] = [
    {
      key: "IP",
      label: "routers.ip",
      sorter: true,
      width: 15,
    },
    {
      key: "sessionTimeOut",
      label: "routers.sessiontimeOut",
      sorter: true,
      width: 15,
    },
    {
      key: "ssid",
      label: "routers.ssid",
      sorter: true,
      width: 15,
    },
    {
      key: "reference",
      label: "routers.reference",
      sorter: true,
      width: 15,
    },
  ];
  pageSize: number = 10;
  pageNumber: number = 1;
  routersFilter$: Observable<RoutersFilterInterface>;
  routersFilter: RoutersFilterInterface = {
    ...initialState.routersFilter,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  routerStatus: {
    id: RouterStatus;
    value: string;
  }[];
  routersResult$: Observable<RoutersResultInterface | null>;
  routersResult: RoutersResultInterface;
  releadRouters: boolean;
  count: number;
  options: ActionInterface[] = routerActions;
  updateRouterFields: ActionInterface[] = updateRouterActions;
  allStatus: string[] = [];
  loading$: Observable<boolean | null>;
  fields: OptionActionInterface = {
    name: [],
    icon: [],
    optionSelected: null,
    title: "",
  };
  constructor(
    private store: Store<AppStateInterface>,
    public badgeService: BadgeService,
    private route: Router,
    private modalService: NgbModal
  ) {
    super(store);
    this.routersResult$ = this.store
      .pipe(select(routersResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.routersFilter$ = this.store
      .pipe(select(routerFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingRouterSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.releadRouters = this.route.url.endsWith("/relead");
        if (this.releadRouters) {
          this.store.dispatch(
            invokeRouterFilter({
              routerFilter: {
                ...this.routersFilter,
                type: RouterType.RELEAD_ROUTER,
                pager: {
                  pageSize: this.pageSize,
                  pageNumber: this.pageNumber,
                },
              },
            })
          );
        } else {
          this.store.dispatch(
            invokeRouterFilter({
              routerFilter: {
                ...this.routersFilter,
                type: RouterType.FSI_ROUTER,
                pager: {
                  pageSize: this.pageSize,
                  pageNumber: this.pageNumber,
                },
              },
            })
          );
        }
      }
    });
  }
  ngOnInit(): void {
    this.routersFilter$.subscribe((result: RoutersFilterInterface) => {
      if (result != null) {
        this.routersFilter = result;
        this.store.dispatch(invokeRouters());
      }
    });
    this.routersResult$.subscribe((result: RoutersResultInterface | null) => {
      if (result) {
        this.routersResult = result;
        this.count = result.count;
      }
    });
    this.routerStatus = Object.keys(RouterStatus)
      .filter((value) => isNaN(Number(value)) === true)
      .map((key: string | any) => ({
        id: RouterStatus[key as keyof typeof RouterStatus],
        value: RouterStatusToShow[key as keyof typeof RouterStatus],
      }));
  }
  get changePageSize() {
    return this.routersFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  itemsPerPageChange(pageSize: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeRouterFilter({
        routerFilter: {
          ...this.routersFilter,
          pager: {
            ...this.routersFilter.pager,
            pageSize: pageSize,
          },
        },
      })
    );
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeRouterFilter({
        routerFilter: {
          ...this.routersFilter,
          pager: {
            ...this.routersFilter.pager,
            pageNumber: event,
          },
        },
      })
    );
  }
  /*getRouterStatusValue(id: RouterStatus): RouterStatus | undefined{
    return this.routerStatus.find((item) => item.id == id).id;
  }*/
  onSort({ column, direction }: SortEvent) {
    this.store.dispatch(invokeApiDistroyed());
    this.headers.forEach((header) => {
      if (column != header.sortable) {
        header.direction = "";
      }
    });
    var col: string | null = column;
    var sort = null;
    switch (direction) {
      case "asc":
        sort = true;
        break;
      case "desc":
        sort = false;
        break;
      default:
        sort = true;
        col = null;
    }
    this.store.dispatch(
      invokeRouterFilter({
        routerFilter: {
          ...this.routersFilter,
          sortName: col,
          isAscending: sort,
        },
      })
    );
  }
  saveOption(action: ActionReturned, router: RouterInterface) {
    switch (action.option.type) {
      case OptionActionEnum.ADMIN_ROUTER_UPDATE: {
        this.store.dispatch(
          saveUpdateFields({ options: this.updateRouterFields })
        );
        const modalRef = this.modalService.open(UpdateComponent, {
          centered: true,
          size: "xl",
        });
        modalRef.componentInstance.routerToEdit = {
          mac: router.mac,
          router: router,
        };
        modalRef.componentInstance.isCol4 = true;
        modalRef.componentInstance.type = TypeCards.ADMIN_ROUTER_UPDATE;
        modalRef.componentInstance.title = "actions.updateRouter";
        break;
      }
      case OptionActionEnum.ADMIN_ROUTER_DELETE: {
        const modalRef = this.modalService.open(DeleteComponent, {
          centered: true,
        });
        modalRef.componentInstance.reference = router.mac;
        modalRef.componentInstance.name = "pop-up.router.title";
        modalRef.componentInstance.title = "actions.deleteRouter";
        modalRef.componentInstance.type = OptionActionEnum.ADMIN_ROUTER_DELETE;
        break;
      }
      case OptionActionEnum.ADMIN_ROUTER_DETAILS: {
        const modalRef = this.modalService.open(DetailsRouterComponent, {
          centered: true,
          size : "lg"
        });
        modalRef.componentInstance.router = router;
        modalRef.componentInstance.title = "actions.detailsRouter";
      }
    }
  }
  sessionTimeOut(sessionTimeOut: SessionTimeOut | null){
    const key = Object.keys(SessionTimeOutToShow).find(
      (key) => SessionTimeOut[key as keyof typeof SessionTimeOut] === sessionTimeOut
    );
    return SessionTimeOutToShow[key as SessionTimeOut];
  }
}
