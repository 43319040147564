import { createAction, props } from "@ngrx/store";
import { AdDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-details.interface";
import { FilterListAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { ResultFilterAdvertisingCompaign } from "src/app/shared/models/advertising-campaigns/result-filter-advertising-compaign.interface";
import { CampaignStatusEnum } from "src/app/shared/enum/campaign-status";
import { SuccessDto } from "src/app/shared/models/success-dto";
import { AdPaymentDetailsInterface } from "src/app/shared/models/advertising-campaigns/ad-payment-details.interface";

import { AdsPublisherDetailsAndStatus } from "src/app/shared/models/advertising-campaigns/ads-publisher-details-and-status.interface";
import { AdvertisingCampaignInterface, CreateAdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { BestAdvertisingCampaign } from "src/app/shared/models/advertising-campaigns/best-advertising-campaign.interface";
import { FilterCalendar } from "src/app/shared/models/advertising-campaigns/filter-calendar.interface";

export const invokeLatestAdvertisingCampaigns = createAction(
  "[Invoke Advertising Campaign] Invoke advertising campaigns",
);
export const setAdvertisingCampaign = createAction(
  "[Set Advertising Campaign] Set advertising campaigns",
  props<{ advertisingCampaign: AdvertisingCampaignInterface[] | null }>()
);

export const invokeAddAdvertisingCampaign = createAction(
  "[ Invoke Add Advertising Campaign ] Invoke add advertising campaign",
  props<{addCampaign: CreateAdvertisingCampaignInterface}>()
);
export const SetAddAdvertisingCampaign = createAction(
  "[ Add Advertising Campaign ] Add advertising campaign",
  props<{adCreated: SuccessDto | null}>()
);
export const invokeAdDetails = createAction(
  "[ invoke Ad Details ] invoke add details",
  props<{invokeAdDetails: AdDetailsInterface}>()
);
export const invokeAdvertisingCompaignFilter = createAction(
  "[ Invoke Advertising compaign Filter ] Invoke advertising Compaign filter",
  props<{filter: FilterListAdvertisingCompaign , refreshCache : boolean}>()
);

export const advertisingCompaignResult = createAction(
  "[ Filter Advertising Compaign ] Filter advertising compaign ",
  props<{listAdvertisingCompaign: ResultFilterAdvertisingCompaign | null}>()
);
export const invokeAdvertisingCompaignByHashedId = createAction(
  "[ Invoke Get Advertising Compaign By campaign hashed id ] Invoke get advertising compaign by campaign hashed id",
  props<{campaignHashedId: string | null}>()
)
export const getAdvertisingCompaignByHashedId = createAction(
  "[ Get Get Advertising Compaign By campaign hashed id] Get advertising compaign by campaign hashed id",
  props<{resultCampaignByHashedId: AdvertisingCampaignInterface | null}>()
)
export const invokeUpdateAdCampaignDetails = createAction(
  "[ Invoke Update Ad Campaign details ] Invoke Update advertising campaign details",
  props<{campaignHashedId: string, adCompaign: AdvertisingCampaignInterface | null}>()
)
export const updateAdCampaignDetails = createAction(
  "[ Update Ad Campaign ] update advertising campaign details",
  props<{successMessage: SuccessDto | null}>()
)
export const invokeUpdateAdCampaignStatus = createAction(
  "[ Invoke Update Ad Campaign Status ] Invoke Update Ad Campaign Status",
  props<{campaignHashedId: string | null,status: CampaignStatusEnum | null}>()
)
export const upadateAdCampaignStatus = createAction(
  "[ Update Ad Campaign Status ] Update Ad Campaign Status",
  props<{successMessage: SuccessDto | null}>()
)


export const invokeUpadateAdPaymentDetails = createAction(
  "[ Invoke Update Ad Campaign Payment Details ] Invoke Update Ad Campaign Payment Details",
  props<{payment: AdPaymentDetailsInterface }>()
)


export const invokeDeleteAdCampaign = createAction(
  "[ Invoke Delete Ad Campaign ] Invoke Delete Ad Campaign ",
  props<{campaignHashedId: string}>()
)
export const deleteAdCampaign = createAction(
  "[ Delete Ad Campaign ] Delete Ad Campaign ",
  props<{successMessage: SuccessDto | null}>()
)
export const invokeBestAdvertisingCampaign = createAction(
  "[ Invoke The best Advertising Campaign Result ] Invoke best advertising campaign",
  props<{isRefreshCache: boolean}>()
)
export const bestAdvertisingCampaign = createAction(
  "[ Get The Best Advertising Campaign Result ]Get the best advertising campaign result ",
  props<{adCompaign : BestAdvertisingCampaign | null}>()
)

export const resultAdsByPublisher = createAction(
  "[ Result Ads By Publisher ] Result ads by publisher",
  props<{advertisingCampaign: AdvertisingCampaignInterface[] | null}>()
)

export const invokeAllAdsByStatusAndPublisher = createAction(
  "[ Invoke All Ads By Status And Publisher ] Invoke all ads by status and publisher ",
  props<{filterCalendar : FilterCalendar}>()
)
export const resultAllAdsByStatusAndPublisher = createAction(
  "[ Result all ads By Status And Publisher Role ] Result all ads by status and publisher role",
  props<{advertisingCampaignDto : AdvertisingCampaignInterface[] | null}>()
)
export const invokeAddSynthesisReport = createAction(
  "[ Invoke add synthesis report ] invoke synthesis report ",
  props<{campaignHashedId: string, synthesisReport : string | null}>()
)
export const resultAddSynthesisReport = createAction(
  "[ Result add synthesis report ] result add synthesis report",
  props<{successMessage: SuccessDto | null}>()
)

export const invokeChangeAdStatus = createAction(
  "[ Invoke Change Advertising Campaign Status ] Invoke Change Advertising Campaign Status ",
  props<{campaignHashedId : string , status : string}>()
)
export const resultSuccessAd = createAction(
  "[ Result change Advertising Campaign Status ] Result Advertising Campaign Status",
  props<{successMessage: SuccessDto | null}>()
)

export const invokeStatusesByListAds = createAction(
  "[ Invoke statuses By Ad Titles ] Invoke statuses By Ad Titles",
  props<{campaignsHashedIds : string[]}>()
)
export const resultStatusesByListAds = createAction(
  "[ Result Statuses By List Ads ] Result statuses by list ads",
  props<{publisherAndStatusDetails : AdsPublisherDetailsAndStatus | null}>()
)

export const invokeCheckAdvertisingCampaignExistByTitle = createAction(
  "[ Invoke Advertising Campaign Exists By Title] Invoke advertising campaign exists by title",
  props<{title : string}>()
)
export const resultCheckAdvertisingCampaignExistByTitle = createAction(
  "[ Result Check Advertising Campaign Exists By Title ] Result check advertising campaign exists by title",
  props<{isExist : boolean | null}>()
)

export const invokeUploadJustificationFile = createAction(
  "[Invoke Upload Justification File ] Invoke upload justification file",
  props<{campaignHashedId : string, justificationFile : string | null}>()
)
export const resultUploadJustificationFile = createAction(
  "[ Result Upload Justification File ] Result upload justification file",
  props<{successUpload : SuccessDto | null}>()
)

export const invokeGetAdvertiserAdvetisingCampaigns = createAction(
  "[Invoke Get Advertiser AdvetisingCampaigns ] Invoke Get Advertiser AdvetisingCampaigns",
  props<{email : string}>()
)
export const resultAdvertiserAdvetisingCampaigns = createAction(
  "[ Result Advertiser AdvetisingCampaigns ] Result Advertiser AdvetisingCampaigns",
  props<{advertisingCamapigns : string[] | null}>()
)