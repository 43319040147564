import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { CreateUpdateZoneInterface, ZoneInterface } from "../models/zone/zone";
import { SuccessDto } from "../models/success-dto";
import { FilterUserHistoryInterface } from "../models/user-history/filter-user-history.interface";
import { ResultUserHistoryInterface } from "../models/user-history/result-user-history.interface";

@Injectable({
  providedIn: "root",
})
export class UsersHistoryService {
  constructor(private http: HttpClient) {}

  getUsersHistoryByFilter(
    filter: FilterUserHistoryInterface | null = null,
    refreshCache: boolean
  ): Observable<ResultUserHistoryInterface> {
    return this.http.post<ResultUserHistoryInterface>(
      `${
        environment.APP_PORT + Constants.HISTORY_END_POINT
      }/refreshCache/${refreshCache}/filter`,
      filter
    );
  }
}
