import { createReducer, on } from "@ngrx/store";
import { LocationPartnerStateInterface } from "./location-partnerState.interface";
import { ObjectiveTypeEnum } from "src/app/shared/enum/objectives";
import { LocationSearchMethod } from "src/app/shared/enum/locationSearchMethod";
import {
  resultMainStaticsForLocationPartner,
  invokeLocationPartnerReview,
  resultLocationPartnerReview,
} from "./actions/get-impressions.actions";
import {
  invokeLocationPartnerFilter,
  getLocationPartnersSuccess,
  setLocationPartnersCompanyNames,
  invokeFilterLocationsToCreateCampaign,
  locationPartnersToCreateCampaignSuccess,
  locationPartnerByAdTitle,
  setLocationPartnersByCompanyNames,
  resultLPInfo,
  invokeLocationPartnerImpressions,
  invokeLocationPartnerImpressionsFilter,
  setLocationPartnerImpressions,
} from "./actions/get-locations.actions";
import {
  updateLocationPartnerSuccess,
  updateLocationPartnerAccountSuccess,
  resultCalculateAndUpdateViewsAverage,
  invokeCalculateAndUpdateViewAverage,
  invokeUpdateViewsAverageManually,
  resultUpdateViewsAverage,
} from "./actions/update.actions";
import {
  calculateTotalLPCostByCampanyNames,
  createLocationPartnerSuccess,
  invokePricingByCompanyName,
  setPricingByCompanyName,
  resultLocationPartnerByRegion,
  selectedLPs,
  deleteLocationPartnerSuccess,
  resultGrantPremiumAccessForLP,
  invokeGrantPremiumAccessForLP,
  resultSuccess,
  resultCalculateViewsOrUniqueViewsBasedOnNotPaidAds,
  resultLocationPartnerAdvertisingCampignFilter,
  invokeLocationPartnerAdvertisingCampignFilter,
  invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds,
  invokeCalculateTotalLPCostByCampanyNames,
  saveSelectedLocationsForMap,
} from "./location-partner.action";

export const initialState: Readonly<LocationPartnerStateInterface> = {
  mainDashboard: null,
  loading: true,
  companyNames: [],
  locationPartnersToCreateCampaignResult: [],
  locationPartnersResult: {
    count: 0,
    locationPartners: [],
  },
  locationPartnerFilter: {
    zoneNames: [],
    companyName: null,
    searchCompanyName : null,
    companyNames: [],
    regions: [],
    showingAdmin: null,
    showingAdvertiser: null,
    partnerStatus: null,
    startDate: null,
    endDate: null,
    tags: [],
    subcategories: [],
    sortName: null,
    isAscending: true,
    pager: {
      pageNumber: 1,
      pageSize: 10,
    },
    isPaid : null
  },
  locationPartnersByCompanyNames: null,
  allLocationPartners: null,
  successMessage: null,
  locationPartnersToCreateCampaignFilter: {
    locationsSearchMethod: LocationSearchMethod.LOCATION_PARTNER,
    regions: [],
    zones: [],
    subCategories: [],
    tags: [],
  },
  calculateCost: {
    calculationMethod: LocationSearchMethod.LOCATION_PARTNER,
    names: [],
    objectiveName: ObjectiveTypeEnum.BRAND_AWARENESS_AND_REACH,
    startDate: null,
    endDate: null,
  },
  totalCostWithDiscount: {
    discount: 100,
    totalCostPerDay: [],
  },
  locationPartner: null,
  resultMainStaticsForLP: null,
  pricingByCompanyName: null,
  lpDetails: null,
  lpInfo: null,
  lastWeekReviewLP: null,
  lps: null,
  impressionsFilter: {
    regionNames: [],
    companyName: null,
    zoneNames: [],
    pager: {
      pageNumber: 1,
      pageSize: 10,
    },
  },
  impressionsResult: {
    count: 0,
    locations: [],
  },
  selectedLps: null,
  averageViewsUpdateResult: null,
  grantPremiumAccess : null,
  views : 0 ,
  advertisingCampaignsPaymentLP : null,
  loadingLocationCampaigns : true,
  loadingViews : false,
  locations : null
};

export const LocationPartnerReducer = createReducer(
  initialState,
  on(invokeLocationPartnerFilter, (state, { locationPartnerFilter }) => ({
    ...state,
    locationPartnerFilter: locationPartnerFilter,
    loading: true,
  })),
  on(getLocationPartnersSuccess, (state, { locationPartnersResult }) => ({
    ...state,
    locationPartnersResult: locationPartnersResult,
    loading: false,
  })),
  on(setLocationPartnersCompanyNames, (state, { companyNames }) => ({
    ...state,
    companyNames: companyNames,
    loading: false,
  })),
  //Filter location partners to create advertising campaign
  on(
    invokeFilterLocationsToCreateCampaign,
    (
      state,
      {
        filterLocationPartnersToCreateCampaign:
          locationPartnersToCreateCampaign,
      }
    ) => ({
      ...state,
      locationPartnersToCreateCampaignFilter: locationPartnersToCreateCampaign,
      loading: false,
    })
  ),
  on(
    locationPartnersToCreateCampaignSuccess,
    (state, { locationPartnersToCreateCampaign }) => ({
      ...state,
      locationPartnersToCreateCampaignResult: locationPartnersToCreateCampaign,
      loading: false,
    })
  ),
  on(invokeCalculateTotalLPCostByCampanyNames, (state, {calculateCost})=>({
    ...state,
    loadingViews : true
  })),
  on(calculateTotalLPCostByCampanyNames,(state, { totalCostWithDiscount }) => ({
      ...state,
      totalCostWithDiscount: totalCostWithDiscount,
      loadingViews : false,
    })
  ),
  on(createLocationPartnerSuccess, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(updateLocationPartnerSuccess, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(locationPartnerByAdTitle, (state, { allLocationPartners }) => ({
    ...state,
    allLocationPartners: allLocationPartners,
  })),
  on(setLocationPartnersByCompanyNames, (state, { locationPartners }) => ({
    ...state,
    locationPartnersByCompanyNames: locationPartners,
  })),
  on(
    resultMainStaticsForLocationPartner,
    (state, { resultMainStaticsForLP }) => ({
      ...state,
      resultMainStaticsForLP: resultMainStaticsForLP,
    })
  ),
  on(invokePricingByCompanyName, (state, { companyName }) => ({
    ...state,
    loading: true,
  })),
  on(setPricingByCompanyName, (state, { pricing }) => ({
    ...state,
    pricingByCompanyName: pricing,
    loading: false,
  })),
  /* on(invokeLPdetails, (state, { lpDetails }) => ({
    ...state,
    lpDetails: lpDetails,
  })), */
  on(resultLPInfo, (state, { lpInfo }) => ({
    ...state,
    lpInfo: lpInfo,
    loading: false,
  })),
  on(invokeLocationPartnerReview, (state) => ({
    ...state,
    loading: true,
  })),
  on(resultLocationPartnerReview, (state, { lastWeekReviewLP }) => ({
    ...state,
    lastWeekReviewLP: lastWeekReviewLP,
    loading: false,
  })),
  on(updateLocationPartnerAccountSuccess, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),

  on(invokeLocationPartnerImpressions, (state) => ({
    ...state,
    loading: true,
  })),
  on(resultLocationPartnerByRegion, (state, { lps }) => ({
    ...state,
    lps: lps,
    loading: false,
  })),
  on(invokeLocationPartnerImpressionsFilter, (state, { filter }) => ({
    ...state,
    impressionsFilter: filter,
  })),
  on(setLocationPartnerImpressions, (state, { result }) => ({
    ...state,
    loading: false,
    impressionsResult: result,
  })),
  on(updateLocationPartnerAccountSuccess, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(selectedLPs, (state, { selectedLps }) => ({
    ...state,
    selectedLps: selectedLps,
  })),
  //Impressions
  on(invokeCalculateAndUpdateViewAverage , (state , {calculateAverageViews, sendMail})=>({
    ...state,
    loadingViews : true
  })),
  on(resultCalculateAndUpdateViewsAverage, (state, { successMessage }) => ({
    ...state,
    loadingViews : false,
    averageViewsUpdateResult: successMessage,
  })),
  on(deleteLocationPartnerSuccess, (state, { successMessage }) => ({
    ...state,
    successMessage: successMessage,
  })),
  on(invokeGrantPremiumAccessForLP, (state)=>({
    ...state,
    loading : true
  })),
  on(resultGrantPremiumAccessForLP, (state, {successMessage})=>({
    ...state,
    successMessage : successMessage,
    loading : false
  })),
  on(resultSuccess, (state,{successMessage}) =>({
    ...state,
    successMessage : successMessage,
  })),
  on(invokeCalculateViewsOrUniqueViewsBasedOnNotPaidAds, (state)=>({
    ...state,
    loadingViews : true
  })),
  on(resultCalculateViewsOrUniqueViewsBasedOnNotPaidAds, (state, {views})=>({
    ...state,
    views : views,
    loadingViews : false
  })),
  on(invokeLocationPartnerAdvertisingCampignFilter, (state)=>({
    ...state,
    loadingLocationCampaigns : true
  })),
  on(resultLocationPartnerAdvertisingCampignFilter, (state , {advertisingCampaignsPaymentLP})=>({
    ...state,
    advertisingCampaignsPaymentLP : advertisingCampaignsPaymentLP,
    loadingLocationCampaigns : false
  })),
  on(saveSelectedLocationsForMap, (state, { locations }) => ({
    ...state,
    locations: locations,
  })),

  on(invokeUpdateViewsAverageManually , (state , {updateViewsAverage})=>({
    ...state,
    loadingViews : true
  })),
  on(resultUpdateViewsAverage, (state, { successMessage }) => ({
    ...state,
    loadingViews : false,
    successMessage: successMessage,
  })),
);
