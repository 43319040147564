import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const statisticsState = (state: AppStateInterface) =>
  state.statisticsState;

export const summaryStatisticsSelector = createSelector(
  statisticsState,
  (state) => state.summaryStatistics
);
export const loadingSummaryStatisticsSelector = createSelector(
  statisticsState,
  (state) => state.loadingSummaryStatistics
);
export const viewByAgeForAdSelector = createSelector(
  statisticsState,
  (state) => state.ageStatistics
);
export const viewByGenderForAdSelector = createSelector(
  statisticsState,
  (state) => state.gender
);

export const genderAgeStatisticsForAdSelector = createSelector(
  statisticsState,
  (state) => state.genderAgeStatistics
);
export const uniqueViewsByGenderAndAgeSelector = createSelector(
  statisticsState,
  (state) => state.genderAgeStatistics
);

export const globalStatisticsForAdSelector = createSelector(
  statisticsState,
  (state) => state.globalStatistics
);

export const resultGlobalStatisticsForLPSelector = createSelector(
  statisticsState,
  (state) => state.resultGlobalStatisticsForLP
);

export const loadingGlobalStaticsSelector = createSelector(
  statisticsState,
  (state) => state.loadingGlobalStatics
);

export const resultUniqueConsumersSelector = createSelector(
  statisticsState,
  (state) => state.uniqueConsommers
);

export const loadingConsumersSelector = createSelector(
  statisticsState,
  (state) => state.loadingConsumers
);

export const resultViewsGenderAgeStaticsForLPSelector = createSelector(
  statisticsState,
  (state) => state.genderAgeStatistics
);

export const resultViewsGenderAgeStaticsForLPForMapSelector = createSelector(
  statisticsState,
  (state) => state.genderAgeStatisticsForMap
);
export const resultCompletedByGenderDemographicForLocationPartnerSelector =
  createSelector(statisticsState, (state) => state.genderAgeStatistics);

export const resultUniqueViewsByGenderDemographicForLocationPartnerSelector =
  createSelector(statisticsState, (state) => state.genderAgeStatistics);

export const viewByGenderForLPSelector = createSelector(
  statisticsState,
  (state) => state.gender
);

export const listUniqueViewsSelector = createSelector(
  statisticsState,
  (state) => state.listUniqueViews
);

export const resultRetentionRateByWeeksSelectors = createSelector(
  statisticsState,
  (state) => state.retentionRate
);

export const resutlRetainedConsumersSelector = createSelector(
  statisticsState,
  (state) => state.consumers
);
export const resultRetentionTimeSelector = createSelector(
  statisticsState,
  (state) => state.retentionTime
);
export const loadingresultRetentionTimeSelector = createSelector(
  statisticsState,
  (state) => state.loadingRetentionTime
);
export const loadingRetainedConsumersSelector = createSelector(
  statisticsState,
  (state) => state.loadingRetainedConsumer
);
export const loadingStatisticsSelector = createSelector(
  statisticsState,
  (state) => state.loading
);
export const loadingByAgeRangeAndGenderByAdSelector = createSelector(
  statisticsState,
  (state) => state.loadingByAgeRangeAndGenderByAd
)