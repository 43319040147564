import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, takeUntil } from "rxjs";
import {
  invokeApiDistroyed,
  invokeComponentDistroyed,
  setAPIStatus,
} from "../apiState.interface";
import { SummaryStatics } from "src/app/shared/models/statistics/summary-statics";
import {
  completedByAgeRangeAndGenderForAd,
  getUniqueViewsByListAds,
  getViewsByGenderForAd,
  globalStatisticsByAdHashedId,
  invokeCompletedByAgeRangeAndGenderForAd,
  invokeCompletedByGenderDemographicForLocationPartner,
  invokeGlobalStatisticsByAdHashedId,
  invokeGlobalStatisticsForLP,
  invokeRedirectionByAgeAndGenderForAd,
  invokeAllSummaryChartInDateBetween,
  invokeSummaryStaticsInDateBetweenForLP,
  invokeSummaryChartInDayForAd,
  invokeSummaryStaticsInDateBetweenForAd,
  invokeUniqueConsumers,
  invokeUniqueViewsByGenderAndAgeForAd,
  invokeUniqueViewsByGenderDemographicForLP,
  invokeUniqueViewsByListAds,
  invokeViewsByAgeRangeAndGenderByAd,
  invokeViewsByGenderDemographicForLocationPartner,
  InvokeViewsByGenderForAd,
  redirectionByAgeAndGenderForAd,
  resultCompletedByGenderDemographicForLocationPartner,
  resultGlobalStatisticsForLP,
  resultUniqueConsumers,
  resultUniqueViewsByGenderDemographicForLocationPartner,
  resultViewsByGenderDemographicForLocationPartner,
  uniqueViewsByGenderAndAgeForAd,
  viewsByAgeRangeAndGenderByAd,
  invokeRetentionRateByWeeks,
  resultRetentionRateByWeeks,
  resultSummaryStatics,
  invokeRetainedConsumers,
  resultRetainedConsumers,
  invokeRetentionTime,
  resultRetentionTime,
  invokeUniqueViewsByAdTitleForLP,
  resultConsumersByAgeAndGenderForLP,
  invokeSummaryChartInDayForLP,
  invokeSummaryChartInDay,
  invokeConsumersByAgeAndGenderForLP,
} from "./statistics.actions";
import { AdminStatisticsService } from "src/app/shared/services/statistics/statistics-admin.service";
import { GenderStatistics } from "src/app/shared/models/statistics/gender-statistics";
import { GenderAgeStatistics } from "src/app/shared/models/statistics/gender-age-statistics";
import { GlobalStatistics } from "src/app/shared/models/statistics/global-statistics";
import { ResultGlobalStatisticsForLP } from "src/app/shared/models/statistics/resultGlobalStaticsForLP";
import { RetentionRate } from "src/app/shared/models/statistics/retentionRate";

@Injectable()
export class StatisticsEffects {
  constructor(
    private actions$: Actions,
    private adminStatisticsService: AdminStatisticsService
  ) {}

  invokeSummaryStaticsBetweenDatesForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSummaryStaticsInDateBetweenForLP),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getSummaryChartBetweenDatesForLP(state.companyName, state.dateFilter, state.refreshCache)
          .pipe(
            map((data: SummaryStatics | null) =>
              resultSummaryStatics({ summaryStatistic: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );
  //start main admin
  invokeSummaryStatisticInDateBetween$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeAllSummaryChartInDateBetween),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getSummaryStaticsInDateBetween(state.dateFilter, state.refreshCache)
          .pipe(
            map((data: SummaryStatics | null) =>
              resultSummaryStatics({ summaryStatistic: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );
  //end main admin
  invokeSummaryStatisticInDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSummaryChartInDay),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getSummaryStaticsInDay(state.day)
          .pipe(
            map((data: SummaryStatics | null) =>
              resultSummaryStatics({ summaryStatistic: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );
  invokeSummarySaticsInDateBetweenForAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSummaryStaticsInDateBetweenForAd),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getSummaryStaticsInDateBetweenForAd(
            state.campaignHashedId,
            state.refreshCache,
            state.filter
          )
          .pipe(
            map((data: SummaryStatics | null) =>
              resultSummaryStatics({ summaryStatistic: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );

  invokeSummaryStatisticInDayForAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSummaryChartInDayForAd),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getSummaryStaticsInDayForAd(
            state.campaignHashedId,
            state.isFinished,
            state.day,
            state.locationPartner
          )
          .pipe(
            map((data: SummaryStatics | null) =>
              resultSummaryStatics({ summaryStatistic: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );

  invokeViewsByGenderForAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvokeViewsByGenderForAd),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getViewsByGenderForAd(state.campaignHashedId)
          .pipe(
            map((data: GenderStatistics | null) =>
              getViewsByGenderForAd({ gender: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeViewsByAgeAndGenderByAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeViewsByAgeRangeAndGenderByAd),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getViewsByAgeAndRangeForAd(
            state.filter,
            state.campaignHashedId,
            state.refreshCache
          )
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              viewsByAgeRangeAndGenderByAd({ genderAgeStatistics: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeRedirectionByAgeAndGenderByAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeRedirectionByAgeAndGenderForAd),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getRedirectionByAgeAndGenderForAd(state.campaignHashedId)
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              redirectionByAgeAndGenderForAd({ genderAgeStatistics: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeUniqueViewsByAgeAndGenderByAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUniqueViewsByGenderAndAgeForAd),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getUniqueViewsByAgeAndGenderForAd(
            state.campaignHashedId,
            state.filter
          )
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              uniqueViewsByGenderAndAgeForAd({ genderAgeStatistics: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeGlobalStatisticsForAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGlobalStatisticsByAdHashedId),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getGlobalStatisticsForAd(
            state.campaignHashedId,
            state.refreshCache,
            state.finished,
            state.locationPartner
          )
          .pipe(
            map((data: GlobalStatistics | null) =>
              globalStatisticsByAdHashedId({ globalStatistics: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokecompletedByAgeRangeAndGenderForAd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCompletedByAgeRangeAndGenderForAd),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getcompletedByAgeRangeAndGenderForAd(
            state.campaignHashedId,
            state.filter
          )
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              completedByAgeRangeAndGenderForAd({ genderAgeStatistics: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeGlobalStaticsForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeGlobalStatisticsForLP),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getGlobalStaticsForLP(state.companyName, state.refreshCache)
          .pipe(
            map((data: ResultGlobalStatisticsForLP | null) =>
              resultGlobalStatisticsForLP({ resultGlobalStatisticsForLP: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeUniqueConsummersByLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUniqueConsumers),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getUniqueConsumersByLP(state.email, state.isRefreshCache)
          .pipe(
            map((data: number) =>
              resultUniqueConsumers({ uniqueConsommers: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeConsumersByAgeAndGenderForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeConsumersByAgeAndGenderForLP),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getConsumersByGenderDemographicForLPAndAdTitle(state.companyName)
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              resultConsumersByAgeAndGenderForLP({
                genderAgeStatistics: data,
              })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeViewsByAgeAndGenderForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeViewsByGenderDemographicForLocationPartner),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getViewsByGenderDemographicForLP(state.companyName, state.dateFilter, state.refreshCache)
          .pipe(
            mergeMap((data: GenderAgeStatistics | null) => {
              if (state.forMap) {
                return of(
                  resultConsumersByAgeAndGenderForLP({
                    genderAgeStatistics: data,
                  })
                );
              } else {
                return of(
                  resultViewsByGenderDemographicForLocationPartner({
                    genderAgeStatistics: data,
                  })
                );
              }
            }),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeCompletedByAgeAndGenderForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeCompletedByGenderDemographicForLocationPartner),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getCompletedByGenderDemographicForLP(
            state.companyName,
            state.dateFilter
          )
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              resultCompletedByGenderDemographicForLocationPartner({
                genderAgeStatistics: data,
              })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeUniqueViewsByAgeAndGenderForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUniqueViewsByGenderDemographicForLP),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getUniqueViewsByGenderDemographicForLP(
            state.companyName,
            state.dateFilter
          )
          .pipe(
            map((data: GenderAgeStatistics | null) =>
              resultUniqueViewsByGenderDemographicForLocationPartner({
                genderAgeStatistics: data,
              })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeUniqueViewsByListAds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUniqueViewsByListAds),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getUniqueViewsByListAds(state.titles)
          .pipe(
            map((data: number[]) =>
              getUniqueViewsByListAds({ uniqueViews: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeRetentionRateByWeeks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeRetentionRateByWeeks),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getRetentionRate(state.companyName, state.dateFilter, state.refreshCache)
          .pipe(
            map((data: RetentionRate | null) =>
              resultRetentionRateByWeeks({
                retentionRate: data,
              })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed))),
            takeUntil(this.actions$.pipe(ofType(invokeApiDistroyed)))
          );
      })
    )
  );

  invokeRetainedConsumers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeRetainedConsumers),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getRetainedConsumers(state.email, state.refreshCache)
          .pipe(
            map((data: number) => resultRetainedConsumers({ consumers: data })),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
  invokeRetentionTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeRetentionTime),
      mergeMap((state) => {
        return this.adminStatisticsService.getRetentionTime(state.email, state.refreshCache).pipe(
          map((data: number[] | null) =>
            resultRetentionTime({ retentionTime: data })
          ),
          catchError((result) =>
            of(
              setAPIStatus({
                apiStatus: {
                  apiResponseMessage: result.error,
                  apiStatus: "error",
                },
              })
            )
          ),
          takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
        );
      })
    )
  );

  invokeUniqueViewsByListAdsForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeUniqueViewsByAdTitleForLP),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getUniqueViewsByListAdsForLP(state.titles)
          .pipe(
            map((data: number[]) =>
              getUniqueViewsByListAds({ uniqueViews: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );

  invokeSummaryStatisticInDayForLP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invokeSummaryChartInDayForLP),
      mergeMap((state) => {
        return this.adminStatisticsService
          .getSummaryStaticsInDayForLP(state.companyName, state.day)
          .pipe(
            map((data: SummaryStatics | null) =>
              resultSummaryStatics({ summaryStatistic: data })
            ),
            catchError((result) =>
              of(
                setAPIStatus({
                  apiStatus: {
                    apiResponseMessage: result.error,
                    apiStatus: "error",
                  },
                })
              )
            ),
            takeUntil(this.actions$.pipe(ofType(invokeComponentDistroyed)))
          );
      })
    )
  );
}
