import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../data/constants";
import { ConsumerCountByDate } from "../models/consumers/consumerCountByDate";
import { DateFilter } from "../models/date-filte.interface";
import { AgeStatistics } from "../models/statistics/age-statistics";
import { GenderAgeStatistics } from "../models/statistics/gender-age-statistics";
import { GenderStatistics } from "../models/statistics/gender-statistics";
import { BrowserFamily } from "../models/statistics/browserFamily";
import { DeviceType } from "../models/statistics/deviceType";
import { TechnicalCardSearchMethod } from "../models/statistics/technical-data";
import { OsFamily } from "../models/statistics/osFamily-statics";

@Injectable({
  providedIn: "root",
})
export class ConsumersService {
  constructor(private http: HttpClient) {}

  /*  getConsumersCountByDate(): Observable<ConsumerCountByDate>{
    return this.http.get<ConsumerCountByDate>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/get/all/date`
    )
  } */
  getOsFamily(
    search: TechnicalCardSearchMethod | null,
    refreshCache: boolean
  ): Observable<OsFamily> {
    return this.http.post<OsFamily>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refreshCache}/operatingSystem`,
      search
    );
  }
  getBrowserFamily(
    search: TechnicalCardSearchMethod | null,
    refreshCache: boolean
  ): Observable<BrowserFamily> {
    return this.http.post<BrowserFamily>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refreshCache}/browserFamily`,
      search
    );
  }
  getDeviceType(
    search: TechnicalCardSearchMethod | null,
    refreshCache: boolean
  ): Observable<DeviceType> {
    return this.http.post<DeviceType>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refreshCache}/deviceType`,
      search
    );
  }
  getDeviceBrand(
    search: TechnicalCardSearchMethod | null,
    refreshCache: boolean
  ): Observable<Map<string, number>> {
    return this.http.post<Map<string, number>>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refreshCache}/deviceBrand`,
      search
    );
  }
  getConsumerCountBetweenDates(
    dateFilter: DateFilter,
    refreshCache: boolean
  ): Observable<ConsumerCountByDate> {
    return this.http.post<ConsumerCountByDate>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refreshCache}/allStaticsInDateBetween`,
      dateFilter
    );
  }

  getConsumersGenderDetails(refresh: boolean): Observable<GenderStatistics> {
    return this.http.get<GenderStatistics>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refresh}/get/all/gender`
    );
  }
  getConsumerDemographicDetails(
    dateFilter: DateFilter,
    refreshCache: boolean
  ): Observable<GenderAgeStatistics> {
    return this.http.post<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refreshCache}/get/all/genderDemographic`,
      dateFilter
    );
  }

  getConsumerAgeRangeDetails(refreshCache: boolean): Observable<AgeStatistics> {
    return this.http.get<AgeStatistics>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/refreshCache/${refreshCache}/get/all/ageRange`
    );
  }

  getConsumersCountByDay(day: string | null): Observable<ConsumerCountByDate> {
    return this.http.get<ConsumerCountByDate>(
      `${
        environment.APP_PORT + Constants.CONSUMER_ENDPOINT
      }/get/allStatics/consumers/inDay/${day}`
    );
  }
}
