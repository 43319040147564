export const environment = {
  production: true,
  name : "production",
  APP_PORT: "https://api.relead.tn/api",
  CDN_RELEAD: "https://cdn.relead.tn/",
  APP_PORT_IBERIS: "https://finances.iberis.io/",
  PAYMENT: {
    RETURN_URL:
      "https://app.relead.tn/dashboard/advertiser/advertising-campaigns/payment-success",
    WEBHOOK_URL:
      "https://app.relead.tn/dashboard/advertiser/advertising-campaigns/payment-success",
    CANCEL_URL:
      "https://app.relead.tn/dashboard/advertiser/advertising-campaigns/payment-cancel",
    APP_PAYMEE: {
      URL: "https://sandbox.paymee.tn",
      TOKEN: "Token 1359866bca1be8da0f05d5a7c816e8ebe8bfd2ab",
    },
    APP_KONNECT: {
      URL: "https://api.konnect.network",
      WALLET: "65bd4cdf43e3d4fae6e5ef82",
      TOKEN: "65bd4cdf43e3d4fae6e5ef7e:QxjqdpkhlrFGPMlaw2scyio",
    },
  },
  links: {
    requestFeat : "https://forms.clickup.com/37327298/f/13k4e2-29315/1TKHAWKAR4I3P6CQP5",
    bookMeetingLP : "https://calendly.com/relead-support/30min?month=2024-02",
    //bookMeetingAdvertiser : "https://calendly.com/relead-sales/30min?month=2024-02",
    bookMeetingAdvertiser :"https://calendly.com/relead-sales/demo-relead-2-0",
    contactRelead : "https://relead.tn/contact",
    blogs: "https://relead.tn/blog/",
    termsOfUse: "https://relead.tn/conditions-generales-dutilisation/"
  },
  RECAPTCHA_PUBLIC_KEY: "6LfDMmQqAAAAAF5-oyZdpqel9KswOox8o51t0pIi",
  IBERIS:{
    IBERIS_COMPANY_ID : "qXDZ",
    PAYMENT_METHOD: "RWKWZ",
    BANK_ID : "JwNv",
    TAX_HASHED_ID :"mqDP",
    TIMBRE_FISCAL_HASHED_ID: "Ovyo",
    TOKEN : "$2y$10$RkgpSbSP7Yl6kAaruepMRe3XYnpkMPkhJFC4fJ.3Rgrw2V7/RrcKC"
  }
};