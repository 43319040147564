import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../../data/constants";
import { DateFilter } from "../../models/date-filte.interface";
import { GenderAgeStatistics } from "../../models/statistics/gender-age-statistics";
import { GenderStatistics } from "../../models/statistics/gender-statistics";
import { GlobalStatistics } from "../../models/statistics/global-statistics";
import { ResultGlobalStatisticsForLP } from "../../models/statistics/resultGlobalStaticsForLP";
import { RetentionRate } from "../../models/statistics/retentionRate";
import { SummaryStatics } from "../../models/statistics/summary-statics";
import { LocationAndDateFilter } from "../../models/LocationAndDateFilter";
import { RedisOptions } from "../../models/RedisOptions";

@Injectable({
  providedIn: "root",
})
export class AdminStatisticsService {
  constructor(private http: HttpClient) {}

  //Start Audience
  getSummaryChartBetweenDatesForLP(
    companyName: string | null,
    dateFilte: DateFilter,
    refreshCache: boolean
  ): Observable<SummaryStatics | null> {
    return this.http.post<SummaryStatics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/summaryStatics/dateBetween/companyName/` +
        companyName,
      dateFilte
    );
  }

  getSummaryStaticsInDateBetweenForAd(
    campaignHashedId: string | null,
    refreshCache: boolean,
    filter: LocationAndDateFilter
  ): Observable<SummaryStatics> {
    return this.http.post<SummaryStatics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/summaryStatics/dateBetween/adTitle/${campaignHashedId}`,
      filter
    );
  }

  /*  getSummaryStaticsForLP(locationEmail : string): Observable<SummaryStatics | null>{
    return this.http.get<SummaryStatics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/summaryStatics/locationEmail/`+locationEmail
    )
  } */

  //End Audience
  getSummaryStaticsInDateBetween(
    dateFilter: DateFilter,
    refreshCache: boolean
  ): Observable<SummaryStatics> {
    return this.http.post<SummaryStatics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/summaryStatics/allStaticsInDateBetween`,
      dateFilter
    );
  }
  getSummaryStaticsInDay(day: string | null): Observable<SummaryStatics> {
    return this.http.get<SummaryStatics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/summaryStatics/inDay/${day}`
    );
  }
  getViewsByGenderForAd(
    campaignHashedId: string
  ): Observable<GenderStatistics> {
    return this.http.get<GenderStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/views/gender/ad/${campaignHashedId}`
    );
  }

  getViewsByAgeAndRangeForAd(
    filter: LocationAndDateFilter,
    campaignHashedId: string | null,
    refreshCache: boolean
  ): Observable<GenderAgeStatistics> {
    return this.http.post<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/views/ageRangeAndGender/${campaignHashedId}`,
      filter
    );
  }
  getRedirectionByAgeAndGenderForAd(
    campaignHashedId: string | null
  ): Observable<GenderAgeStatistics> {
    return this.http.get<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/redirection/ageRangeAndGender/${campaignHashedId}`
    );
  }
  getUniqueViewsByAgeAndGenderForAd(
    campaignHashedId: string | null,
    filter: LocationAndDateFilter
  ): Observable<GenderAgeStatistics> {
    return this.http.post<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/uniqueViews/ageRangeAndGender/${campaignHashedId}`,
      filter
    );
  }

  getcompletedByAgeRangeAndGenderForAd(
    campaignHashedId: string | null,
    filter: LocationAndDateFilter
  ): Observable<GenderAgeStatistics> {
    return this.http.post<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/completed/ageRangeAndGender/${campaignHashedId}`,
      filter
    );
  }

  getConsumersByGenderDemographicForLPAndAdTitle(
    companyName: string
  ): Observable<GenderAgeStatistics | null> {
    return this.http.get<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/consumers/genderDemographic/${companyName}`
    );
  }

  getViewsByGenderDemographicForLP(
    companyName: string,
    dateFilte: DateFilter,
    refreshCache: boolean
  ): Observable<GenderAgeStatistics | null> {
    return this.http.post<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/views/genderDemographic/` + companyName,
      dateFilte
    );
  }
  getUniqueViewsByGenderDemographicForLP(
    companyName: string,
    dateFilte: DateFilter
  ): Observable<GenderAgeStatistics | null> {
    return this.http.post<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/uniqueViews/genderDemographic/` + companyName,
      dateFilte
    );
  }
  getCompletedByGenderDemographicForLP(
    companyName: string,
    dateFilte: DateFilter
  ): Observable<GenderAgeStatistics | null> {
    return this.http.post<GenderAgeStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/completed/genderDemographic/` + companyName,
      dateFilte
    );
  }

  getGlobalStatisticsForAd(
    campaignHashedId: string | null,
    refreshCache: boolean,
    finished: boolean,
    locationPartner: string
  ): Observable<GlobalStatistics> {
    return this.http.post<GlobalStatistics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/adStatus/${finished}/globalStatistics/adTitle/${campaignHashedId}`,
      locationPartner
    );
  }

  getSummaryStaticsInDayForAd(
    campaignHashedId: string | null,
    isFinished: boolean,
    day: string | null,
    locationPartner: string | null
  ): Observable<SummaryStatics> {
    return this.http.post<SummaryStatics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/summaryStatics/inDay/adTitle/${campaignHashedId}/${isFinished}/${day}`,
      locationPartner
    );
  }

  getGlobalStaticsForLP(
    companyName: string,
    refreshCache: boolean
  ): Observable<ResultGlobalStatisticsForLP | null> {
    return this.http.get<ResultGlobalStatisticsForLP>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/globalStatistics/companyName/` +
        companyName
    );
  }

  getUniqueConsumersByLP(
    email: string,
    isRefreshCache: boolean
  ): Observable<number> {
    return this.http.get<number>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/uniqueConsumers/${isRefreshCache}/email/` + email
    );
  }

  getUniqueViewsByListAds(ads: string[]): Observable<number[]> {
    return this.http.post<number[]>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/uniqueViewsByAdTitles`,
      { titles: ads }
    );
  }

  getConsumersForLP(email: string): Observable<number> {
    return this.http.get<number>(
      `${environment.APP_PORT + Constants.STATICS_ENDPOINT}/consumers/${email}`
    );
  }
  getRetentionRate(
    companyName: string,
    dateFilter: DateFilter,
    refreshCache: boolean
  ): Observable<RetentionRate | null> {
    return this.http.post<RetentionRate>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/retentionRate/week/${companyName}`,
      dateFilter
    );
  }

  getRetainedConsumers(
    email: string,
    refreshCache: boolean
  ): Observable<number> {
    return this.http.get<number>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/retainedConsumers/${email}`
    );
  }
  getRetentionTime(
    email: string,
    refreshCache: boolean
  ): Observable<number[] | null> {
    return this.http.get<number[]>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/refreshCache/${refreshCache}/retentionTime/${email}`
    );
  }
  getUniqueViewsByListAdsForLP(ads: string[]): Observable<number[]> {
    return this.http.post<number[]>(
      `${environment.APP_PORT + Constants.STATICS_ENDPOINT}/LP/uniqueViews`,
      { titles: ads }
    );
  }

  getSummaryStaticsInDayForLP(
    companyName: string | null,
    day: string | null
  ): Observable<SummaryStatics> {
    return this.http.get<SummaryStatics>(
      `${
        environment.APP_PORT + Constants.STATICS_ENDPOINT
      }/summaryStatics/inDay/companyName/${companyName}/${day}`
    );
  }
}
