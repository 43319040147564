import { Component, OnInit } from "@angular/core";
import { Observable, takeUntil } from "rxjs";
import { IColumn } from "src/app/shared/models/table/column.interface";
import { BaseComponent } from "src/app/base.component";
import { select, Store } from "@ngrx/store";
import { AppStateInterface } from "src/app/store/appState.interface";
import { LocationPartnersFilterInterface } from "src/app/shared/models/location-partners/locationPartnersFilter.interface";
import { initialState } from "src/app/store/locationPartner/location-partner.reducers";
import {
  locationPartnersResultSelector,
  pricingByCompanyNameSelector,
  resultLoadingLocationAdsSelector,
  resultLocationPartnerAdvertisingCampignFilterSelector,
  successMessageSelector,
} from "src/app/store/locationPartner/location-partner.selectors";
import { LocationPartnersResultInterface } from "src/app/shared/models/location-partners/locationPartnersResult.interface";
import { invokeFilterLocationsForPayment } from "src/app/store/locationPartner/actions/get-locations.actions";
import { AdvertisingCampaignInterface } from "src/app/shared/models/advertising-campaigns/advertising-campaign.interface";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PricingInterface } from "src/app/shared/models/location-partners/pricing";
import { PricingMethod } from "src/app/shared/enum/pricing-method";
import { PaymentFrequency } from "src/app/shared/enum/payment-frequency";
import { LocationPartnerInterface } from "src/app/shared/models/location-partners/locationPartner.interface";
import {
  ActionInterface,
  ActionReturned,
  OptionActionEnum,
  paymentLP,
} from "src/app/shared/data/actions-data";
import { UpdatePaymentStatusComponent } from "./update-payment-status/update-payment-status.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { getLocationPartnersSuccess } from "src/app/store/locationPartner/actions/get-locations.actions";
import {
  FilterInterface,
  paymentLPFilter,
} from "src/app/shared/data/filter-data";
import {
  PaymentStatus,
  PaymentStatusToShow,
} from "src/app/shared/enum/paymentStatus";
import { BadgeService } from "src/app/shared/services/badge.service";
import { AdvertisingCampaignsPaymentLP } from "src/app/shared/models/advertising-campaigns/advertising-campaigns-payment-LP.interface";
import {
  invokeLocationPartnerAdvertisingCampignFilter,
  invokePricingByCompanyName,
  resultLocationPartnerAdvertisingCampignFilter,
  resultSuccess,
} from "src/app/store/locationPartner/location-partner.action";
import { invokeApiDistroyed } from "src/app/store/apiState.interface";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { SuccessDto } from "src/app/shared/models/success-dto";
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
})
export class PaymentComponent extends BaseComponent implements OnInit {
  columns: IColumn[] = [
    {
      key: "endPilotePhase",
      label: "admin.locations.repository.endPhase",
      sorter: true,
    },
    {
      key: "pricingMethod",
      label: "admin.locations.payment.pricingMethod",
      sorter: true,
    },
    {
      key: "prix",
      label: "admin.locations.payment.price",
      sorter: true,
    },
    {
      key: "titre",
      label: "admin.locations.campaigns.campaignName",
      sorter: true,
    },
    {
      key: "startDate",
      label: "admin.locations.payment.campaignStartDate",
      sorter: true,
    },
    {
      key: "endDate",
      label: "admin.locations.payment.campaignEndDate",
      sorter: true,
    },
    {
      key: "impression",
      label: "admin.locations.payment.impressionsNumber",
      sorter: true,
    },
    {
      key: "uiqueViews",
      label: "admin.locations.payment.uniqueImpressionsNumber",
      sorter: true,
    },
    {
      key: "ht",
      label: "admin.locations.payment.totalTTC",
      sorter: true,
    },
  ];
  pageSize: number = 10;
  pageNumber: number = 1;
  locationPartnerFilter$: Observable<LocationPartnersFilterInterface>;
  locationPartnerFilter: LocationPartnersFilterInterface = {
    ...initialState.locationPartnerFilter,
    showingAdmin: true,
    showingAdvertiser: true,
    pager: {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
    },
  };
  locationPartnersResult$: Observable<LocationPartnersResultInterface | null>;
  locationPartnersResult: LocationPartnersResultInterface;
  rowSpan: number = 1;

  allCompanyNames: string[] = [];
  formFilter: FormGroup;
  pricing$: Observable<PricingInterface | null>;
  pricing: PricingInterface[] = [
    {
      price: 0,
      pricingMethod: PricingMethod.NONE,
      paymentFrequency: PaymentFrequency.NONE,
      pilotPhaseEndDate: new Date(),
    },
  ];
  count: number;
  options: ActionInterface[] = paymentLP;
  filterPayment: FilterInterface = paymentLPFilter;
  isPaid: boolean | null = null;
  pricingMethode = PricingMethod;

  filterAdsForLocationPartners$: Observable<AdvertisingCampaignsPaymentLP | null>;
  filterAdsForLocationPartners: AdvertisingCampaignsPaymentLP;
  advertsingCampaigns: AdvertisingCampaignInterface[][] = [];
  locationPartners: LocationPartnerInterface[];
  loading$: Observable<boolean | null>;
  loading: boolean | null = true;
  updatePaymentStatus$: Observable<SuccessDto | null>;
  paymentStatusSuccessfullyUpdated: string;
  constructor(
    private store: Store<AppStateInterface>,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public badgeService: BadgeService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    super(store);
    this.locationPartnersResult$ = this.store
      .pipe(select(locationPartnersResultSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.pricing$ = this.store
      .pipe(select(pricingByCompanyNameSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.filterAdsForLocationPartners$ = this.store
      .pipe(select(resultLocationPartnerAdvertisingCampignFilterSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.loading$ = this.store
      .pipe(select(resultLoadingLocationAdsSelector))
      .pipe(takeUntil(this.ngDestroyed$));

    this.updatePaymentStatus$ = this.store
      .pipe(select(successMessageSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.formFilter = this.fb.group({
      companyName: [""],
      date: [[null, null]],
    });
  }

  ngOnInit(): void {
    this.updatePaymentStatus$.subscribe((result: SuccessDto | null) => {
      if (result != null) {
        this.paymentStatusSuccessfullyUpdated = result.message;
        this.modalService.dismissAll()
        if (this.paymentStatusSuccessfullyUpdated != "") {
          this.formFilter.reset({
            companyName: "",
            date: [null, null],
          });
          this.toastr.success(
            this.translate.instant(
              "response.success." + this.paymentStatusSuccessfullyUpdated
            ),
            this.translate.instant("response.successTitle")
          );
          this.store.dispatch(
            invokeFilterLocationsForPayment({
              locationPartnerFilter: {
                ...this.locationPartnerFilter,
                pager: {
                  pageSize: this.pageSize,
                  pageNumber: this.pageNumber,
                },
              },
            })
          );
          this.store.dispatch(resultSuccess({ successMessage: null }));
        }
      }
    });
    this.filterPayment.name.map(
      (e) =>
        (e.data = Object.keys(PaymentStatus)
          .filter((value) => isNaN(Number(value)) === true)
          .map(
            (key: string | any) =>
              PaymentStatusToShow[key as keyof typeof PaymentStatus]
          ))
    );
    this.loading$.subscribe((data) => {this.loading = data});
    this.store.dispatch(
      getLocationPartnersSuccess({ locationPartnersResult: null })
    );
    this.pricing$.subscribe((pricing: PricingInterface | null) => {
      if (pricing) {
        this.pricing = [...this.pricing, pricing];
      }
    });
    this.locationPartnersResult$.subscribe(
      (result: LocationPartnersResultInterface | null) => {
        this.store.dispatch(resultLocationPartnerAdvertisingCampignFilter({advertisingCampaignsPaymentLP : null}))
        if (result) {
          this.locationPartnersResult = result;
          this.locationPartners = result.locationPartners;
          this.count = result.count;
          result.locationPartners.map((e) => {
            this.allCompanyNames = [...this.allCompanyNames, e.companyName];
          });
          this.allCompanyNames.map((e) => {
            this.store.dispatch(invokePricingByCompanyName({ companyName: e }));
          });
          if (this.allCompanyNames.length != 0) {
            this.store.dispatch(
              invokeLocationPartnerAdvertisingCampignFilter({
                locationPartnerCampaignsFilter: {
                  ...this.locationPartnerFilter,
                  searchCompanyName: this.formFilter.get("companyName")?.value?.trim() === "" ? null : this.formFilter.get("companyName")?.value,
                  companyNames: this.allCompanyNames,
                  isPaid: this.isPaid,
                  startDate: this.formFilter.get("date")?.value[0],
                  endDate: this.formFilter.get("date")?.value[1],
                },
                isWithViews: true,
                refreshCache : false
              })
            );
          }
          this.allCompanyNames = [];
        } else {
          this.store.dispatch(
            invokeFilterLocationsForPayment({
              locationPartnerFilter: {
                ...this.locationPartnerFilter,
                pager: {
                  pageSize: this.pageSize,
                  pageNumber: this.pageNumber,
                },
              },
            })
          );
        }
      }
    );
    this.filterAdsForLocationPartners$.subscribe((data) => {
      if (data) {
        this.filterAdsForLocationPartners = data;
        this.advertsingCampaigns = data.advertisingCampaigns;
      }
    });
  }
  get changePageSize() {
    return this.locationPartnerFilter.pager.pageSize;
  }
  set changePageSize(pageSize: number) {
    this.itemsPerPageChange(pageSize);
  }
  itemsPerPageChange(pageSize: number) {
    this.locationPartnerFilter = {
      ...this.locationPartnerFilter,
      searchCompanyName: this.formFilter.get("companyName")?.value?.trim() === "" ? null : this.formFilter.get("companyName")?.value,
      companyNames: this.allCompanyNames,
      isPaid: this.isPaid,
      startDate: this.formFilter.get("date")?.value[0],
      endDate: this.formFilter.get("date")?.value[1],
      pager: {
        ...this.locationPartnerFilter.pager,
        pageSize: pageSize,
      },
    }
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeFilterLocationsForPayment({
        locationPartnerFilter: {
          ...this.locationPartnerFilter
        },
      })
    );
  }
  changePage(event: number) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeFilterLocationsForPayment({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          searchCompanyName: this.formFilter.get("companyName")?.value?.trim() === "" ? null : this.formFilter.get("companyName")?.value,
          companyNames: this.allCompanyNames,
          isPaid: this.isPaid,
          startDate: this.formFilter.get("date")?.value[0],
          endDate: this.formFilter.get("date")?.value[1],
          pager: {
            ...this.locationPartnerFilter.pager,
            pageNumber: event,
          },
        },
      })
    );
  }
  columnFilterChange(event: any) {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeFilterLocationsForPayment({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          sortName: event.column,
        },
      })
    );
  }
  saveOption(action: ActionReturned, lp: LocationPartnerInterface, i: number) {
    switch (action.option.type) {
      case OptionActionEnum.ADMIN_UPDATE_LOCATION_PAYMENT_STATUS: {
        const modalRef = this.modalService.open(UpdatePaymentStatusComponent, {
          centered: true,
        });
        modalRef.componentInstance.companyName = lp.companyName;
        modalRef.componentInstance.title =
          "enumMapping.paymentLP.updatePaymentStatus";
        modalRef.componentInstance.dateRange = [
          this.formFilter.get("date")?.value[0],
          this.formFilter.get("date")?.value[1],
        ];
        modalRef.componentInstance.isPaid = this.isPaid;
        modalRef.componentInstance.companyNameFilter =
          this.formFilter.get("companyName")?.value;
        modalRef.componentInstance.advertisingCampaigns = this.advertsingCampaigns[i];
        break;
      }
    }
  }
  getIndex(i: number, indexAds: number) {
    let length: number = 0;
    for (let index = 0; index < i; index++) {
      length +=
        this.filterAdsForLocationPartners.advertisingCampaigns[index].length;
    }
    if (indexAds == 0) return length;
    else return length + indexAds;
  }
  searchCompanyName() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeFilterLocationsForPayment({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          searchCompanyName: this.formFilter.get("companyName")?.value?.trim() === "" ? null : this.formFilter.get("companyName")?.value,
          isPaid: this.isPaid,
          startDate: this.formFilter.get("date")?.value[0],
          endDate: this.formFilter.get("date")?.value[1],
        },
      })
    );
  }
  FilterDate() {
    this.store.dispatch(invokeApiDistroyed());
    this.store.dispatch(
      invokeFilterLocationsForPayment({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          searchCompanyName: this.formFilter.get("companyName")?.value?.trim() === "" ? null : this.formFilter.get("companyName")?.value,
          startDate: this.formFilter.get("date")?.value[0],
          endDate: this.formFilter.get("date")?.value[1],
          isPaid: this.isPaid,
        },
      })
    );
  }

  saveOptionFilter(option: any | null) {
    this.store.dispatch(invokeApiDistroyed());
    const KeyFound = Object.keys(PaymentStatusToShow).find(
      (key) => PaymentStatusToShow[key as PaymentStatus] === option
    );
    switch (KeyFound) {
      case PaymentStatus.PAID:
        this.isPaid = true;
        break;
      case PaymentStatus.UNPAID:
        this.isPaid = false;
        break;
      default:
        this.isPaid = null;
    }
    this.store.dispatch(
      invokeFilterLocationsForPayment({
        locationPartnerFilter: {
          ...this.locationPartnerFilter,
          searchCompanyName: this.formFilter.get("companyName")?.value?.trim() === "" ? null : this.formFilter.get("companyName")?.value,
          startDate: this.formFilter.get("date")?.value[0],
          endDate: this.formFilter.get("date")?.value[1],
          isPaid: this.isPaid,
        },
      })
    );
  }
}
