<div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
    <div class="card m-b-0">
      <div class="card-header">
        <div class="media-space-between">
          <h4>{{ "profile.details" | translate }}</h4>
        </div>
      </div>
      <div class="card-body align-items-center" style="min-height: 180px">
        <div class="text-center" *ngIf="!(lpProfile$ | async); else details">
          <div class="loader-box">
            <div class="loader-34"></div>
          </div>
          <h6 class="f-w-600">{{ "fetching" | translate }}</h6>
        </div>
        <ng-template #details>
          <div class="pl-12 pr-20 grid-column">
            <div class="grid-column">
              <label class="col-form-label label f-w-600">
                {{ "profile.profileDetails.title" | translate }}:
              </label>
              <p class="col-form-label label">{{ lpProfile.companyName }}</p>
              <label class="col-form-label label f-w-600">
                {{ "profile.profileDetails.email" | translate }}:
              </label>
              <p class="col-form-label label">{{ lpProfile.email }}</p>
              <label class="col-form-label label f-w-600">
                {{ "profile.profileDetails.fullName" | translate }}:
              </label>
              <p class="col-form-label label">
                {{ lpProfile.firstName + " " + lpProfile.lastName }}
              </p>
            </div>

            <div class="grid-column">
              <label class="col-form-label label f-w-600">
                {{ "profile.profileDetails.tel" | translate }}:
              </label>
              <p class="col-form-label label">{{ lpProfile.phone }}</p>
              <label class="col-form-label label f-w-600">
                {{ "profile.profileDetails.activity" | translate }}:
              </label>
              <p class="col-form-label label">
                {{ lpProfile.companyActivity }}
              </p>
              <label class="col-form-label label f-w-600">
                <b
                  >{{ "profile.profileDetails.companyAddress" | translate }}:</b
                >
              </label>
              <p class="col-form-label label">{{ lpProfile.companyAddress }}</p>
              <label class="col-form-label label f-w-600">
                {{ "profile.profileDetails.premiumEndDate" | translate }}:
              </label>
              <p class="col-form-label label">
                {{ lpProfile.premiumEndDate | localizedDate }}
              </p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
    <div class="card pb-0 o-hidden earning-card">
      <div class="card-header earning-back"></div>
      <div class="card-body">
        <div class="earning-content">
          <img
            class="rounded-circle"
            src="assets/identity/consummers.svg"
            alt=""
          />
          <h5 class="f-w-600">
            {{ "welcomCard.statistic.titleCardStatistique" | translate }}
          </h5>
          <div *ngIf="loadingReteinedConsumers; else loadingFalse">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
          </div>
          <ng-template #loadingFalse>
            <h6>{{ resultRetainedConsumers ?? 0 | toKValue }}</h6>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex my-3">
  <h4 class="m-15">{{ "location.statistics.title" | translate }}</h4>
  <div class="d-flex mx-3">
    <button
      *ngIf="cachedAt"
      class="btn badge badge-light text-dark"
      type="button"
      (click)="fetchCardsStatistics(true)"
    >
      <i class="icofont icofont-refresh"></i>
      <span>
        {{ "cachedAt" | translate }}
        {{ cachedAt | localizedDate : "short" }}
      </span>
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
    <app-card
      [title]="Retention_time.title"
      [icon]="Retention_time.icon"
      [value]="Retention_time.value"
      [class]="'eye'"
      [isLoading]="loadingRetentionTime$ | async"
    >
    </app-card>
  </div>
  <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
    <app-card
      [title]="consummers.title"
      [icon]="consummers.icon"
      [value]="consummers.value"
      [class]="'eye'"
      [isLoading]="loadingConsumers$ | async"
    >
    </app-card>
  </div>
  <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
    <app-card
      [title]="impression.title"
      [icon]="impression.icon"
      [value]="impression.value"
      [class]="'eye'"
      [isLoading]="loadingGlobalStatics$ | async"
    >
    </app-card>
  </div>
  <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3 box-col-4">
    <app-card
      [title]="uniqueViews.title"
      [icon]="uniqueViews.icon"
      [value]="uniqueViews.value"
      [class]="'eye'"
      [isLoading]="loadingGlobalStatics$ | async"
    >
    </app-card>
  </div>
  <app-audience [inputAudience$]="inputAudience"></app-audience>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="media-space-between">
          <div class="d-flex">
            <h4 class="m-t-15">
              {{ "location.statistics.retentionRate" | translate }}
            </h4>
            <div class="d-flex mx-3">
              <button
                *ngIf="retentionRateCachedAt"
                class="btn badge badge-light text-dark"
                type="button"
                (click)="fetchRetentionRate(true)"
              >
                <i class="icofont icofont-refresh"></i>
                <span>
                  {{ "cachedAt" | translate }}
                  {{ retentionRateCachedAt | localizedDate : "short" }}
                </span>
              </button>
            </div>
          </div>
          <form
            [formGroup]="form"
            class="media-space-between col-10 col-xl-4 col-md-5 col-sm-8 col-xxl-3"
          >
            <div class="input-group m-r-10">
              <div
                class="input-group-text bg-primary"
                data-target="#dt-date"
                [owlDateTimeTrigger]="dtPicker3"
              >
                <i
                  class="icofont icofont-ui-calendar"
                  style="color: white; font-size: 12px"
                ></i>
              </div>
              <input
                style="font-size: small; border-radius: 0 5px 5px 0"
                width="max-content"
                formControlName="date"
                class="form-control"
                type="text"
                name="daterange"
                (ngModelChange)="getChartInDate()"
                [placeholder]="'placehorderPickDate' | translate"
                placehorderPickDate
                [selectMode]="'range'"
                [owlDateTimeTrigger]="dtPicker3"
                [owlDateTime]="dtPicker3"
              />
              <owl-date-time
                [pickerType]="'calendar'"
                #dtPicker3
              ></owl-date-time>
            </div>
            <app-export
              class="m-auto"
              [buttonName]="'export'"
              [title]="'Audiences '"
              [data]="retentionChart"
            ></app-export>
          </form>
        </div>
      </div>
      <div class="card-body" id="retentionCHart">
        <app-column-chart
          [xaxisTitle]="columnChart.xaxisTitle"
          [yaxisTitle]="columnChart.yaxisTitle"
          [xaxisTitle]="columnChart.xaxisTitle"
          [resultRetentionRate]="retentionRate"
          [isLoading]="isLoading"
        ></app-column-chart>
      </div>
    </div>
  </div>
  <app-technical-data [search]="search"></app-technical-data>
</div>
