import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "../appState.interface";

export const locationPartnerState = (state: AppStateInterface) =>
  state.locationPartnerState;

export const locationPartnersResultSelector = createSelector(
  locationPartnerState,
  (state) => state.locationPartnersResult
);

export const locationPartnerFilterSelector = createSelector(
  locationPartnerState,
  (state) => state.locationPartnerFilter
);

export const locationPartnerSelector = createSelector(
  locationPartnerState,
  (state) => state.locationPartner
);

export const loadingLocationPartnersSelector = createSelector(
  locationPartnerState,
  (state) => state.loading
);
export const getAllLocationPartnersSelector = createSelector(
  locationPartnerState,
  (state) => state.allLocationPartners
);

export const locationPartnersComapyNamesSelector = createSelector(
  locationPartnerState,
  (state) => state.companyNames
);

export const locationPartnerCreatedSelector = createSelector(
  locationPartnerState,
  (state) => state.successMessage
);
export const successMessageSelector = createSelector(
  locationPartnerState,
  (state) => state.successMessage
);
export const locationPartnerDeletedByEmailSelector = createSelector(
  locationPartnerState,
  (state) => state.successMessage
);

//location partners to create campaign Selector
export const locationPartnersToCreateCampaignFilterSelector = createSelector(
  locationPartnerState,
  (state) => state.locationPartnersToCreateCampaignFilter
);

export const locationPartnersToCreateCampaignResultSelector = createSelector(
  locationPartnerState,
  (state) => state.locationPartnersToCreateCampaignResult
);

export const calculateTotalLPCostByCampanyNamesSelector = createSelector(
  locationPartnerState,
  (state) => state.totalCostWithDiscount
);

export const locationPartnersByCompanyNamesSelector = createSelector(
  locationPartnerState,
  (state) => state.locationPartnersByCompanyNames
);
export const resultMainStaticsForLPSelector = createSelector(
  locationPartnerState,
  (state) => state.resultMainStaticsForLP
);
export const pricingByCompanyNameSelector = createSelector(
  locationPartnerState,
  (state) => state.pricingByCompanyName
);

export const lpDetailsSelector = createSelector(
  locationPartnerState,
  (state) => state.lpDetails
);
export const resultLPInfoSelector = createSelector(
  locationPartnerState,
  (state) => state.lpInfo
);
export const resultLocationPartnerReviewSelector = createSelector(
  locationPartnerState,
  (state) => state.lastWeekReviewLP
);
export const resultLocationPartnerByRegionSelector = createSelector(
  locationPartnerState,
  (state) => state.lps
);
export const updateLocationPartnerAccountSuccessSelector = createSelector(
  locationPartnerState,
  (state) => state.successMessage
);

export const resultLocationPartnersImpressionsSelector = createSelector(
  locationPartnerState,
  (state) => state.impressionsResult
);
export const filterLocationPartnerResultSelector = createSelector(
  locationPartnerState,
  (state) => state.impressionsFilter
);
export const saveSelectedLPsSelector = createSelector(
  locationPartnerState,
  (state) => state.selectedLps
)

//Impressions
export const resultCalculateAndUpdateViewsAverageSelector = createSelector(
  locationPartnerState,
  (state) => state.averageViewsUpdateResult
);

export const resultGrantPremiumAccessSelector = createSelector(
  locationPartnerState,
  (state) => state.successMessage
)

export const resultSuccessSelector = createSelector(
  locationPartnerState,
  (state) => state.successMessage
)

export const resultCalculateViewsOrUniqueViewsBasedOnNotPaidAdsSelector = createSelector(
  locationPartnerState,
  (state) => state.views
)

export const resultLocationPartnerAdvertisingCampignFilterSelector = createSelector(
  locationPartnerState,
  (state) => state.advertisingCampaignsPaymentLP
)
export const resultLoadingLocationAdsSelector = createSelector(
  locationPartnerState,
  (state) => state.loadingLocationCampaigns
)
export const loadingViewsSelector = createSelector(
  locationPartnerState,
  (state) => state.loadingViews
)

export const saveSelectedLocationsForMapSelector = createSelector(
  locationPartnerState,
  (state) => state.locations
)

export const updateAverageViewsManuallySelector = createSelector(
  locationPartnerState,
  (state) => state.successMessage
)