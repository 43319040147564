import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Constants } from "../data/constants";
import { Observable } from "rxjs";
import { FilterListAdvertisingCompaign } from "../models/advertising-campaigns/filter-list-advertising-compaign.interface";
import { ResultFilterAdvertisingCompaign } from "../models/advertising-campaigns/result-filter-advertising-compaign.interface";
import { CampaignStatusEnum } from "../enum/campaign-status";
import { SuccessDto } from "../models/success-dto";
import { BestAdvertisingCampaign } from "../models/advertising-campaigns/best-advertising-campaign.interface";
import { AdPaymentDetailsInterface } from "../models/advertising-campaigns/ad-payment-details.interface";
import { AdsPublisherDetailsAndStatus } from "../models/advertising-campaigns/ads-publisher-details-and-status.interface";
import { FilterCalendar } from "../models/advertising-campaigns/filter-calendar.interface";
import {
  AdvertisingCampaignInterface,
  CreateAdvertisingCampaignInterface,
} from "../models/advertising-campaigns/advertising-campaign.interface";

@Injectable({
  providedIn: "root",
})
export class AdvertisingCampaignService {
  constructor(private http: HttpClient) {}
  getLatestAdvertisingCampaigns(): Observable<AdvertisingCampaignInterface[]> {
    return this.http.get<AdvertisingCampaignInterface[]>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/latestAds/token`
    );
  }
  addAdvertisingCampaign(
    campaignDto: CreateAdvertisingCampaignInterface
  ): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/create`,
      campaignDto
    );
  }
  filterAdvertisingCompaign(
    filter: FilterListAdvertisingCompaign,
    refreshCache : boolean
  ): Observable<ResultFilterAdvertisingCompaign> {
    return this.http.post<ResultFilterAdvertisingCompaign>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/refreshCache/${refreshCache}/filter`,
      filter
    );
  }

  getAdvertisingCompaignByHashedId(
    campaignHashedId: string | null
  ): Observable<AdvertisingCampaignInterface | null> {
    return this.http.get<AdvertisingCampaignInterface | null>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/${campaignHashedId}/details`
    );
  }
  updateAdCampaignDetails(
    campaignHashedId: string,
    adCompaign: AdvertisingCampaignInterface | null
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/update/${campaignHashedId}`,
      adCompaign
    );
  }

  invokeUpadateAdPaymentDetails(
    payment: AdPaymentDetailsInterface
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/updatePayment`,
      payment
    );
  }

  updateAdCampaignStatus(
    campaignHashedId: string | null,
    status: CampaignStatusEnum | null
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/status/${campaignHashedId}/${status}`,
      null
    );
  }
  updateAdvertisingCampaignStatus(
    campaignHashedId: string,
    status: string
  ): Observable<SuccessDto> {
    return this.http.patch<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/update/status/${campaignHashedId}/${status}`,
      null
    );
  }
  deleteAdCampaignByHashedId(campaignHashedId: string): Observable<SuccessDto> {
    return this.http.delete<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/${campaignHashedId}`
    );
  }
  bestAdvertisingCampaignResult(isRefreshCache : boolean): Observable<BestAdvertisingCampaign> {
    return this.http.get<BestAdvertisingCampaign>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/bestAdvertisingCampaign/${isRefreshCache}`
    );
  }

  getAllAdsByStatusAndPublisherRole(
    filterCalendar: FilterCalendar
  ): Observable<AdvertisingCampaignInterface[]> {
    return this.http.post<AdvertisingCampaignInterface[]>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/allAdsForCalendar`,
      filterCalendar
    );
  }
  addSynthesisReport(
    campaignHashedId: string,
    synthesisReport: string | null
  ): Observable<SuccessDto> {
    return this.http.post<SuccessDto>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/addSynthesisReport/${campaignHashedId}`,
      synthesisReport
    );
  }
  getAdvertiserByAdTitle(title: string[]): Observable<string[]> {
    return this.http.get<string[]>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/user/adTitle/${title}`
    );
  }
  getStatusesByAdTiles(
    campaignsHashedIds: string[]
  ): Observable<AdsPublisherDetailsAndStatus | null> {
    return this.http.post<AdsPublisherDetailsAndStatus | null>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/publisher/status`,
      campaignsHashedIds
    );
  }
  getCheckAdvertisingCampaignExistByTitle(title: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/exist/ad/${title}`
    );
  }

  uploadJustificationFile(
    campaignHashedId: string,
    justificationFile: string | null
  ): Observable<SuccessDto | null> {
    return this.http.post<SuccessDto | null>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/uploadJustificationFile/${campaignHashedId}`,
      justificationFile
    );
  }

  getAdvertiserAds(email: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${
        environment.APP_PORT + Constants.ADVERTISING_CAMPAIGN_ENDPOINT
      }/advertiserAds/` + email
    );
  }
}
