<div
  *ngIf="
    currentLP?.partnerStatus == partnerStatus.INTERNET_PROVIDER_PAYMENT;
    else revenue_sharing
  "
>
  <app-case-fsi></app-case-fsi>
</div>
<ng-template #revenue_sharing>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-baseline">
        <h4 class="m-r-5">{{ "location.remuneration.title" | translate }}</h4>
        <!--<i class="icofont icofont-info-circle" style="color: darkgrey"></i>-->
      </div>
      <hr style="width: 87%; margin-bottom: 0; position: absolute" />
    </div>
    <div class="card-body" *ngIf="pricing">
      <div class="row">
        <div class="col-12 col-xl-12">
          <div class="grid-column">
            <div class="media align-items-baseline">
              <h5 class="m-r-10">
                {{ "location.remuneration.method" | translate }}
              </h5>
              <span
                [ngClass]="
                  'badge ' +
                  badgeService.getBadgeByLocationPartnerPricingMethod(
                    pricing.pricingMethod
                  )
                "
              >
                {{ pricingMethod[pricing.pricingMethod] }}
              </span>
            </div>

            <div class="media align-items-baseline">
              <h5 class="m-r-10">
                {{ "location.remuneration.frequency" | translate }}
              </h5>
              {{ PaymentFrequency[pricing.paymentFrequency] }}
            </div>

            <div class="media align-items-baseline">
              <h5 class="m-r-10">
                {{ "location.remuneration.price" | translate }}
              </h5>
              {{ pricing.price }} TND
            </div>
            <div class="media align-items-baseline">
              <h5 class="m-r-10">
                {{ "location.remuneration.endPhase" | translate }}
              </h5>
              {{ pricing.pilotPhaseEndDate | localizedDate }}
            </div>
            <div class="media align-items-baseline">
              <h5 class="m-r-10">
                {{ "location.remuneration.nextPayment" | translate }}
              </h5>
              <div *ngIf="loadingViews$ | async">
                <ng-container style="float: right" class="flex">
                  <div class="loader-box">
                    <div class="loader-34"></div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="!(loadingViews$ | async)">
                {{ (totalViewsNotPaid * pricing.price).toFixed(3) }}
                TND
              </div>
            </div>
          </div>
          <img
            class="ant"
            src="assets/identity/ant-remuneration.svg"
            alt="ant"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <form class="d-flex justify-content-end" [formGroup]="formFilter">
        <div class="d-flex mb-3">
          <button
            *ngIf="cachedAt"
            class="btn badge badge-light text-dark"
            type="button"
            (click)="fetchCampaigns(true)"
          >
            <i class="icofont icofont-refresh"></i>
            <span>
              {{ "cachedAt" | translate }}
              {{ cachedAt | localizedDate : "short" }}
            </span>
          </button>
        </div>
        <div class="w-auto" style="margin-left: 5px">
          <div
            class="input-group date"
            id="dt-date"
            data-target-input="nearest"
            style="width: 17rem"
          >
            <div
              class="input-group-text bg-primary"
              data-target="#dt-date"
              [owlDateTimeTrigger]="dt"
            >
              <i
                class="icofont icofont-ui-calendar"
                style="color: white; font-size: 12px"
              ></i>
            </div>
            <input
              formControlName="date"
              class="form-control datetimepicker-input digits"
              type="text"
              name="daterange"
              [placeholder]="'placehorderPickDate' | translate"
              [selectMode]="'range'"
              [owlDateTimeTrigger]="dt"
              [owlDateTime]="dt"
              (ngModelChange)="FilterDate()"
            />
          </div>
          <owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>
        </div>
        <div class="m-r-10">
          <div
            class="faq-form mr-3"
            style="color: darkgrey; display: flex; margin-left: 10px"
          >
            <i class="search-icon" data-feather="search"></i>
            <div>
              <div class="input-group">
                <span
                  class="input-group-text p-0"
                  style="background-color: transparent"
                >
                  <app-general-filter
                    [filter]="filterPayment"
                    (optionSelected)="saveOptionFilter($event)"
                  ></app-general-filter>
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive data-table" style="font-size: 12px">
        <table class="table table-striped table-border-vertical">
          <thead>
            <tr>
              <ng-container *ngFor="let column of columns">
                <th>
                  {{ column.label | translate }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody *ngIf="!(loadingAds$ | async)">
            <tr *ngFor="let ad of advertsingCampaigns; let i = index">
              <td>
                {{ ad.title }}
                <i
                  class="icofont icofont-check-circled color-success"
                  *ngIf="filterAdsForLocationPartners.isPaid[i]; else notPaid"
                ></i>
                <ng-template #notPaid>
                  <i
                    *ngIf="!filterAdsForLocationPartners.isPaid[i]"
                    class="icofont icofont-close-circled"
                    style="color: red"
                  ></i>
                </ng-template>
              </td>
              <td>
                {{ ad.startingDate | localizedDate }}
              </td>
              <td>
                {{ ad.endingDate | localizedDate }}
              </td>
              <td>
                {{ filterAdsForLocationPartners.views[i] }}
              </td>
              <td>
                {{ filterAdsForLocationPartners.uniqueViews[i] }}
              </td>
              <td>
                <span
                  [ngClass]="
                    'badge ' +
                    badgeService.getBadgeForAdvertisingCampaingStatus(ad.status)
                  "
                >
                  {{ "enumMapping.campaigns.status." + ad.status | translate }}
                </span>
              </td>
              <td
                *ngIf="pricing.pilotPhaseEndDate < ad.startingDate; else none"
              >
                {{
                  (pricing.pricingMethod == "VIEWS"
                    ? filterAdsForLocationPartners.views[i] * pricing.price
                    : filterAdsForLocationPartners.uniqueViews[i] *
                      pricing.price
                  ).toFixed(3)
                }}
              </td>
              <ng-template #none>
                <td>0.000</td>
              </ng-template>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="advertsingCampaigns.length == 0 && !(loadingAds$ | async)"
          class="d-flex justify-content-center m-t-20"
        >
          {{ "noData" | translate }}
        </div>
        <div *ngIf="loadingAds$ | async">
          <ng-container style="float: right" class="flex">
            <div class="loader-box">
              <div class="loader-34"></div>
            </div>
            <h6
              class="col align-self-center f-w-600"
              style="text-align: center"
            >
              {{ "fetching" | translate }}
            </h6>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
