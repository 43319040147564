<div class="card">
  <div class="card-header p-b-10">
    <div class="media-space-between">
      <h4 class="m-t-15">
        {{ title | translate }}
      </h4>
      <div class="d-flex mb-3">
        <button
          *ngIf="cachedAt"
          class="btn badge badge-light text-dark"
          type="button"
          (click)="getChartInDate(true)"
        >
          <i class="icofont icofont-refresh"></i>
          <span>
            {{ "cachedAt" | translate }}
            {{ cachedAt | localizedDate : "short" }}
          </span>
        </button>
      </div>
      <form
        [formGroup]="form"
        class="d-inline-flex col-10 col-xl-5 col-md-6 col-sm-8 col-xxl-4"
      >
        <div class="input-group d-contents" >
          <div
            class="input-group-text bg-primary"
            data-target="#dt-date"
            [owlDateTimeTrigger]="dtPicker3"
          >
            <i
              class="icofont icofont-ui-calendar"
              style="color: white; font-size: 12px"
            ></i>
          </div>
          <input
            formControlName="date"
            style="font-size: small; border-radius: 0 5px 5px 0"
            width="max-content"
            class="form-control"
            type="text"
            name="daterange"
            (ngModelChange)="getChartInDate(false)"
            [placeholder]="'placehorderPickDate' | translate"
            [selectMode]="'range'"
            [owlDateTimeTrigger]="dtPicker3"
            [owlDateTime]="dtPicker3"
          />
          <owl-date-time [pickerType]="'calendar'" #dtPicker3></owl-date-time>
        </div>
        <app-export
          class="m-l-10"
          [data]="Audience"
          [buttonName]="'export'"
          [title]="adTitle"
        ></app-export>
      </form>
    </div>
  </div>
  <div class="card-body" id="audiences">
    <div class="row">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 col-xxl-8"
        id="invoices.summary"
      >
        <div>
          <div class="media-space-between">
            <h6 class="f-w-600 color-darkGray">
              {{ inputAudience.titleSpline ?? "" | translate }}
            </h6>
            <app-spline-filter
              [hidden]="isConsummer"
              (optionSelected)="resultOptionClicked($event)"
            >
            </app-spline-filter>
          </div>
        </div>
        <div>
          <div class="card">
            <div class="card-body">
              <app-spline
                (chart)="chart($event)"
                [summaryChart$]="resultSummaryChart"
                [optionSelected]="optionSelected"
                [seriesTitles]="seriesTitles"
                [yaxisTitle]="yaxisTitle | translate"
                [xaxisTitle]="summaryChart.xaxisTitle | translate"
                [isAdFinished] = "isAdFinished"
                [isConsumerChart]="isConsummer"
              >
              </app-spline>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4"
        id="invoices.distributionAgeGender"
      >
        <div>
          <div class="media-space-between m-b-10">
            <h6 class="f-w-600 color-darkGray">
              {{ inputAudience.titleColumnChart ?? "" | translate }}
            </h6>
            <app-general-filter
              [hidden]="isConsummer"
              [filter]="filterColumnChart"
              (optionSelected)="saveOption($event)"
            ></app-general-filter>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <app-column-chart
              [xaxisTitle]="columnChart.xaxisTitle"
              [seriesTitles]="columnChart.seriesTitles"
              [yaxisTitle]="columnChart.yaxisTitle"
              [xaxisTitle]="columnChart.xaxisTitle"
              [resultGenderAgeStatics]="genderAgeStatics"
              [isLoading]="loading"
            >
            </app-column-chart>
          </div>
        </div>
      </div>
    </div>
    <button
      class="btn btn-primary px-3"
      style="margin-left: auto; display: block"
      *ngIf="inputAudience.title == 'Récapitulatif'"
    >
      {{ "buttons.seeStatistics" | translate }}
    </button>
  </div>
</div>
