import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { JwtService } from "src/app/shared/services/jwt.service";
import {
  bestAdvertisingCampaignSelector,
  loadingAdvertisingCampaignSelector,
} from "src/app/store/advertisingCampaign/advertiserCampaign.selectors";
import {
  bestAdvertisingCampaign,
  invokeBestAdvertisingCampaign,
} from "src/app/store/advertisingCampaign/advertisingCampaign.actions";
import { AppStateInterface } from "src/app/store/appState.interface";
import { environment } from "src/environments/environment";
import { BestAdvertisingCampaign } from "src/app/shared/models/advertising-campaigns/best-advertising-campaign.interface";
import { ObjectiveTypeEnumMapping } from "src/app/shared/enum/objectives";
@Component({
  selector: "app-best-advertising-campaign",
  templateUrl: "./best-advertising-campaign.component.html",
})
export class BestAdvertisingCampaignComponent
  extends BaseComponent
  implements OnInit
{
  videoLink: string = "/assets/video/demo-ad.mp4";
  isLoading: boolean | null = true;
  loading$: Observable<boolean | null>;
  bestAdvertisingCampaign$: Observable<BestAdvertisingCampaign | null>;
  resultBestAdvertisingCampaign: BestAdvertisingCampaign;
  objectiveTypeEnumMapping = ObjectiveTypeEnumMapping;
  clicks = {
    value: 0,
    title: "Clicks",
    icon: "assets/identity/click-icon.svg",
    class: "card info-card",
  };
  impression = {
    value: 0,
    title: "Impression",
    icon: "assets/identity/eye-icon.svg",
    class: "card info-card",
  };

  completedVideos = {
    value: 0,
    title: "Engagement",
    unit: "yesterday",
    icon: "assets/identity/time-icon.svg",
    class: "card info-card",
  };
  title: string;
  @ViewChild("myVideo") myVideo: ElementRef;
  companyName: string;
  constructor(
    private store: Store<AppStateInterface>,
    private jwt: JwtService
  ) {
    super(store);
    this.bestAdvertisingCampaign$ = this.store
      .pipe(select(bestAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.loading$ = this.store
      .pipe(select(loadingAdvertisingCampaignSelector))
      .pipe(takeUntil(this.ngDestroyed$));
  }

  ngOnInit(): void {
    this.store.dispatch(bestAdvertisingCampaign({ adCompaign: null }));
    this.companyName = this.jwt.getCompanyName();
    this.bestAdvertisingCampaign$.subscribe(
      (result: BestAdvertisingCampaign | null) => {
        if (result != null) {
          this.isLoading = false;
          this.resultBestAdvertisingCampaign = result;
          this.title = result.title;
          this.completedVideos.value = result.finishes;
          this.clicks.value = result.clicks;
          this.impression.value = result.views;
          this.videoLink = environment.CDN_RELEAD + result.videoLink;
        } else {
          this.store.dispatch(invokeBestAdvertisingCampaign({isRefreshCache: false}));
        }
      }
    );
  }
  onClickRefreshCache(){
    this.store.dispatch(invokeBestAdvertisingCampaign({isRefreshCache: true}));
  }
}
