import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexStroke,
  ApexTheme,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts";
import { Observable, of, takeUntil } from "rxjs";
import { BaseComponent } from "src/app/base.component";
import { DeviceType } from "src/app/shared/models/statistics/deviceType";
import { GenderStatistics } from "src/app/shared/models/statistics/gender-statistics";
import { AppStateInterface } from "src/app/store/appState.interface";
import { setDeviceType } from "src/app/store/consumers/consumers.actions";
import { resultDeviceTypeSelector } from "src/app/store/consumers/consumers.selectors";
import { Style } from "src/app/utils/style/style";
import { ChartName } from "../chart-name.enum";
import { AgeStatistics } from "src/app/shared/models/statistics/age-statistics";
import { TranslateService } from "@ngx-translate/core";

let primary_color = "#FF69B4";
let secondary_color = "#9D77F4";
let third_color = "#F7DAED";
let fourth_color = "#FFE1F0";
let fifth_color = "#FFAFD7";
@Component({
  selector: "app-donut",
  templateUrl: "./donut.component.html",
})
export class DonutComponent extends BaseComponent implements OnInit {
  @Input("resultDeviceType") resultDeviceType: Observable<DeviceType | null>;
  @ViewChild("chartApex", { static: false }) chartApex: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  @Input() chart?: any = {};
  @Input() title = "";
  @Input() labels?: any = [];
  @Input() legend: ApexLegend = {};
  @Input() theme: ApexTheme = {};

  @Input() chartName: ChartName;

  @Input("resultConsumerGender")
  resultConsumerGender: Observable<GenderStatistics | null> = of(null);

  @Input("resultConsumerAgeRange")
  resultConsumerAgeRange: Observable<AgeStatistics | null> = of(null);

  series?: ApexNonAxisChartSeries = [];
  length = 0;

  adTitle: string | null;
  deviceTypeForAd$: Observable<DeviceType | null>;
  @Input() loading: boolean = true;
  constructor(
    private store: Store<AppStateInterface>,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super(store);
    this.deviceTypeForAd$ = this.store
      .pipe(select(resultDeviceTypeSelector))
      .pipe(takeUntil(this.ngDestroyed$));
    this.chartOptions = {
      series: [],
      chart: {
        width: 450,
        height: 300,
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        show: true,
        position: "bottom",
        floating: true,
        verticalAlign: "bottom",
        align: "center",
      },
      labels: Style.browserDevice,
      responsive: [
        {
          options: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            legend: {
              show: true,
              position: "bottom",
              floating: true,
              verticalAlign: "bottom",
              align: "center",
            },
          },
        },
      ],
    };
  }

  ngOnInit(): void {
    this.adTitle = this.route.snapshot.queryParamMap.get("title");
    this.store.dispatch(setDeviceType({ deviceType: null }));
    switch (this.chartName) {
      case ChartName.DEVICE_TYPE: {
        this.deviceTypeForAd$.subscribe((result: DeviceType | null) => {
          if (result != null) {
            this.loading = false;
            this.configChart(result);
            this.length = Object.values(result).reduce(
              (total, value) => total + value,
              0
            );
          } /* else {
            this.store.dispatch(InvokeDeviceType({ adTitle: this.adTitle }));
          } */
        });
        break;
      }
      case ChartName.GENDER: {
        this.resultConsumerGender.subscribe((data) => {
          this.loading = false;
          if (data != null) {
            this.configGenderChart(data);
            this.length = Object.values(data).reduce(
              (total, value) => total + value,
              0
            );
          }
        });
        break;
      }
      case ChartName.AGE_RANGE: {
        this.resultConsumerAgeRange.subscribe((data) => {
          this.loading = false;
          if (data != null) {
            this.configAgeChart(data);
            this.length = Object.values(data).reduce(
              (total, value) => total + value,
              0
            );
          }
        });
        break;
      }
    }
  }
  configChart(result: DeviceType | null) {
    let legend: string[] = [];
    Style.browserDevice.map((e) => {
      legend = [...legend, this.translate.instant(e)];
    });
    this.chartOptions = {
      ...this.chartOptions,
      series: [
        result?.bot,
        result?.mobile,
        result?.pc,
        result?.tablet,
        result?.other,
      ],
      colors: [
        primary_color,
        secondary_color,
        third_color,
        fourth_color,
        fifth_color,
      ],
      labels: legend,
      legend: {
        customLegendItems: legend,
        position: "bottom",
      },
    };
  }
  configGenderChart(result: GenderStatistics) {
    let legend: string[] = [];
    Object.keys(result)
      .filter((e) => e != "cachedAt")
      .map((e) => {
        legend = [...legend, this.translate.instant("Charts." + e)];
      });
    this.chartOptions = {
      ...this.chartOptions,
      chart: {
        ...this.chartOptions.chart,
      },
      colors: [secondary_color, primary_color],
      series: [result?.female, result?.male],
      labels: legend,
      legend: {
        customLegendItems: legend,
      },
    };
  }
  configAgeChart(result: AgeStatistics) {
    let legend: string[] = [];
    Object.keys(result)
      .filter((e) => e != "cachedAt")
      .map((e) => {
        legend = [...legend, this.translate.instant("Charts.ageRange." + e)];
      });
    this.chartOptions = {
      ...this.chartOptions,
      chart: {
        ...this.chartOptions.chart,
      },
      colors: [
        primary_color,
        secondary_color,
        third_color,
        fourth_color,
        fifth_color,
      ],
      series: [
        result?.between18And25,
        result?.between26and35,
        result?.between36and45,
        result?.inf18,
        result?.sup46,
      ],
      labels: legend,
      legend: {
        customLegendItems: legend,
      },
    };
  }
}
export type ChartOptions = {
  series?: ApexAxisChartSeries;
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  labels?: string[];
  stroke?: ApexStroke;
  title?: ApexTitleSubtitle;
};
